import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { styles } from "./styles";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import {
  onSaveJobCustomerInfo,
  onSaveJobInfo,
} from "../dashboard/ducks/actions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const InitialSchedule = (props) => {
  const classes = useStyles();

  const {
    isEditMode,
    onSummaryEditClick,
    onSaveJobInitialScheduleInfoAction,
    onStepperNextClick,
    onStepperBackClick,
    jobOrderDetail,
  } = props;
  const { register, handleSubmit, setValue, errors } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    if (jobOrderDetail.jobId) {
      onSaveJobInitialScheduleInfoAction({ jobId: jobOrderDetail.jobId, initialScheduleInfo: { ...data } });
      onStepperNextClick();
    }
  };

  const handleBack = () => {
    onStepperBackClick();
  };

  const onClickFinish = (updatedData) => {
    if (jobOrderDetail.jobId) {
      onSaveJobInitialScheduleInfoAction({ jobId: jobOrderDetail.jobId, initialScheduleInfo: { ...updatedData } });
    }
    onSummaryEditClick(6, false);
  };

  const getCurrentDate = () => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }
    return `${yyyy}-${mm}-${dd}`;
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>

        <div
          class="col-lg-12 container-center text-left container-stepper"
          style={{}}
        >
          <div class="col-lg-12">
            <div class="col-lg-12 block-header" style={{ fontWeight: "bold" }}>
              Initial Schedule
              <CalendarTodayIcon
                fontSize="small"
                style={{ marginLeft: "5px" }}
              />
            </div>
            <div
              class="row text-left"
              style={{ paddingLeft: "20px", paddingTop: "10px" }}
            >
              <div class="col-lg-3 d-inline">
                <TextField
                  id="mdate"
                  name="mdate"
                  label="Measure date"
                  inputRef={register}
                  type="date"
                  defaultValue={
                    jobOrderDetail.initialScheduleInfo
                      ? jobOrderDetail.initialScheduleInfo.mdate
                      : "2021-02-05"
                  }
                  className={classes.textField}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div class="col-lg-3 d-inline">
                <TextField
                  id="tidate"
                  name="tidate"
                  label="Tentative installation date"
                  inputRef={register}
                  type="date"
                  defaultValue={
                    jobOrderDetail.initialScheduleInfo
                      ? jobOrderDetail.initialScheduleInfo.tidate
                      : "2021-02-05"
                  }
                  className={classes.textField}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div class="col-lg-3 d-inline">
                <TextField
                  id="cidate"
                  name="cidate"
                  label="Confirm installation date"
                  inputRef={register}
                  type="datetime-local"
                  defaultValue={
                    jobOrderDetail.initialScheduleInfo
                      ? jobOrderDetail.initialScheduleInfo.cidate
                      : `${getCurrentDate()}T00:00`
                  }
                  style={{ width: "250px" }}
                  className={classes.textField}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <div class="col-lg-12">
            <div class="col-lg-12 block-header" style={{ fontWeight: "bold" }}>
              Construction Type
              <HomeWorkIcon fontSize="small" style={{ marginLeft: "5px" }} />
            </div>
            <div class="row text-left" style={{ paddingLeft: "20px" }}>
              <table style={{ marginLeft: "30px", marginTop: "20px" }}>
                <tr>
                  <td class="tdStylecss">

                    <Checkbox
                      defaultChecked={
                        jobOrderDetail.initialScheduleInfo
                          ? jobOrderDetail.initialScheduleInfo.new
                          : false
                      }
                      inputRef={register}
                      name="new"
                      color="primary"
                    />
                    <span style={{ paddingLeft: "5px" }}>New</span>
                  </td>
                  <td class="tdStylecss">

                    <Checkbox
                      defaultChecked={
                        jobOrderDetail.initialScheduleInfo
                          ? jobOrderDetail.initialScheduleInfo.replacement
                          : false
                      }
                      inputRef={register}
                      name="replacement"
                      color="primary"
                    />
                    <span style={{ paddingLeft: "5px" }}>Replacement</span>
                  </td>
                  <td class="tdStylecss">

                    <Checkbox
                      defaultChecked={
                        jobOrderDetail.initialScheduleInfo
                          ? jobOrderDetail.initialScheduleInfo.commercial
                          : false
                      }
                      inputRef={register}
                      name="commercial"
                      color="primary"
                    />
                    <span style={{ paddingLeft: "5px" }}>Commercial</span>
                  </td>
                  <td class="tdStylecss">

                    <Checkbox
                      defaultChecked={
                        jobOrderDetail.initialScheduleInfo
                          ? jobOrderDetail.initialScheduleInfo.propertyManagement
                          : false
                      }
                      inputRef={register}
                      name="propertyManagement"
                      color="primary"
                    />
                    <span style={{ paddingLeft: "5px" }}>Property Management</span>{" "}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-lg-12  text-left" style={{ marginTop: "10px" }}>
          <div style={{ textAlign: "right" }}>
            <Button onClick={handleBack}>Back</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {" "}
              Next
            </Button>
            {isEditMode && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(onClickFinish)}
              >
                {" "}
                Finish
              </Button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    jobOrderDetail: state.jobsDashboardReducer.jobOrderDetail || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveJobInitialScheduleInfoAction: (payload) =>
      dispatch(onSaveJobInfo(payload)),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(InitialSchedule)
);

export const ON_LOAD_ARCHIVE_CONTRACTORS = 'ON_LOAD_ARCHIVE_CONTRACTORS';
export const SET_ARCHIVE_CONTRACTORS = 'SET_ARCHIVE_CONTRACTORS';
export const ON_ACTIVATE_CONTRACTOR = 'ON_ACTIVATE_CONTRACTOR';
export const ON_LOAD_ARCHIVE_INSTALLERS = 'ON_LOAD_ARCHIVE_INSTALLERS';
export const SET_ARCHIVE_INSTALLERS = 'SET_ARCHIVE_INSTALLERS';
export const ON_ACTIVATE_INSTALLER = 'ON_ACTIVATE_INSTALLER';
export const ON_LOAD_ARCHIVE_CUSTOMERS = 'ON_LOAD_ARCHIVE_CUSTOMERS';
export const SET_ARCHIVE_CUSTOMERS = 'SET_ARCHIVE_CUSTOMERS';
export const ON_ACTIVATE_CUSTOMER = 'ON_ACTIVATE_CUSTOMER';
export const ON_LOAD_ARCHIVE_JOBS = 'ON_LOAD_ARCHIVE_JOBS';
export const SET_ARCHIVE_JOBS = 'SET_ARCHIVE_JOBS';
export const ON_ACTIVATE_JOB = 'ON_ACTIVATE_JOB';
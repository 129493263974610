import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { styles } from "./styles";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import InputLabel from "@material-ui/core/InputLabel";
import { onSaveJobInfo } from "../dashboard/ducks/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { typeOfMaterialList } from "./type-of-material-info";
import JobView from "./job-view";
import AuthenticationHandler from './../shared/authentication-handler';
import {getLoggedInUserInfo} from './../shared/get-logged-user-detail';



const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const JobSummary = (props) => {
  const classes = useStyles();

  const { onStepperBackClick, jobSummaryInfo, onSummaryEditClick, loggedUserInfo } = props;

 

  const onSubmit = () => {
    // submit complete data
    props.history.push("/jobs");
  };

  const handleBack = () => {
    onStepperBackClick();
  };

  return (
    <div>
      <div
        class="col-lg-12 container-center text-left container-stepper"
        style={{}}
      >
        <div
          class="col-lg-12 block-header-summary-1"
          style={{ fontWeight: "bold" }}
        >
          Summary
        </div>
        <JobView
        loggedUserInfo={loggedUserInfo}
          jobSummaryInfo={jobSummaryInfo}
          onSummaryEditClick={onSummaryEditClick}
        />
        <br></br>
        <br></br>
        <div class="col-lg-12  text-left">
          <div style={{ textAlign: "right" }}>
            <Button  onClick={handleBack}>Back</Button>
            <Button  variant="contained" color="primary" onClick={onSubmit}>
              Finish
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUserInfo: getLoggedInUserInfo(state),
    jobSummaryInfo: state.jobsDashboardReducer.jobOrderDetail || {},
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSaveJobSummaryAction: (payload) => dispatch(onSaveJobInfo(payload)),
  };
};
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthenticationHandler(JobSummary)))
);

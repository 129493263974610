export const ON_NEW_CUSTOMER_CLICK = 'ON_NEW_CUSTOMER_CLICK';
export const ON_VIEW_CUSTOMER_CLICK = 'ON_VIEW_CUSTOMER_CLICK';
export const ON_EDIT_CUSTOMER_CLICK = 'ON_EDIT_CUSTOMER_CLICK';
export const ON_DELETE_CUSTOMER_CLICK = 'ON_DELETE_CUSTOMER_CLICK';
export const ON_LIST_CUSTOMERS = 'ON_LIST_CUSTOMERS';
export const ON_CUSTOMER_CREATE = 'ON_CUSTOMER_CREATE';
export const ON_CUSTOMER_SAVE = 'ON_CUSTOMER_SAVE';

export const SET_CUSTOMER_VIEW = 'SET_CUSTOMER_VIEW';
export const SET_CUSTOMER_EDIT = 'SET_CUSTOMER_EDIT';
export const SET_CUSTOMERS_LIST = 'SET_CUSTOMERS_LIST';

export const ON_ERROR = 'ON_ERROR';
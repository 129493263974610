import React, { useState, useRef, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { styles } from "./styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import InputLabel from "@material-ui/core/InputLabel";
import { onSaveJobInfo } from "../dashboard/ducks/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { typeOfMaterialList } from "./type-of-material-info";
import Link from "@material-ui/core/Link";
import ConfirmationDialog from "./../shared/confirmation-dialog";
import {
  onDeleteJobClick,
  onArchiveJobClick,onHoldStatusChangeJob
} from "../dashboard/ducks/actions";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import ArchiveIcon from "@material-ui/icons/Archive";
import DeleteIcon from "@material-ui/icons/Delete";
import PostAddIcon from "@material-ui/icons/PostAdd";
import JobView from "./job-view";
import { jobStatus } from "./job-status";
import AuthenticationHandler from './../shared/authentication-handler';
import {getLoggedInUserInfo} from './../shared/get-logged-user-detail';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const JobDetailedView = (props) => {
  const classes = useStyles(); 
  const { onHoldJobAction,onJobStatusChangeAction, jobSummaryInfo, deleteJobClickAction, archiveJobClickAction, loggedUserInfo } = props;
  const [dialogObj, setDialogObj] = useState({ open: false });
  const [jobHold, setJobHold] = useState(jobSummaryInfo.hold===true?true:false);
  
  const onClose = () => {
    props.history.push("/jobs");
  };

  const onJobSummaryEditClick = (stepperValue) => {
    props.history.push({ pathname: "./jobs-form", stepperValue });
  };

  const deleteJobHandler = (confirm) => {
    setDialogObj({ open: false });
    if (confirm) {
      deleteJobClickAction(jobSummaryInfo.jobId);
      props.history.push("/jobs");
    }
  };

  const onJobDeleteClick = () => {
    var dialogProp = {
      open: true,
      title: "Delete Job",
      content:
        "This job will be removed from the system. Are you sure you want to Delete this job?",
      handleClose: deleteJobHandler,
    };

    setDialogObj(dialogProp);
  };

  const archiveJobHandler = (confirm) => {
    setDialogObj({ open: false });
    if (confirm) {
      archiveJobClickAction(jobSummaryInfo.jobId);
      props.history.push("/jobs");
    }
  };

  const onJobArchiveClick = () => {
    var dialogProp = {
      open: true,
      title: "Archive Job",
      content:
        "This job will be archived in the system. Are you sure you want to Archive this job?",
      handleClose: archiveJobHandler,
    };

    setDialogObj(dialogProp);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onJobHoldHandler = (confirm, params) => {   
    if (confirm) {
      onHoldJobAction({jobId:jobSummaryInfo.jobId, hold:params.jobHold});      
    }   
    setDialogObj({ open: false });
  };

  const onJobStatusChangeHandler=(confirm, params) => {   
    if (confirm) {     
      onJobStatusChangeAction({...jobSummaryInfo, status:params.status});      
    }   
    setDialogObj({ open: false });
  };

  const changeJobHoldStatus=(setToHold)=>{
    let message;
    if (setToHold) {
      message =
        "This job will be placed on hold. Are you sure you want to put a hold on this job?";
    } else {
      message =
        "This job will be placed on unhold. Are you sure you want to put a unhold on this job?";
    }
    var dialogProp = {
      open: true,
      title: "Hold Job",
      content:message,
      'params': { jobHold: setToHold},
      handleClose: onJobHoldHandler,
    };
    setDialogObj(dialogProp);
  }

  const changeJobProgressStatus=(status)=>{
    let message;
    if (status===jobStatus.IN_PROGRESS) {
      message =
        "This job will be moved to In Progress status. Are you sure you want to move it to In Progress?";
    } else {
      message =
      "This job will be moved to Completed status. Are you sure you want to move it to Completed?";
    }
    var dialogProp = {
      open: true,
      title: "Job Status Change",
      content:message,
      'params': { status: status},
      handleClose: onJobStatusChangeHandler,
    };
    setDialogObj(dialogProp);
  }

  const onJobAdditionalDetailsClick = () => {
    props.history.push("/job-additional-details");
  };


  
  return (
    <div>
      <div
        class="col-lg-12 container-center text-left pt-2"
        style={{ paddingTop: "10px!important" }}
      >
        <div class="row">
          <div class="col-lg-6"></div>
          <div class="col-lg-6 text-right">
            { 
              loggedUserInfo.role.toLowerCase() === 'admin' && jobSummaryInfo.hold===true && jobSummaryInfo.status!==jobStatus.COMPLETED && 
              <Button onClick={()=>changeJobHoldStatus(false)} class="blackbtn logon-btn-space" component="button" 
              variant="body2" style={{ marginRight: "5px" }}>Move to Unhold</Button>
            }
            { 
              loggedUserInfo.role.toLowerCase() === 'admin' && !jobSummaryInfo.hold && jobSummaryInfo.status!==jobStatus.COMPLETED && 
              <Button onClick={()=>changeJobHoldStatus(true)} class="blackbtn logon-btn-space" component="button" 
              variant="body2" style={{ marginRight: "5px" }}>Move to Hold</Button>
            }
            { 
              loggedUserInfo.role.toLowerCase() === 'admin' && jobSummaryInfo.status===jobStatus.IN_PROGRESS && !jobSummaryInfo.hold && 
              <Button onClick={()=>changeJobProgressStatus(jobStatus.COMPLETED)} class="blackbtn logon-btn-space" component="button" variant="body2" 
              style={{ marginRight: "5px" }}> Move to Complete</Button>
            }
           
            <button class="blackbtn logon-btn-space " component="button" variant="body2" style={{ marginRight: "5px" }} onClick={handlePrint}><PrintIcon style={{ marginTop: "-2px" }} /> Print</button>
            { loggedUserInfo.role.toLowerCase() === 'admin' && jobSummaryInfo.status!==jobStatus.COMPLETED && <button class="blackbtn logon-btn-space" component="button" variant="body2" onClick={onJobDeleteClick} ><DeleteIcon style={{ marginTop: "-2px" }} />Delete Job</button>}
                        
            <button onClick={onJobAdditionalDetailsClick} class="blackbtn logon-btn-space " component="button" variant="body2" style={{ marginRight: "5px" }}  ><PostAddIcon style={{ marginTop: "-2px", marginRight: "2px" }} />Job Additional Detail</button>&nbsp;
          </div>
        </div>
       
        <div ref={componentRef}>
          <div
            class="col-lg-12 block-header-summary-3"
            style={{ fontWeight: "bold" }}
          >
            Detailed Job View :<span> {jobSummaryInfo.jobId}</span>
            Is Hold?: <span>{jobSummaryInfo.hold===true?"YES":"NO"}</span>            
            STATUS:<span> {jobSummaryInfo.status}</span>          
          </div>
          <JobView
            loggedUserInfo={loggedUserInfo}
            jobSummaryInfo={jobSummaryInfo}
            onSummaryEditClick={onJobSummaryEditClick}
          />
        </div>

        <ConfirmationDialog
          id="confirmation-dialog"
          keepMounted
          open={dialogObj.open}
          onClose={dialogObj.handleClose}
          title={dialogObj.title}
          params={dialogObj.params}
          content={dialogObj.content}
        ></ConfirmationDialog>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    jobSummaryInfo: state.jobsDashboardReducer.jobOrderDetail || {},
    loggedUserInfo: getLoggedInUserInfo(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteJobClickAction: (payload) => dispatch(onDeleteJobClick(payload)),
    archiveJobClickAction: (payload) => dispatch(onArchiveJobClick(payload)),
    onHoldJobAction: (payload)=>dispatch(onHoldStatusChangeJob(payload)),
    onJobStatusChangeAction: (payload)=>dispatch(onSaveJobInfo(payload)),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthenticationHandler(JobDetailedView)))
);

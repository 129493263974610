import { SET_LOGGEDIN_USER_INFO, ON_SUBMIT_LOGIN, LOGGED_OUT_USER, ON_LOGIN_FAILURE } from "./types";

const initialState = {};

const userFormReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case ON_SUBMIT_LOGIN: {
      return {
        ...state,
        credentials: payload,
      };
    }

    case SET_LOGGEDIN_USER_INFO: {
      return {
        ...state,
        loggedUserInfo: payload,
      };
    }
    case LOGGED_OUT_USER:{
      return {
        ...state,
        loggedUserInfo: null,
      };
    }
    case ON_LOGIN_FAILURE:{
      return {
        ...state,
        loginFailureMessage: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default userFormReducer;

export const ON_NEW_CONTRACTOR_CLICK = 'ON_NEW_CONTRACTOR_CLICK';
export const ON_VIEW_CONTRACTOR_CLICK = 'ON_VIEW_CONTRACTOR_CLICK';
export const ON_EDIT_CONTRACTOR_CLICK = 'ON_EDIT_CONTRACTOR_CLICK';
export const ON_DELETE_CONTRACTOR_CLICK = 'ON_DELETE_CONTRACTOR_CLICK';
export const ON_LIST_CONTRACTORS = 'ON_LIST_CONTRACTORS';
export const ON_CONTRACTOR_CREATE = 'ON_CONTRACTOR_CREATE';
export const ON_CONTRACTOR_SAVE = 'ON_CONTRACTOR_SAVE';

export const SET_CONTRACTOR_VIEW = 'SET_CONTRACTOR_VIEW';
export const SET_CONTRACTOR_EDIT = 'SET_CONTRACTOR_EDIT';
export const SET_CONTRACTORS_LIST = 'SET_CONTRACTORS_LIST';

export const ON_ERROR = 'ON_ERROR';
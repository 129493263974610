import React, { useEffect, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { styles } from './styles';
import { useForm } from "react-hook-form";
import { connect } from 'react-redux';
import Button from "@material-ui/core/Button";
import PersonIcon from '@material-ui/icons/Person';
import { onLoadJobsCountryStates, onSaveJobInfo } from "../dashboard/ducks/actions";
import { MenuItem } from "@material-ui/core";
import { jobStatus } from "./job-status";


const CustomerInfo = (props) => {
  const { isEditMode, onSaveJobCustomerInfoAction, onStepperNextClick, onSummaryEditClick, countryStatesInfo, countryStatesAction, jobOrderDetail } = props;

  const [selectedState, setSelectedState] = useState('CA');
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");

  const { register, handleSubmit, setValue, errors } = useForm({
    mode: "onChange"
  });

  useEffect(() => {
    countryStatesAction();
  }, []);

  const countryStates = countryStatesInfo;

  const onSubmit = data => {
    var jobDetail = {};
    //validate phonenumber and zipcode
    if(!setPhoneNumber(data.phone) || !setZipCode(data.zipCode)){
      return false;
    }

    //if we have job id then append to data..
    let customer = {
      companyName: data.companyName,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      address: {
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        state: data.state,
        zipCode: data.zipCode
      }
    };
    if (jobOrderDetail.jobId) {
      jobDetail = { jobId: jobOrderDetail.jobId, jobDescription: data.description, customerId: jobOrderDetail.customerId, customerInfo: { ...customer } }
    } else {
      jobDetail = { customerInfo: { ...customer }, jobDescription: data.description, status: jobStatus.INITIATED }
    }
    onSaveJobCustomerInfoAction(jobDetail);
    onStepperNextClick();

  };
  const onClickFinish = (updateData) => {

    //validate phonenumber and zipcode
    if(!setPhoneNumber(updateData.phone) || !setZipCode(updateData.zipCode)){
      return false;
    }

    if (jobOrderDetail.jobId) {
      let customer = {
        companyName: updateData.companyName,
        firstName: updateData.firstName,
        lastName: updateData.lastName,
        email: updateData.email,
        phone: updateData.phone,
        address: {
          addressLine1: updateData.addressLine1,
          addressLine2: updateData.addressLine2,
          city: updateData.city,
          state: updateData.state,
          zipCode: updateData.zipCode
        }
      };
      onSaveJobCustomerInfoAction({ jobId: jobOrderDetail.jobId, jobDescription: updateData.description, customerId: jobOrderDetail.customerId, customerInfo: { ...customer } });
    }
    onSummaryEditClick(6, false);
  }

  const setStateDefaultValue = () => {
    const value = jobOrderDetail.customerInfo && jobOrderDetail.customerInfo.address ? jobOrderDetail.customerInfo.address.state : 'CA';
    setValue('state', value, true);
    return value;
  }

  const setPhoneNumber = (phoneNumber) => {

    if(!phoneNumber){
      setPhoneNumberError("Phone is required");
      return false;
    }else {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if(!pattern.test(phoneNumber)) {
        setPhoneNumberError("Please enter only numbers");
        return false;
      }else if(phoneNumber.length != 10){
        setPhoneNumberError("Please enter valid 10 digit phone number");
        return false;
      }
    }
    setPhoneNumberError("");
    return true;
  }

  const setZipCode = (zipCode) => {

    if(!zipCode){
      setZipCodeError("Zip Code is required");
      return false;
    }else {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if(!pattern.test(zipCode)) {
        setZipCodeError("Please enter only numbers");
        return false;
      }else if(zipCode.length != 5){
        setZipCodeError("Please enter valid 5 digit zip code");
        return false;
      }
    }
    setZipCodeError("");
    return true;
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div class="col-lg-12 container-center text-left container-stepper" style={{}} >
          <div class="col-lg-12 text-left">
            <div class="col-lg-12 block-header" style={{ fontWeight: 'bold' }} >Customer Information  <PersonIcon fontSize='small' style={{ marginLeft: '5px' }} /></div>
            <div class="row text-left" style={{ paddingLeft: '20px' }}>
              <div class="col-lg-3 d-inline"><TextField autoFocus margin="dense" id="name" name="companyName" defaultValue={jobOrderDetail.customerInfo ? jobOrderDetail.customerInfo.companyName : ''}
                inputRef={register({ required: false })} label="Company Name" type="string" style={{ paddingRight: '30px' }} /></div>
            </div>
            <div class="row text-left" style={{ paddingLeft: '20px' }}>
              <div class="col-lg-3 d-inline"><TextField autoFocus margin="dense" id="name" name="firstName" defaultValue={jobOrderDetail.customerInfo ? jobOrderDetail.customerInfo.firstName : ''} inputRef={register({ required: true })} label="First Name" type="string" style={{ paddingRight: '30px' }} />
                {errors.firstname && errors.firstname.type === "required" && <span class="error-val-msg">First name is required.</span>}</div>
              <div class="col-lg-3 d-inline"><TextField autoFocus margin="dense" id="name" name="lastName" defaultValue={jobOrderDetail.customerInfo ? jobOrderDetail.customerInfo.lastName : ''} inputRef={register({ required: true })} label="Last Name" type="string" style={{ paddingRight: '30px' }} />
                {errors.lastname && errors.lastname.type === "required" && <span class="error-val-msg">Last name is required.</span>}</div>
              <div class="col-lg-3 d-inline"><TextField autoFocus margin="dense" id="name" name="email" defaultValue={jobOrderDetail.customerInfo ? jobOrderDetail.customerInfo.email : ''} inputRef={register({ required: true })} label="Email" type="email" style={{ paddingRight: '30px' }} />
                {errors.email && errors.email.type === "required" && <span class="error-val-msg">Email is required.</span>}</div>
              <div class="col-lg-3 d-inline"><TextField autoFocus margin="dense" id="name" name="phone" defaultValue={jobOrderDetail.customerInfo ? jobOrderDetail.customerInfo.phone : ''} inputRef={register({ required: true })} label="Phone" type="tel" onChange={e => setPhoneNumber(e.target.value)} style={{ paddingRight: '30px' }} />
                {phoneNumberError && <span class="error-val-msg">{phoneNumberError}</span>}</div>
            </div>
            <div class="row text-left" style={{ paddingLeft: '20px' }}>
              <div class="col-lg-3 d-inline"> <TextField autoFocus margin="dense" id="addressline1" name="addressLine1" defaultValue={jobOrderDetail.customerInfo && jobOrderDetail.customerInfo.address? jobOrderDetail.customerInfo.address.addressLine1 : ''} inputRef={register({ required: true })} label="Address Line 1" type="string" style={{ paddingRight: '30px' }} />
                {errors.addressline1 && errors.addressline1.type === "required" && <span class="error-val-msg">Address Line 1 is required</span>}</div>
              <div class="col-lg-3 d-inline"> <TextField autoFocus margin="dense" id="addressline2" name="addressLine2" defaultValue={jobOrderDetail.customerInfo && jobOrderDetail.customerInfo.address? jobOrderDetail.customerInfo.address.addressLine2 : ''} inputRef={register({ required: false })} label="Address Line 2 (Optional)" type="string" style={{ paddingRight: '30px' }} />
              </div>
              <div class="col-lg-3 d-inline"> <TextField autoFocus margin="dense" id="city" name="city" defaultValue={jobOrderDetail.customerInfo && jobOrderDetail.customerInfo.address? jobOrderDetail.customerInfo.address.city : ''} inputRef={register({ required: true })} label="City" type="string" style={{ paddingRight: '30px' }} />
                {errors.city && errors.city.type === "required" && <span class="error-val-msg">City is required</span>}</div>
              <div class="col-lg-3 d-inline"> <TextField autoFocus margin="dense" id="zipcode" name="zipCode" defaultValue={jobOrderDetail.customerInfo && jobOrderDetail.customerInfo.address? jobOrderDetail.customerInfo.address.zipCode : ''} inputRef={register({ required: true })} label="Zip Code" type="string" onChange={e => setZipCode(e.target.value)} style={{ paddingRight: '30px' }} />
               {zipCodeError && <span class="error-val-msg">{zipCodeError}</span>}</div>
            </div>
            <div class="row text-left" style={{ paddingLeft: '20px', marginTop: '15px' }}>
              <div class="col-lg-3 d-inline">
                {/* <TextField autoFocus margin="dense" id="name" name="installAddress"  inputRef={register({ required: true })} label="Zip Code" type="string"  style={{ paddingRight: '30px' }} /> */}
                {/* <TextField autoFocus margin="dense" id="standard-select-currency-native" select  label="state" name='state1' value={countryStates} inputRef={register({ required: true})} helperText="Please select state"  >
                  {countryStates.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.id}
                    </option>
                  ))}
                </TextField> */}
                <TextField autoFocus margin="dense" id='state' name='state' inputRef={register({ name: 'state' })} select label='state' defaultValue={setStateDefaultValue} onChange={e => setValue('state', e.target.value, true)} style={{ width: "200px" }} >
                  {countryStates.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {`${option.name}( ${option.id})`}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            <div class="row text-left" style={{ paddingLeft: '20px' }}>
              <div class="col-lg-12" id="note-margin">
                <TextField
                  id="outlined-basic"
                  name="description"
                  multiline
                  inputRef={register({ required: false })}
                  rows={3}
                  rowsMax={3}
                  defaultValue={jobOrderDetail ? jobOrderDetail.jobDescription : ''}
                  label="Description"
                  variant="outlined"
                  style={{ width: "100%", height: "20px", marginBottom: "55px" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12  text-left" style={{ marginTop: '10px' }} >
          <div style={{ textAlign: 'right' }}>

            <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)} style={{ marginRight: "10px!important" }} >Next</Button>
            {isEditMode && <Button variant="contained" color="primary" onClick={handleSubmit(onClickFinish)}  >{" "}Finish</Button>}
          </div>
        </div>

      </form>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    jobOrderDetail: state.jobsDashboardReducer.jobOrderDetail || {},
    countryStatesInfo: state.jobsDashboardReducer.countryList || []
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSaveJobCustomerInfoAction: (payload) => dispatch(onSaveJobInfo(payload)),
    countryStatesAction: (payload) => dispatch(onLoadJobsCountryStates(payload))
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CustomerInfo));
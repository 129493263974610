import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const ViewEditDeleteRenderer = (props) => {

    const handleClickView = () => {
        const { api, context: { onViewClick }, data } = props;
        onViewClick(data);
    }

    const handleClickEdit = () => {
        const { api, context: { onEditClick }, data } = props;
        onEditClick(data);
    }

    const handleClickDelete = () => {
        const { api, context: { onDeleteClick }, data } = props;
        onDeleteClick(data);
    }

    return (
        <div>
            <span className="m-r-10 m-l-10 action-icon" onClick={handleClickView}><VisibilityIcon /></span>
            <span className="m-r-10 m-l-10 action-icon" onClick={handleClickEdit}><EditIcon /></span>
            <span className="m-r-10 m-l-10 action-icon" onClick={handleClickDelete}><DeleteIcon /></span>
        </div>
    );
}

export default ViewEditDeleteRenderer;
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Link from "@material-ui/core/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { onLoggedOutUser } from "./../user-form/ducks/actions";
import logo from "../images/oflogo.png";
import classNames from "classnames";
import {getLoggedInUserInfo} from './get-logged-user-detail';


const HeaderPage = (props) => {
  const [value, setValue] = useState(0);
  const [activeButton, setActiveButton] = useState("Dashboard");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const {
    loggedUserInfo,
    logoutUserAction,
    location,
    classes: {
      root,
      labelFont,
      toppaper,
      toppapernav,
      toppaperBtnGrp,
      headermain,
    },
  } = props;

  useEffect(() => {
    const menuItems = [];
    menuItems.push({ key: "dashboard", value: "dashboard" });
    menuItems.push({ key: "jobs", value: "jobs" });
    menuItems.push({ key: "job-detailed-view", value: "jobs" });
    menuItems.push({ key: "job-additional-details", value: "jobs" });
    menuItems.push({ key: "customer", value: "customer" });
    menuItems.push({ key: "customer-form", value: "customer" });
    menuItems.push({ key: "contractor", value: "contractor" });
    menuItems.push({ key: "contractor-form", value: "contractor" });
    menuItems.push({ key: "installer", value: "installer" });
    menuItems.push({ key: "installer-form", value: "installer" });
    menuItems.push({ key: "reports", value: "reports" });
    menuItems.push({ key: "account-setup", value: "account-setup" });

    if (location) {
      const path = location.pathname.substring(1);
      const selectedMenu = menuItems.find(x => x.key === path);
      if (selectedMenu) {
        setActiveButton(selectedMenu.value);
      }
    }
  }, [location]);

  const displayLoginPage = () => {
    props.history.push("/login");
  };
  const displayLogoutPage = () => {
    //clear session storage 
    localStorage.removeItem("loggedinUserInfo");
    setActiveButton("Jobs");
    logoutUserAction();
    props.history.push("/logout");
  };
  const enquireNowPage = () => {
    props.history.push("/enquire");
  };
  const displayHomePage = () => {
    props.history.push("/");
  };




  return (
    <div>
      <div class="row" className={toppaper}>
        <div class="col-lg-2">
          <img
            src={logo}
            onClick={displayHomePage}
            class="logo"
            width="218"
            height="68"
          />
        </div>
        <div class="col-lg-12" style={{ textAlign: "right" }}>
          <ButtonGroup
            className={toppaperBtnGrp}
            color="primary"
            aria-label="outlined primary button group"
            style={{ marginTop: "10px", marginBottom: "5px" }}
          ></ButtonGroup>
          {loggedUserInfo.firstName && (
            <div id="utype-mb">
              <span class="user-type" style={{ marginTop: "15px" }}>
                Welcome
              </span>
              <span class="user-name-class" style={{ marginTop: "25px" }}>
                {loggedUserInfo.firstName}
              </span>
              <span class="user-welcome" style={{ marginTop: "25px" }}>
                &nbsp;&#40;{loggedUserInfo.role}&#41;
              </span>
            </div>
          )}
        </div>
      </div>
      <div>


      </div>
      <nav class="navbar navbar-expand-lg navbar-light">
        <button class="navbar-toggler ml-auto custom-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown" style={{ display: "inline!important" }}>

          {/* <div class="row" style={{ maxWidth:"100%" }}> */}
          <div class="col-lg-12" id="m-margin-mb" style={{ maxWidth: "100%" }}>
            <div class="col-lg-9" style={{ display: "inline-flex" }}>
              {loggedUserInfo.isAuthenticated && (
                <div align="left">
                  {loggedUserInfo.role === "admin" && (
                    <input
                      class="topmenubtn-n"
                      type="button"
                      value="Dashboard"
                      className={
                        activeButton === "dashboard"
                          ? classNames("topmenubtn-n topmenubtn-active")
                          : "topmenubtn-n"
                      }
                      onClick={() => {
                        props.history.push("/dashboard");
                      }}
                    />
                  )}
                  <input
                    class="topmenubtn-n"
                    type="button"
                    value="Jobs"
                    className={
                      activeButton === "jobs"
                        ? classNames("topmenubtn-n topmenubtn-active")
                        : "topmenubtn-n"
                    }
                    onClick={() => {
                      props.history.push("/jobs");
                    }}
                  />
                  {loggedUserInfo.role === "admin" && (
                    <input
                      class="topmenubtn-n"
                      type="button"
                      value="Customers"
                      className={
                        activeButton === "customer"
                          ? classNames("topmenubtn-n topmenubtn-active")
                          : "topmenubtn-n"
                      }
                      onClick={() => {
                        props.history.push("/customer");
                      }}
                    />
                  )}
                  {loggedUserInfo.role === "admin" && (
                    <input
                      class="topmenubtn-n"
                      type="button"
                      value="Contractors"
                      className={
                        activeButton === "contractor"
                          ? classNames("topmenubtn-n topmenubtn-active")
                          : "topmenubtn-n"
                      }
                      onClick={() => {
                        props.history.push("/contractor");
                      }}
                    />
                  )}
                  {loggedUserInfo.role === "admin" && (
                    <input
                      class="topmenubtn-n"
                      type="button"
                      value="Installers"
                      className={
                        activeButton === "installer"
                          ? classNames("topmenubtn-n topmenubtn-active")
                          : "topmenubtn-n"
                      }
                      onClick={() => {
                        props.history.push("/installer");
                      }}
                    />
                  )}
                  {loggedUserInfo.role === "admin" && (
                    <span>
                      <input class="topmenubtn-n" type="button" value="Reports" />
                    </span>
                  )}
                </div>
              )}
            </div>
            <div class="col-lg-3 text-right" id="d-mb" style={{ display: "inline-flex" }}>
              {loggedUserInfo.role === "admin" && (
                <button
                  class="topmenubtn-n"
                  className={
                    activeButton === "account-setup"
                      ? classNames("topmenubtn-n topmenubtn-active")
                      : "topmenubtn-n"
                  }
                  onClick={() => {
                    props.history.push("/account-setup");
                  }}
                >
                  Account Settings{" "}
                </button>
              )}
              {loggedUserInfo.isAuthenticated && (
                <Link
                  class="topmenubtn"
                  component="button"
                  variant="body2"
                  onClick={displayLogoutPage}
                >
                  Sign out
                </Link>
              )}

              {!loggedUserInfo.isAuthenticated && (
                <Link
                  class="topmenubtn align-right"
                  component="button"
                  variant="body2"
                  onClick={displayLoginPage}
                >
                  Sign in
                </Link>
              )}

            </div>
          </div>
        </div>

      </nav>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUserInfo: getLoggedInUserInfo(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUserAction: () => dispatch(onLoggedOutUser()),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderPage))
);


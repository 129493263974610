import {
  ON_NEW_CUSTOMER_CLICK,
  ON_VIEW_CUSTOMER_CLICK,
  ON_EDIT_CUSTOMER_CLICK,
  ON_DELETE_CUSTOMER_CLICK,
  ON_LIST_CUSTOMERS,
  ON_CUSTOMER_CREATE,
  ON_CUSTOMER_SAVE
} from './types';

export const onNewCustomerClick = (payload) => ({
  type: ON_NEW_CUSTOMER_CLICK, payload
});

export const onViewCustomerClick = (payload) => ({
  type: ON_VIEW_CUSTOMER_CLICK, payload
});

export const onEditCustomerClick = (payload) => ({
  type: ON_EDIT_CUSTOMER_CLICK, payload
});

export const onDeleteCustomerClick = (payload) => ({
  type: ON_DELETE_CUSTOMER_CLICK, payload
});

export const onListCustomers = () => ({
  type: ON_LIST_CUSTOMERS
});

export const onCustomerCreate = (payload) => ({
  type: ON_CUSTOMER_CREATE, payload
});

export const onCustomerSave = (payload) => ({
  type: ON_CUSTOMER_SAVE, payload
});
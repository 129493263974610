import React from "react";
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { styles } from './styles';
import { useForm } from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Button from "@material-ui/core/Button";
import BorderClearIcon from '@material-ui/icons/BorderClear';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { onSaveJobInfo } from "../dashboard/ducks/actions";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const ProductInfo = (props) => {
  const { isEditMode, onSummaryEditClick, onSaveJobProductInfoAction, onStepperNextClick, onStepperBackClick, jobOrderDetail } = props;
  const classes = useStyles();

  const { register, handleSubmit, setValue } = useForm({
    mode: "onChange"
  });

  const onSubmit = (data) => {
    if (jobOrderDetail.jobId) {
      onSaveJobProductInfoAction({ jobId: jobOrderDetail.jobId, productInfo: { ...data } });
    } else {
      return false;
    }
    console.log("till this time Product Info", jobOrderDetail);
    console.log("Product Info data ", data);
    onStepperNextClick();
  };

  const handleBack = () => {
    onStepperBackClick();
  };

  const onClickFinish = (updateData) => {
    onSaveJobProductInfoAction({ jobId: jobOrderDetail.jobId, productInfo: { ...updateData } });
    onSummaryEditClick(6, false);
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>

        <div class="col-lg-12 container-center text-left container-stepper" style={{}} >
          <div class="col-lg-12 text-left">
            <div class="col-lg-12 block-header" style={{ fontWeight: 'bold' }} >Product Information  <BorderClearIcon fontSize='small' style={{ marginLeft: '5px' }} /></div>
            <div class="typeofmetcss">
              <div>
                <div class="row text-left" style={{ paddingLeft: '20px' }}>
                  <div class="col-lg-3 d-inline"><TextField autoFocus margin="dense" id="manufacture" name="manufacture" inputRef={register} defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.manufacture : ''} label="Manuf." type="string" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="style" name="style" inputRef={register} label="Style" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.style : ''} type="string" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="color" name="color" inputRef={register} label="Color" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.color : ''} type="email" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="amtOrdered" name="amtOrdered" inputRef={register} defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.amtOrdered : ''} label="Amt. Ordered" type="tel" style={{ paddingRight: '30px' }} /></div>
                </div>
                <br></br>
              </div>
              <div>
                <div class="col-lg-12 block-header-2"  >Transitions Needed</div>
                <div class="row text-left" style={{ paddingLeft: '20px' }}>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="endcap" name="endcap" inputRef={register} defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.endcap : ''} label="End Cap" type="string" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="endcaplength" name="endcapLength" inputRef={register} defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.endcapLength : ''} label="Length" type="string" style={{ paddingRight: '30px' }} /></div>
                </div>
                <br></br>
                <div class="row text-left" style={{ paddingLeft: '20px' }}>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="tmolding" name="tmolding" inputRef={register} defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.tmolding : ''} label="T-Molding" type="email" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="tmoldinglength" name="tmoldingLength" inputRef={register} defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.tmoldingLength : ''} label="Length" type="tel" style={{ paddingRight: '30px' }} /></div>
                </div>
                <br></br>
                <div class="row text-left" style={{ paddingLeft: '20px' }}>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="reducer" name="reducer" inputRef={register} defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.reducer : ''} label="Reducer" type="string" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="reducerLength" name="reducerLength" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.reducerLength : ''} inputRef={register} label="Length" type="string" style={{ paddingRight: '30px' }} /></div>
                </div>
                <br></br>
              </div>
              <div class="hr-height"></div>
              <div>
                <div class="col-lg-12 block-header-2"  >Stairs</div>
                <div class="row text-left" style={{ paddingLeft: '20px' }}>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="flushMount" name="flushMount" inputRef={register} defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.flushMount : ''} label="Flush Mount" type="string" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="overlap" name="overlap" inputRef={register} defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.overlap : ''} label="Overlap" type="string" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="treadWoodLam" name="treadWoodLam" inputRef={register} defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.treadWoodLam : ''} label="Tread-Wood/Lam" type="email" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="treadRubberVinyl" name="treadRubberVinyl" inputRef={register} defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.treadRubberVinyl : ''} label="Tread-Rubber / Vinyl" type="tel" style={{ paddingRight: '30px' }} /></div>
                </div>
                <br></br>
                <div class="row text-left" style={{ paddingLeft: '20px' }}>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="bullnoseMetal" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.bullnoseMetal : ''} name="bullnoseMetal" inputRef={register} label="Bullnose-Metal" type="string" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="bullnoseRubberVinyl" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.bullnoseRubberVinyl : ''} name="bullnoseRubberVinyl" inputRef={register} label="Bullnose-Rubber/Vinyl" type="string" style={{ paddingRight: '30px' }} /></div>
                </div>
                <br></br>
                <div class="hr-height"></div>
              </div>
              <div>
                <div class="col-lg-12 block-header-2">Furniture Responsibility</div>
                <div class="row text-left" style={{ paddingLeft: '20px' }}>
                  <table style={{ marginLeft: "30px", marginTop: "20px" }}>
                    <tr>
                      <td class="tdStylecss">
                        <FormControlLabel
                          control={<Checkbox color="primary" />}
                          name='osuna'
                          inputRef={register}
                          defaultChecked={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.osuna : 'false'}
                          onChange={e => setValue('osuna', e.target.checked)}
                          label="Osuna"
                          labelPlacement="end"
                        />
                      </td>
                      <td class="tdStylecss">
                        <FormControlLabel
                          control={<Checkbox color="primary" />}
                          name='customer'
                          inputRef={register}
                          defaultChecked={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.customer  : 'false'}
                          onChange={e => setValue('customer', e.target.checked)}
                          label="Customer"
                          labelPlacement="end"
                        />
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="hr-height"></div>
              </div>
              <div>
                <div class="col-lg-12 block-header-2"  >Floor Preparation / Demo: Sub-Floor</div>

                <div class="row text-left" style={{ paddingLeft: '20px' }}>
                  <table style={{ marginLeft: "30px", marginTop: "20px" }}>
                    <tr>
                      <td class="tdStylecss">
                        {/* <FormControlLabel
                          control={<Checkbox color="primary" />}
                          name='concrete'
                          inputRef={register}
                          defaultChecked={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.concrete : 'false'}
                          onChange={e => setValue('concrete', e.target.checked)}
                          label="Concrete"
                          labelPlacement="end"
                        /> */}
                      <Checkbox
                        defaultChecked={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.concrete : false}
                        onChange={e => setValue('concrete', e.target.checked)}
                        inputRef={register}
                        name='concrete'
                        color="primary"
                    />
                     <span style={{ paddingLeft: "5px" }}>Concrete</span>
                      </td>
                      <td class="tdStylecss">
                        {/* <FormControlLabel
                          control={<Checkbox color="primary" />}
                          name='wood'
                          inputRef={register}
                          defaultChecked={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.wood : 'false'}
                          onChange={e => setValue('wood', e.target.checked)}
                          label="Wood"
                          labelPlacement="end"
                        /> */}
                        <Checkbox
                        defaultChecked={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.wood : false}
                        onChange={e => setValue('wood', e.target.checked)}
                        inputRef={register}
                        name='wood'
                        color="primary"
                    />
                     <span style={{ paddingLeft: "5px" }}>Wood</span>
                      </td>
                      <td class="tdStylecss">
                        {/* <FormControlLabel
                          control={<Checkbox color="primary" />}
                          name='elasticel'
                          inputRef={register}
                          defaultChecked={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.elasticel : 'false'}
                          onChange={e => setValue('elasticel', e.target.checked)}
                          label="Elasticel"
                          labelPlacement="end"
                        /> */}
                        <Checkbox
                        defaultChecked={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.elasticel : false}
                        onChange={e => setValue('elasticel', e.target.checked)}
                        inputRef={register}
                        name='elasticel'
                        color="primary"
                    />
                     <span style={{ paddingLeft: "5px" }}>Elasticel</span>
                      </td>
                    </tr>
                  </table>
                </div>

                <div class="row text-left" style={{ paddingLeft: '20px' }}>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="existingFloorCoveringDemolition" name="existingFloorCoveringDemolition" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.existingFloorCoveringDemolition : ''} inputRef={register} label="Existing Floor-Covering" type="string" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="scrape" name="scrape" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.scrape : ''} inputRef={register} label="Scrape" type="string" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="grinding" name="grinding" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.grinding : ''} inputRef={register} label="Grinding" type="string" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="floorPrepFillPatchLevel" name="floorPrepFillPatchLevel" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.floorPrepFillPatchLevel : ''} inputRef={register} label="Floor Prep-Fill/Patch/Level" type="tel" style={{ paddingRight: '30px' }} /></div>
                </div>

                <div class="row text-left" style={{ paddingLeft: '20px' }}>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="amountofDemo" name="amountofDemo" inputRef={register} defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.amountofDemo : ''} label="Amount of Demo (sq ft)" type="string" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="woodUnderlaymentRequired" name="woodUnderlaymentRequired" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.woodUnderlaymentRequired : ''} inputRef={register} label="Wood Underlayment (sq ft)" type="string" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="base" name="base" inputRef={register} defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.base : ''} label="Base" type="string" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="round" name="round" inputRef={register} defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.round : ''} label="1/4 Round" type="string" style={{ paddingRight: '30px' }} /></div>
                </div>
                <div class="row text-left" style={{ paddingLeft: '20px' }}>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="woodSpecies" name="woodSpecies" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.woodSpecies : ''} inputRef={register} label="Wood Species" type="string" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="paintStainColor" name="paintStainColor" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.paintStainColor : ''} inputRef={register} label="Paint/Stain Color" type="string" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="styleDimensions" name="styleDimensions" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.styleDimensions : ''} inputRef={register} label="Style/Dimensions" type="string" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="woodSpecieslength" name="woodSpecieslength" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.woodSpecieslength : ''} inputRef={register} label="Length" type="string" style={{ paddingRight: '30px' }} /></div>
                </div>
                <br></br>
                <div class="hr-height"></div>
              </div>
              <div>
                <div class="col-lg-12 block-header-2"  >Top Set</div>
                <div class="row text-left" style={{ paddingLeft: '20px' }}>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="VinylRubber" name="VinylRubber" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.VinylRubber : ''} inputRef={register} label="Vinyl or Rubber" type="string" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="VinylRubberColor" name="VinylRubberColor" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.VinylRubberColor : ''} inputRef={register} label="Color" type="string" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="VinylRubberSize" name="VinylRubberSize" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.VinylRubberSize : ''} inputRef={register} label="Size" type="string" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="VinylRubberLength" name="VinylRubberLength" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.VinylRubberLength : ''} inputRef={register} label="Length" type="string" style={{ paddingRight: '30px' }} /></div>
                </div>
                <br></br>
                <div class="hr-height"></div>
              </div>
              <div>
                <div class="col-lg-12 block-header-2"  >Appliances</div>
                <div class="row text-left" style={{ paddingLeft: '20px' }}>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="fridge" name="fridge" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.fridge : ''} inputRef={register} label="Fridge" type="string" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="icemaker" name="icemaker" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.icemaker : ''} inputRef={register} label="Icemaker" type="string" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="dryer" name="dryer" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.dryer : ''} inputRef={register} label="Dryer" type="email" style={{ paddingRight: '30px' }} /></div>
                  <div class="col-lg-3 d-inline"><TextField margin="dense" id="toilets" name="toilets" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.toilets : ''} inputRef={register} label="Toilet(s)" type="tel" style={{ paddingRight: '30px' }} /></div>
                </div>
                <br></br>
                <div class="row text-left" style={{ paddingLeft: '20px' }}>
                  <div class="col-lg-6 d-inline"><TextField margin="dense" id="other" name="other" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.other : ''} inputRef={register} label="Other" type="string" style={{ paddingRight: '30px' }} /></div>
                </div>
                <br></br>
                <div class="hr-height"></div>
              </div>
              <div>
                <div class="col-lg-12 block-header-2"  >Utilities Available</div>
                <div class="row text-left" style={{ paddingLeft: '20px' }}>
                  <table style={{ marginLeft: "30px", marginTop: "20px" }}>
                    <tr>
                      <td class="tdStylecss">
                        {/* <FormControlLabel
                          control={<Checkbox color="primary" />}
                          name='electricity'
                          inputRef={register}
                          defaultChecked={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.electricity : false}
                          onChange={e => setValue('electricity', e.target.checked)}
                          label="Electricity"
                          labelPlacement="end"
                        /> */}
                        <Checkbox
                        name='electricity'
                        inputRef={register}
                        defaultChecked={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.electricity : false}
                        onChange={e => setValue('electricity', e.target.checked)}
                        label="Electricity"
                        labelPlacement="end"
                        color="primary"
                    />
                    <span style={{ paddingLeft: "5px" }}>Electricity</span>
                      </td>
                      <td class="tdStylecss">
                        {/* <FormControlLabel
                          control={<Checkbox color="primary" />}
                          name='runningwater'
                          inputRef={register}
                          defaultChecked={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.runningwater : false}
                          onChange={e => setValue('runningwater', e.target.checked)}
                          label="Running Water"
                          labelPlacement="end"
                        /> */}
                        <Checkbox
                        name='runningwater'
                        inputRef={register}
                        defaultChecked={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.runningwater : false}
                        onChange={e => setValue('runningwater', e.target.checked)}
                        label="Running Water"
                        labelPlacement="end"
                        color="primary"
                    />
                    <span style={{ paddingLeft: "5px" }}>Running Water</span>
                      </td>
                      <td class="tdStylecss">
                        {/* <FormControlLabel
                          control={<Checkbox color="primary" />}
                          name='utilitytoilet'
                          inputRef={register}
                          defaultChecked={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.toilet : false}
                          onChange={e => setValue('utilitytoilet', e.target.checked)}
                          label="Toilet"
                          labelPlacement="end"
                        /> */}
                        <Checkbox
                        name='utilitytoilet'
                        inputRef={register}
                        defaultChecked={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.toilet : false}
                        onChange={e => setValue('utilitytoilet', e.target.checked)}
                        label="Toilet"
                        labelPlacement="end"
                        color="primary"
                    />
                    <span style={{ paddingLeft: "5px" }}>Toilet</span>
                      </td>
                    </tr>
                  </table>
                </div>


                <div class="col-lg-12" id="note-margin">
                  <TextField
                    id="othercomments"
                    name="othercomments"
                    inputRef={register({ required: false })}
                    multiline
                    rows={3}
                    rowsMax={3}
                    label="Other Comments / Instructions"
                    defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.othercomments : ''}
                    variant="outlined"
                    style={{
                      width: "100%",
                      height: "20px",
                      marginBottom: "55px",
                    }}
                  />
                </div>
                <br></br>
                <div class="row text-left" style={{ paddingLeft: '20px' }}>
                  <div class="col-lg-6 d-inline"><TextField fullWidth margin="dense" id="signature" name="signature" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.signature : ''} inputRef={register} label="Customer Signature of Acceptance" type="string" style={{ paddingRight: '30px' }} /></div>
                </div>

                <div class="col-lg-3 d-inline" style={{ marginLeft: '0px' }} >
                  <TextField id="pdate" name="pdate" defaultValue={jobOrderDetail.productInfo ? jobOrderDetail.productInfo.pdate : ''} label="Date" inputRef={register} type="date" className={classes.textField} InputLabelProps={{ shrink: true }} />
                </div>
                <br></br>
                <br></br>
                <div class="hr-height"></div>
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12  text-left" style={{ marginTop: "10px" }}>
          <div style={{ textAlign: "right" }}>
            <Button onClick={handleBack}>Back</Button>
            <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)} >{" "}Next</Button>
            {isEditMode && <Button variant="contained" color="primary" onClick={handleSubmit(onClickFinish)} >{" "}Finish</Button>}
          </div>
        </div>
      </form>
    </div>
  );
}


const mapStateToProps = state => {
  return {
    jobOrderDetail: state.jobsDashboardReducer.jobOrderDetail || {},
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSaveJobProductInfoAction: (payload) => dispatch(onSaveJobInfo(payload))
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ProductInfo));
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import TextField from "@material-ui/core/TextField";
import { styles } from "./styles";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import ActionCellRenderer from "../grid-cell-renderers/action-cell-renderer";
import { v4 as uuidv4 } from "uuid";
import { onSaveJobInfo, onDeleteUpload } from "../dashboard/ducks/actions";
import FileNameLinkCellRenderer from "../grid-cell-renderers/file-name-link-cell-renderer";
import AddIcon from "@material-ui/icons/Add";
import PublishIcon from '@material-ui/icons/Publish';
import UploadedFileactionCellRenderer from "../grid-cell-renderers/upload-file-action-cell-renderer";
import {getLoggedInUserInfo} from './../shared/get-logged-user-detail';

const getCurrentTimeStamp = () => {
  var currentdate = new Date();
  return currentdate.toUTCString();
};

const frameworkComponents = {
  actionCellRenderer: ActionCellRenderer,
  fileNameLinkCellRenderer: FileNameLinkCellRenderer,
  uploadedFileactionCellRenderer: UploadedFileactionCellRenderer
};



const defaultColDef = {
  sortable: true,
  resizable: true,
};

const JobAdditionalDetail = (props) => {
  const { loggedUserInfo, jobOrderDetail, onSaveAdditionalDetailAction, onDeleteUploadAction } = props;

  const [currentContractorNote, setCurrentContractorNote] = useState();
  const [currentInstallerNote, setCurrentInstallerNote] = useState();
  const [contractorNotes, setContractorNotes] = useState([]);
  const [installerNotes, setInstallerNotes] = useState([]);
  const [beforeImagesUpload, setBeforeImagesUpload] = useState();
  const [afterImagesUpload, setAfterImagesUpload] = useState();
  const [beforeImages, setBeforeImages] = useState([]);
  const [afterImages, setAfterImages] = useState([]);
  const [images, setImages] = useState([]);
  const [errors, setErrors] = useState({ contractorNotesError: '', installerNotesError: '', beforeImagesError: '', afterImagesError: '' });

  const onDeleteClickHandler = (params) => {
    const { data, contractorNotes, installerNotes } = params;
    let indexContractorDelete = contractorNotes.findIndex((item) => item.id === data.id);
    if (indexContractorDelete >= 0) {
      // Contractor notes to delete
      contractorNotes.splice(indexContractorDelete, 1);
      setContractorNotes([]);
      // update to database
      if (jobOrderDetail.jobId) {
        onSaveAdditionalDetailAction({ jobId: jobOrderDetail.jobId, contractorNotes: [...contractorNotes] });
      } else {
        return false;
      }
    } else {
      // installer notes to delete
      let indexInstallerDelete = installerNotes.findIndex((item) => item.id === data.id);
      if (indexInstallerDelete >= 0) {
        installerNotes.splice(indexInstallerDelete, 1);
        setInstallerNotes([]);
        // update to database
        if (jobOrderDetail.jobId) {
          onSaveAdditionalDetailAction({ jobId: jobOrderDetail.jobId, installerNotes: [ ...installerNotes ] });
        } else {
          return false;
        }
      }
    }
  };

  const onDeleteUploadFileClickHandler = (params) => {
    // upload images to delete
    const { data } = params;
    const deleteUpload = { 'docId': `${data.docId}`, 'jobId': `${jobOrderDetail.jobId}`, 'userId': `${data.uploadedBy}` };
    onDeleteUploadAction(deleteUpload);
  }

  const onFileNameSelectClickHandler = (data) => {
    fetch(
      'https://osuna.mkbedev.com/api/uploads?docId=' + `${data.docId}` + '&fileName=' + `${data.fileName}`,
      {
        method: 'GET'
      }
    ).then(response => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", data.fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    }).catch(err => {
      console.log(err);
    });
  }

  useEffect(() => {
    const clonedafterImages = [];
    const clonedBeforeImages = [];
    if (jobOrderDetail && jobOrderDetail.uploads) {
      jobOrderDetail.uploads.map(item => {
        if (item.state === 'after') {
          clonedafterImages.push(item);
        } else {
          clonedBeforeImages.push(item);
        }
      })
    }
    setAfterImages(clonedafterImages);
    setBeforeImages(clonedBeforeImages);
    setInstallerNotes(jobOrderDetail && jobOrderDetail.installerNotes ? jobOrderDetail.installerNotes : []);
    setContractorNotes(jobOrderDetail && jobOrderDetail.contractorNotes ? jobOrderDetail.contractorNotes : []);

  }, [jobOrderDetail]);

  const context = {
    onDeleteClick: onDeleteClickHandler,
    onDeleteUploadFileClick: onDeleteUploadFileClickHandler,
    onFileNameSelectClickHandler: onFileNameSelectClickHandler
  };


  const onAddContractorNotes = () => {
    setErrors({ contractorNotesError: '', installerNotesError: '', beforeImagesError: '', afterImagesError: '' });
    if (currentContractorNote) {
      const clonedContractorNotes = [...contractorNotes];
      const addedBy = `${loggedUserInfo.firstName} ${loggedUserInfo.lastName} (Role:${loggedUserInfo.role}) `;
      const noteInfo = {
        id: uuidv4(),
        note: currentContractorNote,
        addedBy,
        timestamp: getCurrentTimeStamp(),
      };
      clonedContractorNotes.push(noteInfo);
      //setContractorNotes(clonedContractorNotes);
      setCurrentContractorNote("");

      // update to database
      if (jobOrderDetail.jobId) {
        jobOrderDetail.contractorNotes = [...clonedContractorNotes];
        onSaveAdditionalDetailAction(jobOrderDetail);
      } else {
        return;
      }
    } else {
      setErrors({ contractorNotesError: "Contractor notes cannot be empty.." });
      console.log("errors ", JSON.stringify(errors));
      console.log("Contractor notes cannot be empty..");
    }
  };

  const onAddInstallerNotes = () => {
    setErrors({ contractorNotesError: '', installerNotesError: '', beforeImagesError: '', afterImagesError: '' });
    if (currentInstallerNote) {
      const clonedInstallerNotes = [...installerNotes];
      const addedBy = `${loggedUserInfo.firstName} ${loggedUserInfo.lastName} (Role:${loggedUserInfo.role}) `;
      const noteInfo = {
        id: uuidv4(),
        note: currentInstallerNote,
        addedBy,
        timestamp: getCurrentTimeStamp(),
      };
      clonedInstallerNotes.push(noteInfo);
      //setInstallerNotes(clonedInstallerNotes);
      setCurrentInstallerNote("");

      // update to database
      if (jobOrderDetail.jobId) {
        jobOrderDetail.installerNotes = [...clonedInstallerNotes];
        onSaveAdditionalDetailAction(jobOrderDetail);
      } else {
        return false;
      }
    } else {
      setErrors({ installerNotesError: "Installer notes cannot be empty.." });
      console.log("Installer notes cannot be empty..");
    }
  };

  const fileHandlerBeforeImages = (e) => {
    console.log("fileHandlerBeforeImages ", e.target.files[0]);
    setBeforeImagesUpload(e.target.files[0])
  }

  const fileHandlerAfterImages = (e) => {
    console.log("fileHandlerAfterImages ", e.target.files[0]);
    setAfterImagesUpload(e.target.files[0])
  }

  const fileUploadBeforeImages = () => {
    setErrors({ contractorNotesError: '', installerNotesError: '', beforeImagesError: '', afterImagesError: '' });

    if (beforeImagesUpload && beforeImagesUpload.size < 2000000) {
      const formData = new FormData();
      formData.append('file', beforeImagesUpload);
      fetch(
        'https://osuna.mkbedev.com/api/upload?jobId=' + `${jobOrderDetail.jobId}` + '&userId=' + `${loggedUserInfo.firstName}${loggedUserInfo.lastName} (Role:${loggedUserInfo.role})` + '&before=true',
        {
          method: 'POST',
          body: formData,
        }
      ).then((response) => response.json())
        .then((result) => {
          console.log('Success:', result);
          const clonedBeforeImages = [];
          result.map(item => {
            if (item.state == 'before') {
              clonedBeforeImages.push(item);
            }
          })
          setBeforeImages(clonedBeforeImages);
        }).catch((error) => {
          setErrors({ beforeImagesError: error })
          console.error('Error:', error);
        });
      setBeforeImagesUpload("");
    } else {
      setErrors({ beforeImagesError: "Please choose file and make sure file size should be less then 2MB" });
      console.log("Please choose file and make sure file size should be less then 2MB");
    }
  }

  const fileUploadAfterImages = () => {
    setErrors({ contractorNotesError: '', installerNotesError: '', beforeImagesError: '', afterImagesError: '' });

    if (afterImagesUpload && afterImagesUpload.size < 2000000) {
      const formData = new FormData();
      formData.append('file', afterImagesUpload);
      fetch(
        'https://osuna.mkbedev.com/api/upload?jobId=' + `${jobOrderDetail.jobId}` + '&userId=' + `${loggedUserInfo.firstName}${loggedUserInfo.lastName} (Role:${loggedUserInfo.role})` + '&before=true',
        {
          method: 'POST',
          body: formData,
        }
      ).then((response) => response.json())
        .then((result) => {
          console.log('Success:', result);
          const clonedAfterImages = [];
          result.map(item => {
            if (item.state == 'after') {
              clonedAfterImages.push(item);
            }
          })
          setAfterImages(clonedAfterImages);
        }).catch((error) => {
          setErrors({ afterImagesError: error });
          console.error('Error:', error);
        });
      setAfterImagesUpload("");
    } else {
      setErrors({ afterImagesError: "Please choose file and make sure file size should be less then 2MB" });
      console.log("Please choose file and make sure file size should be less then 2MB");
    }
  }

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  }

  const imgColumnDefs = [
    {
      headerName: "File Name",
      field: "fileName",
      suppressMenu: true,
      width: "auto",
      cellRenderer: "fileNameLinkCellRenderer",
    },
    {
      headerName: "uploaded By",
      field: "uploadedBy",
      suppressMenu: true,
      width: 300,
    },
    {
      headerName: "Timestamp",
      field: "uploadedDate",
      suppressMenu: true,
      width: 220,
    },
    {
      headerName: "Action",
      cellRenderer: "uploadedFileactionCellRenderer",
      cellRendererParams: { beforeImages: beforeImages, afterImages: afterImages },
      suppressMenu: true,
      width: 220,
    },
    {
      headerName: "id",
      field: "id",
      hide: true,
      suppressMenu: true,
      width: 0,
    },
  ];

  const columnDefs = [
    {
      headerName: "Note",
      field: "note",
      suppressMenu: true,
      width: 700,
    },
    {
      headerName: "Added By",
      field: "addedBy",
      suppressMenu: true,
      width: 300,
    },
    {
      headerName: "Timestamp",
      field: "timestamp",
      suppressMenu: true,
      width: 220,
    },
    {
      headerName: "Action",
      cellRenderer: "actionCellRenderer",
      cellRendererParams: { contractorNotes: contractorNotes, installerNotes: installerNotes },
      suppressMenu: true,
      width: 220,
    },
    {
      headerName: "id",
      field: "id",
      hide: true,
      suppressMenu: true,
      width: 0,
    },
  ];

  return (

    <div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12" style={{ width: '95%', marginTop: '10px' }}> </div>
          <div class="col-lg-12" style={{ width: '100%', paddingRight: '25px', paddingLeft: '25px' }}>
            <div id="mb-brd" class="col-lg-12" style={{ marginLeft: '-15px' }}>
              <div class="col-lg-3 pages-tabs">
                Job Additional Detail
                        </div>
              <div class="col-lg-9 ">

              </div>
            </div>
            <div class="col-lg-12 pages-body">
              <div class="block-header-summary-3" style={{ paddingTop: "10px!important", color: "#000!important" }}>Job Id: <span >{jobOrderDetail.jobId}</span><span></span></div>
              <div>
                <TextField
                  id="outlined-basic"
                  name="contractorNotes"
                  multiline
                  rows={1}
                  rowsMax={1}
                  value={currentContractorNote}
                  onChange={(event) => setCurrentContractorNote(event.target.value)}
                  label={`Contractor Notes`}
                  variant="outlined"
                  style={{
                    width: "80%",
                    height: "0px",
                    marginBottom: "70px",
                    marginRight: 10,
                    marginLeft: 15,
                  }}
                />
                <button class="blackbtn" type="button" onClick={onAddContractorNotes}>
                  <AddIcon />Contractor Notes
        </button>
              </div>
              {errors && errors.contractorNotesError && <span class="error-val-msg" style={{ paddingLeft: "15px" }}>{errors.contractorNotesError}</span>}
              <div class="col-lg-12 container-fluid">
                <div
                  className="ag-theme-alpine"
                  style={{ height: 200, position: "relative" }}
                >
                  <AgGridReact
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    frameworkComponents={frameworkComponents}
                    context={context}
                    rowData={contractorNotes}
                    animateRows={true}
                    fullWidth={true}
                    onGridReady={onGridReady}
                  ></AgGridReact>
                </div>
              </div>
              <hr />
              <br></br>

              <div>
                <TextField
                  multiline
                  rows={1}
                  rowsMax={1}
                  value={currentInstallerNote}
                  onChange={(event) => setCurrentInstallerNote(event.target.value)}
                  label={`Installer Notes`}
                  variant="outlined"
                  style={{
                    width: "80%",
                    height: "0px",
                    marginBottom: "70px",
                    marginRight: 10,
                    marginLeft: 15,
                  }}
                />
                <button class="blackbtn" type="button" onClick={onAddInstallerNotes}>
                  <AddIcon />Add Installer Notes
        </button>
              </div>
              {errors && errors.installerNotesError && <span class="error-val-msg" style={{ paddingLeft: "15px" }}>{errors.installerNotesError}</span>}
              <div class="col-lg-12 container-fluid">
                <div class="col-lg-12" className="ag-theme-alpine" style={{ height: 200, position: "relative" }} >
                  <AgGridReact
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    frameworkComponents={frameworkComponents}
                    rowData={installerNotes}
                    context={context}
                    animateRows={true}
                    onGridReady={onGridReady}
                  ></AgGridReact>
                </div>
              </div>
              <hr />
              <br></br>


              <div style={{ marginLeft: 15, marginBottom: 5, marginTop: 5, fontWeight: "bold" }} >Before Images
<input style={{ marginLeft: 10 }} type="file" name="file" onChange={fileHandlerBeforeImages} />
                <Button class="blackbtn" onClick={fileUploadBeforeImages}>
                  <PublishIcon />Upload File</Button>
              </div>
              {errors && errors.beforeImagesError && <span class="error-val-msg" style={{ paddingLeft: "15px" }}>{errors.beforeImagesError}</span>}
              <div class="col-lg-12 container-fluid">
                <div
                  className="ag-theme-alpine"
                  style={{ height: 200, position: "relative" }}
                >
                  <AgGridReact
                    columnDefs={imgColumnDefs}
                    defaultColDef={defaultColDef}
                    frameworkComponents={frameworkComponents}
                    rowData={beforeImages}
                    //rowData={images}
                    context={context}
                    animateRows={true}
                    onGridReady={onGridReady}
                  ></AgGridReact>
                </div>
              </div>

              <div style={{ marginLeft: 15, marginBottom: 5, marginTop: 5, fontWeight: "bold" }} >After Images
      <input style={{ marginLeft: 10 }} type="file" name="file" onChange={fileHandlerAfterImages} />
                <Button class="blackbtn" onClick={fileUploadAfterImages}>
                  <PublishIcon />Upload File
      </Button>
              </div>
              {errors && errors.afterImagesError && <span class="error-val-msg" style={{ paddingLeft: "15px" }}>{errors.afterImagesError}</span>}
              <div class="col-lg-12 container-fluid">
                <div
                  className="ag-theme-alpine"
                  style={{ height: 200, position: "relative" }}
                >
                  <AgGridReact
                    columnDefs={imgColumnDefs}
                    defaultColDef={defaultColDef}
                    frameworkComponents={frameworkComponents}
                    rowData={afterImages}
                    //rowData={images}
                    context={context}
                    animateRows={true}
                    onGridReady={onGridReady}
                  ></AgGridReact>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    jobOrderDetail: state.jobsDashboardReducer.jobOrderDetail || {},
    loggedUserInfo: getLoggedInUserInfo(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveAdditionalDetailAction: (payload) => dispatch(onSaveJobInfo(payload)),
    onDeleteUploadAction: (payload) => dispatch(onDeleteUpload(payload))
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(JobAdditionalDetail)
);

import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { styles } from "./styles";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Select from '@material-ui/core/Select';
import {
  onSaveJobInfo,
  onLoadContractors,
  onLoadInstallerListByContractor,
} from "../dashboard/ducks/actions";
import { Checkbox, MenuItem } from "@material-ui/core";
import { jobStatus } from "./job-status";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const ContratorInstaller = (props) => {
  const classes = useStyles();

  const {
    isEditMode,
    onSummaryEditClick,
    onSaveJobcontractorInstallerInfoAction,
    onStepperNextClick,
    onStepperBackClick,
    jobOrderDetail,
    contractorsList,
    fetchContractorListAction,
    installerListForContractor,
    onLoadInstallerListByContractorAction,
  } = props;
  const { register, handleSubmit, setValue, errors } = useForm({
    mode: "onChange",
  });

  const [jobSelectedInstallerList, setJobSelectedInstallerList] = useState([]);
  const [installerListOfContractor, setInstallerOfContractor] = useState();
  const [selectedContractor, setSelectedContractor] = useState('');
  const [contractors, setContractors] = useState(contractorsList);

  useEffect(() => {
    fetchContractorListAction();
    setInstallerOfContractor([]);
  }, []);

  useEffect(() => {
    setInstallerOfContractor(installerListForContractor);
  }, [installerListForContractor]);

  useEffect(() => {
    if (jobOrderDetail.jobId && jobOrderDetail.contractorInstallerInfo && jobOrderDetail.contractorInstallerInfo.contractorInfo) {
      if (jobOrderDetail.contractorInstallerInfo.selectedInstallerList) {
        const selInstallers = jobOrderDetail.contractorInstallerInfo.selectedInstallerList.map(item => item._id);
        setJobSelectedInstallerList(selInstallers);
      }

      const contractorId = jobOrderDetail.contractorInstallerInfo.contractorInfo._id;
      setValue("id", contractorId, true);
      if (contractorId) {
        onLoadInstallerListByContractorAction(contractorId);
      }
    }
  }, [jobOrderDetail]);

  useEffect(() => {
    if (contractorsList && contractorsList.length > 0) {
      var activeContractors = contractorsList.filter(opt => opt.active).map(c => ({ id: c.id, name: c.name }));
    }

    if (jobOrderDetail.jobId && jobOrderDetail.contractorInstallerInfo && jobOrderDetail.contractorInstallerInfo.contractorInfo
      && contractorsList && contractorsList.length > 0) {
      const contractorId = jobOrderDetail.contractorInstallerInfo.contractorInfo._id;
      const selContractor = contractorsList.find(
        (item) => item.id === contractorId
      );
      if (selContractor) {
        if (!selContractor.active) {
          activeContractors.push({ id: selContractor.id, name: `${selContractor.name} (Deleted)` });
        }
        setSelectedContractor(selContractor.id);
      }
    }

    setContractors(activeContractors);
  }, [jobOrderDetail, contractorsList]);

  const onSubmit = (data) => {
    if (jobOrderDetail.jobId) {
      onSaveJobcontractorInstallerInfoAction(getJobContractorInstallerDetails(data));
    }
    onStepperNextClick();
  };

  const handleBack = () => {
    onStepperBackClick();
  };

  const onChangeContractor = (e) => {
    setValue("id", e.target.value, true);
    const contractorId = e.target.value;
    if (contractorId) {
      onLoadInstallerListByContractorAction(contractorId);
      const selContractor = contractorsList.find(
        (item) => item.id === contractorId
      );
      if (selContractor) {
        setSelectedContractor(selContractor.id);
        setJobSelectedInstallerList([]);
      }
    } else {
      setSelectedContractor('');
      setJobSelectedInstallerList([]);
    }
  };

  const onClickFinish = (updatedData) => {
    if (jobOrderDetail.jobId) {
      onSaveJobcontractorInstallerInfoAction(getJobContractorInstallerDetails(updatedData));
    } else {
      return;
    }
    onSummaryEditClick(6, false);
  };

  const onInstallerChanged = (item, isChecked) => {
    if (!jobSelectedInstallerList) {
      setJobSelectedInstallerList([]);
    }
    const clonedSelectedInstallerList = jobSelectedInstallerList ? [...jobSelectedInstallerList] : [];
    const index = clonedSelectedInstallerList.indexOf(item.id);
    if (index === -1 && isChecked) {
      clonedSelectedInstallerList.push(item.id);
    } else {
      clonedSelectedInstallerList.splice(index, 1);
    }
    setJobSelectedInstallerList(clonedSelectedInstallerList);
  };

  const getJobContractorInstallerDetails = (data) => {
    const jobContractorInstallers = { jobId: jobOrderDetail.jobId, contractorInstallerInfo: { id: selectedContractor, ...data, selectedInstallerList: [...jobSelectedInstallerList] } };
    if (selectedContractor && jobOrderDetail.status === jobStatus.INITIATED) {
      jobContractorInstallers.status = jobStatus.IN_PROGRESS;
    }
    return jobContractorInstallers;
  }


  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>

        <div
          class="col-lg-12 container-center text-left container-stepper"
          style={{}}
        >
          <div class="row" style={{ paddingLeft: "22px" }}>
            <div class="col-lg-3">
              <div class="col-lg-12" style={{ padding: "10px 10px 10px 0px", color: "#000099", fontSize: "16px" }}>
                Contractor
              </div>
              <Select
                margin="dense"
                label="Contractor"
                // defaultValue={selectedContractor}
                value={selectedContractor}
                onChange={onChangeContractor}
                style={{ width: "200px" }}
              >
                <MenuItem key="" value="">-- Select --</MenuItem>
                {contractors && contractors.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div class="col-lg-9" style={{ padding: "0px" }}>
              <div class="col-lg-12" style={{ padding: "10px 10px 10px 0px", color: "#000099", fontSize: "16px" }}>
                Installer
              </div>
              <div class="row">

                {selectedContractor && installerListOfContractor &&
                  installerListOfContractor.filter(x => x.active || jobSelectedInstallerList.includes(x.id)).map((item) => (
                    <div class="col-lg-3" style={{ padding: "10px" }}>
                      <Checkbox
                        color="primary"
                        name={item.id}
                        key={item.id}
                        defaultChecked={jobSelectedInstallerList ? jobSelectedInstallerList.includes(item.id) : false}
                        onChange={(e) =>
                          onInstallerChanged(item, e.target.checked)
                        }
                      />
                      <span style={{ paddingRight: "5px" }}>
                        {item.active ? item.name : `${item.name} (Deleted)`}
                      </span>
                    </div>
                  ))}
              </div>

            </div>
          </div>

          <br></br>
          <br></br>
          <div class="row text-left" style={{ paddingLeft: "20px" }}>
            <div class="col-lg-12" id="note-margin">
              <TextField
                id="outlined-basic"
                name="contractorNotes"
                multiline
                inputRef={register({ required: false })}
                rows={3}
                rowsMax={3}
                defaultValue={
                  jobOrderDetail.contractorInstallerInfo
                    ? jobOrderDetail.contractorInstallerInfo.contratorNotes
                    : ""
                }
                label="Contractor"
                variant="outlined"
                style={{ width: "100%", height: "20px", marginBottom: "55px" }}
              />
            </div>
            <div class="col-lg-12" id="note-margin">
              <TextField
                id="outlined-basic"
                name="installerNotes"
                multiline
                inputRef={register({ required: false })}
                rows={3}
                rowsMax={3}
                defaultValue={
                  jobOrderDetail.contractorInstallerInfo
                    ? jobOrderDetail.contractorInstallerInfo.installerNotes
                    : ""
                }
                label="Installer Notes"
                variant="outlined"
                style={{ width: "100%", height: "20px", marginBottom: "55px" }}
              />
            </div>
          </div>
        </div>
        <div class="col-lg-12  text-left" style={{ marginTop: "10px" }}>
          <div style={{ textAlign: "right" }}>
            <Button onClick={handleBack}>Back</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {" "}
              Next
            </Button>
            {isEditMode && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(onClickFinish)}
              >
                {" "}
                Finish
              </Button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    jobOrderDetail: state.jobsDashboardReducer.jobOrderDetail || {},
    contractorsList: state.jobsDashboardReducer.contractorsList || [],
    installerListForContractor:
      state.jobsDashboardReducer.installerListForContractor || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveJobcontractorInstallerInfoAction: (payload) =>
      dispatch(onSaveJobInfo(payload)),
    fetchContractorListAction: (payload) =>
      dispatch(onLoadContractors(payload)),
    onLoadInstallerListByContractorAction: (payload) =>
      dispatch(onLoadInstallerListByContractor(payload)),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ContratorInstaller)
);

const AccountManagementCellRenderer = (props) => {
    const { currentUserId, data } = props;

    const handleSetupAccount = () => {
        const { api, context: { onSetupAccountClickHandler }, data } = props;
        onSetupAccountClickHandler(data);
    }

    const handleDeleteAccount = () => {
        const { api, context: { onDeleteAccountClickHandler }, data } = props;
        onDeleteAccountClickHandler(data);
    }

    return (<div>
        {data.role !== 'admin' && data.role !== 'wm' && !data.userId && <span onClick={handleSetupAccount} style={{ textDecoration: 'underline', cursor: 'pointer', color: '#3045ac' }}>Setup Account</span>}
        {data._id !== currentUserId && data.userId && <span onClick={handleDeleteAccount} style={{ textDecoration: 'underline', cursor: 'pointer', color: '#e53321' }}>Delete Account</span>}
    </div>);
}

export default AccountManagementCellRenderer;
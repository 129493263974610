import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { styles } from "./styles";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import ImageAspectRatioIcon from "@material-ui/icons/ImageAspectRatio";
import { onSaveJobInfo } from "../dashboard/ducks/actions";
import { typeOfMaterialList } from './type-of-material-info';


const transFormTOMaterialInfo = (typeOfMaterialList) => {
  let listMaterilsType = {};
  typeOfMaterialList.forEach((item) => {
    listMaterilsType[item.materialTypeId] = {};
    item.options.forEach((option) => {
      listMaterilsType[item.materialTypeId][option.key] = false;
    });
  });
  return listMaterilsType;
};



let materialInfo = transFormTOMaterialInfo([...typeOfMaterialList]);

const splitArray = (array, splitSize) => {
  const clonedArray = [...array];
  var splits = [];
  while (clonedArray.length) {
    splits.push(clonedArray.splice(0, splitSize));
  }
  return splits;
};

const materialTypeOption = ({
  options,
  heading,
  headerId,
  handleCheckboxChange,
  typeOfMaterialInfo,
}) => {
  return (
    <div>
      <FormLabel
        component="legend"
        style={{
          position: "relative",
          fontWeight: "bold",
          color: "#000000",
          fontSize: "18px",
          marginTop: "15px",
          marginBottom: "20px",
        }}
      >
        {heading}
      </FormLabel>
      <FormGroup aria-label="position" row style={{ position: "relative" }}>
        <table style={{ marginLeft: "30px" }}>
          <tbody>
            {splitArray(options, 6).map((itemArray, index) => (
              <tr key={`${[headerId]}.${index}`}>
                {itemArray.map((item) => {
                  const name = `${[headerId]}.${[item.key]}`;
                  return (
                    <td class="tdStylecss" key={name}>
                      <FormControlLabel
                        control={<Checkbox style={{ marginTop: "8px" }}
                          color="primary"
                          checked={typeOfMaterialInfo[headerId] ? typeOfMaterialInfo[headerId][item.key] : false}
                          onChange={(event) => handleCheckboxChange(event.target.checked, headerId, item.key)}
                          name={name}
                        />}
                        labelPlacement="end"
                        label={item.value}
                      />
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </FormGroup>
      <br></br>
      <div class="hr-height"></div>
    </div>
  );
};

const TypeOfMaterial = (props) => {
  const {
    isEditMode,
    onSummaryEditClick,
    onStepperNextClick,
    jobOrderDetail,
    onStepperBackClick, onSaveJobTypeOfMaterialInfoAction
  } = props;

  const [typeOfMaterialInfo, setTypeOfMaterialInfo] = useState({});

  useEffect(() => {
    if (jobOrderDetail.typeOfMaterial) {
      setTypeOfMaterialInfo(jobOrderDetail.typeOfMaterial);
    }
    else {
      setTypeOfMaterialInfo(transFormTOMaterialInfo([...typeOfMaterialList]));
    }

  }, [jobOrderDetail])

  const handleCheckboxChange = (isChecked, headerId, key) => {
    const matInfo = { ...typeOfMaterialInfo };

    if (!matInfo[headerId]) {
      matInfo[headerId] = {};
    }
    matInfo[headerId][key] = isChecked;
    setTypeOfMaterialInfo(matInfo);
  };

  const onClickNext = () => {
    if (jobOrderDetail.jobId) {
      onSaveJobTypeOfMaterialInfoAction({ jobId: jobOrderDetail.jobId, typeOfMaterial: { ...typeOfMaterialInfo } });
      onStepperNextClick();
    }
  };

  const handleBack = () => {
    onStepperBackClick();
  };

  const onClickFinish = () => {
    if (jobOrderDetail.jobId) {
      onSaveJobTypeOfMaterialInfoAction({ jobId: jobOrderDetail.jobId, typeOfMaterial: { ...typeOfMaterialInfo } });
      onSummaryEditClick(6, false);
    }
  }

  return (
    <div>
      <form>
        <div
          class="col-lg-12 container-center text-left container-stepper"
          style={{}}
        >
          <div class="col-lg-12" >
            <div class="col-lg-12 block-header" style={{ fontWeight: "bold" }}>
              Type Of Material
              <ImageAspectRatioIcon
                fontSize="small"
                style={{ marginLeft: "5px" }}
              />
            </div>
            <div class="row typeofmetcss">
              <div
                class="col-lg-12 d-inline typeofmaterial-block"
                style={{ position: "relative", marginLeft: "20px" }}
              >
                {typeOfMaterialList.map((item) => (
                  <React.Fragment key={item.materialTypeId}>
                    {materialTypeOption({
                      options: item.options,
                      heading: item.materialTypeDisplayName,
                      headerId: item.materialTypeId,
                      typeOfMaterialInfo,
                      handleCheckboxChange
                    })}
                  </React.Fragment>
                ))}

                <div class="col-lg-12" id="note-margin">
                  <TextField
                    id="outlined-basic"
                    name="notes"
                    multiline
                    rows={3}
                    rowsMax={3}
                    label="Notes"
                    variant="outlined"
                    style={{
                      width: "100%",
                      height: "20px",
                      marginBottom: "55px",
                    }}
                  />
                </div>
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
        </div>
      </form>
      <div style={{ textAlign: "right", marginTop: "10px" }}>
        {/* <Button variant="contained" color="primary" >Reset</Button> */}
        <Button onClick={handleBack}>Back</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onClickNext}
        >
          {" "}
          Next
        </Button>
        {isEditMode && <Button variant="contained" color="primary" onClick={onClickFinish} >{" "}Finish</Button>}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    jobOrderDetail: state.jobsDashboardReducer.jobOrderDetail || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveJobTypeOfMaterialInfoAction: (payload) => dispatch(onSaveJobInfo(payload))
  };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TypeOfMaterial));
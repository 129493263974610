import React, { useEffect, useState } from "react";
import JobStepper from "../job/job-stepper";
import { render } from "react-dom";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import Link from "@material-ui/core/Link";

import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import AgGridJobs from "./ag-grid-jobs";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import { connect } from "react-redux";
import { onLoadJobs } from "./ducks/actions";
import AddIcon from "@material-ui/icons/Add";
import ClearAllIcon from '@material-ui/icons/ClearAll';
import AuthenticationHandler from './../shared/authentication-handler';
import {getLoggedInUserInfo} from './../shared/get-logged-user-detail';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const JobsLayout = (props) => {
  const { loggedUserInfo, loadJobsAction } = props;

  useEffect(() => {
    if (loggedUserInfo.isAuthenticated) {
      loadJobs(loggedUserInfo._id);
    } 
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadJobs = (loggedId, archivesInclude) => {
    const loadJobsReqParams = {
      userId: loggedId
    };
    loadJobsAction(loadJobsReqParams);
  };

  return (
    <div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12" style={{ width: "95%", marginTop: "10px" }}>
            {" "}
          </div>
          <div
            class="col-lg-12"
            style={{ width: "100%", paddingRight: "25px", paddingLeft: "25px" }}
          >
            <div id="mb-brd" class="col-lg-12" style={{ marginLeft: "-15px" }}>
              <div class="col-lg-3 pages-tabs">Jobs</div>
              <div class="col-lg-9 "></div>
            </div>
            <div class="col-lg-12 pages-body">
              <AgGridJobs />
            </div>
          </div>
          <div class="col-lg-12">
            {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                            Open form dialog
      </Button> */}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
              maxWidth="lg"
              style={{ height: "700px" }}
            >
              <DialogTitle
                class="label-3"
                id="form-dialog-title"
                style={{ fontWeight: "bold" }}
              >
                Job Order
              </DialogTitle>
              <DialogContent></DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
    // <div>
    //     <JobStepper/>
    // </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUserInfo: getLoggedInUserInfo(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadJobsAction: (payload) => dispatch(onLoadJobs(payload))
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AuthenticationHandler(JobsLayout))
);

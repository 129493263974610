import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    ON_VIEW_CONTRACTOR_CLICK,
    ON_EDIT_CONTRACTOR_CLICK,
    ON_DELETE_CONTRACTOR_CLICK,
    ON_LIST_CONTRACTORS,
    ON_CONTRACTOR_CREATE,
    ON_CONTRACTOR_SAVE,
    SET_CONTRACTOR_VIEW,
    SET_CONTRACTOR_EDIT,
    SET_CONTRACTORS_LIST,
    ON_ERROR
} from './ducks/types';
import { fetchGet, fetchPost } from '../rest-api-client';
import configData from "./../config.json";

configData.SERVER_URL = process.env.REACT_APP_API_ENDPOINT;

/** View Contractor Button Click */
function* onViewContractorClick(action) {
    try {
        const { payload } = action;
        let contractor = yield call(fetchGet, `${configData.SERVER_URL}/contractor/get/${payload}`);

        if (!contractor.error) {
            yield put({ type: SET_CONTRACTOR_VIEW, payload: contractor });
        } else {
            yield put({ type: ON_ERROR, payload: "Failed to load contractor" });
        }
    } catch (e) {
    }
}

function* onViewContractorClickWatcher() {
    yield takeLatest(ON_VIEW_CONTRACTOR_CLICK, onViewContractorClick)
}

/** Edit Contractor Button Click */
function* onEditContractorClick(action) {
    try {
        const { payload } = action;
        let contractor = yield call(fetchGet, `${configData.SERVER_URL}/contractor/get/${payload}`);
        if (!contractor.error) {
            yield put({ type: SET_CONTRACTOR_EDIT, payload: contractor });
        } else {
            yield put({ type: ON_ERROR, payload: "Failed to load contractor" });
        }
    } catch (e) {
    }
}

function* onEditContractorClickWatcher() {
    yield takeLatest(ON_EDIT_CONTRACTOR_CLICK, onEditContractorClick)
}

/** Delete Contractor Button Click */
function* onDeleteContractorClick(action) {
    try {
        const { payload } = action;
        let result = yield call(fetchPost, `${configData.SERVER_URL}/contractor/delete/${payload}`);
        if (result.error) {
            yield put({ type: ON_ERROR, payload: "Failed to delete contractor" });
        }
    } catch (e) {
    }
}

function* onDeleteContractorClickWatcher() {
    yield takeLatest(ON_DELETE_CONTRACTOR_CLICK, onDeleteContractorClick)
}

/** Load Contractors */
function* onListContractors() {
    try {
        let contractors = yield call(fetchGet, `${configData.SERVER_URL}/contractor/list`);
        yield put({ type: SET_CONTRACTORS_LIST, payload: contractors && contractors.length > 0 ? contractors : [] });
    } catch (e) {
    }
}

function* onListContractorsWatcher() {
    yield takeEvery(ON_LIST_CONTRACTORS, onListContractors)
}

/** Create Contractor */
function* onContractorCreate(action) {
    try {
        const { payload } = action;
        let contractor = {
            firstName: payload.firstName,
            lastName: payload.lastName,
            email: payload.email,
            phone: payload.phone,
            companyName: payload.companyName,
            addressLine1: payload.addressLine1,
            addressLine2: payload.addressLine2,
            city: payload.city,
            state: payload.state,
            zipCode: payload.zipCode
        };
        let result = yield call(fetchPost, `${configData.SERVER_URL}/contractor/create`, contractor);

        if (result !== "success") {
            yield put({ type: ON_ERROR, payload: "Failed to create contractor" });
        }
    } catch (e) {
    }
}

function* onContractorCreateWatcher() {
    yield takeLatest(ON_CONTRACTOR_CREATE, onContractorCreate)
}

/** Save Contractor */
function* onContractorSave(action) {
    try {
        const { payload } = action;
        let contractor = {
            _id: payload._id,
            firstName: payload.firstName,
            lastName: payload.lastName,
            email: payload.email,
            phone: payload.phone,
            companyName: payload.companyName,
            addressLine1: payload.addressLine1,
            addressLine2: payload.addressLine2,
            city: payload.city,
            state: payload.state,
            zipCode: payload.zipCode
        };
        let result = yield call(fetchPost, `${configData.SERVER_URL}/contractor/update`, contractor);

        if (result !== "success") {
            yield put({ type: ON_ERROR, payload: "Failed to create contractor" });
        }
    } catch (e) {
        console.log("error on contractor update");
    }
}

function* onContractorSaveWatcher() {
    yield takeLatest(ON_CONTRACTOR_SAVE, onContractorSave)
}

/** Export Watchers */
const contractorManagementWatchers = [
    onViewContractorClickWatcher,
    onEditContractorClickWatcher,
    onDeleteContractorClickWatcher,
    onListContractorsWatcher,
    onContractorCreateWatcher,
    onContractorSaveWatcher
];

export default contractorManagementWatchers;
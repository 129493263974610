import {
    ON_NEW_INSTALLER_CLICK,
    ON_VIEW_INSTALLER_CLICK,
    ON_EDIT_INSTALLER_CLICK,
    ON_DELETE_INSTALLER_CLICK,
    ON_LIST_INSTALLERS,
    ON_LOAD_CONTRACTORS_DROPDOWN_VALUES,
    ON_INSTALLER_CREATE,
    ON_INSTALLER_SAVE
  } from './types';
  
  export const onNewInstallerClick = () => ({
    type: ON_NEW_INSTALLER_CLICK
  });
  
  export const onViewInstallerClick = (payload) => ({
    type: ON_VIEW_INSTALLER_CLICK, payload
  });
  
  export const onEditInstallerClick = (payload) => ({
    type: ON_EDIT_INSTALLER_CLICK, payload
  });
  
  export const onDeleteInstallerClick = (payload) => ({
    type: ON_DELETE_INSTALLER_CLICK, payload
  });
  
  export const onListInstallers = () => ({
    type: ON_LIST_INSTALLERS
  });
  
  export const onLoadContractorsAction = () => ({
    type: ON_LOAD_CONTRACTORS_DROPDOWN_VALUES
  });

  export const onInstallerCreate = (payload) => ({
    type: ON_INSTALLER_CREATE, payload
  });
  
  export const onInstallerSave = (payload) => ({
    type: ON_INSTALLER_SAVE, payload
  });
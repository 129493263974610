import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../user-form/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import WorkIcon from '@material-ui/icons/Work';
import PeopleIcon from '@material-ui/icons/People';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import BuildIcon from '@material-ui/icons/Build';
import { withRouter } from "react-router-dom";
import ManageArchiveJobs from "./manage-archive-jobs";
import ManageArchiveContractors from "./manage-archive-contractors";
import ManageArchiveInstallers from "./manage-archive-installers";
import ManageArchiveCustomers from "./manage-archive-customers";
import {getLoggedInUserInfo} from './../shared/get-logged-user-detail';

/** Manage Archive Layout */
const ManageArchiveLayout = (props) => {

    /** State & Event Handler from Properties */
    const { loggedUserInfo } = props;
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        if (loggedUserInfo.role !== "admin") {
            props.history.push("/login");
        }
    }, [loggedUserInfo]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    /** Renderer */
    return (
        <div className="user-management-form">
            <Paper square>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label="icon label tabs example">
                    <Tab icon={<WorkIcon />} label="Jobs" />
                    <Tab icon={<PeopleIcon />} label="Customers" />
                    <Tab icon={<AccountBoxIcon />} label="Contractors" />
                    <Tab icon={<BuildIcon />} label="Installers" />
                </Tabs>
            </Paper>
            {tabValue === 0 && <div role="tabpanel">
                <ManageArchiveJobs />
            </div>}
            {tabValue === 1 && <div role="tabpanel">
                <ManageArchiveCustomers />
            </div>}
            {tabValue === 2 && <div role="tabpanel">
                <ManageArchiveContractors />
            </div>}
            {tabValue === 3 && <div role="tabpanel">
                <ManageArchiveInstallers />
            </div>}
        </div>
    );
}

/** Get State from Reducers & map to Manage Archive Layout */
const mapStateToProps = state => {
    return {
        loggedUserInfo: getLoggedInUserInfo(state),
    }
}

/** Manage Archive Layout */
export default withStyles(styles)(connect(mapStateToProps)(withRouter(ManageArchiveLayout)));
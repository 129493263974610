export const ON_LOAD_JOBS = "ON_LOAD_JOBS";
export const SET_JOBS_LIST = "SET_JOBS_LIST";
export const ON_SAVE_JOB_INFO = "ON_SAVE_JOB_INFO";
export const ON_SAVE_JOB_FAILURE = "ON_SAVE_JOB_FAILURE";
export const ON_LOAD_JOB_COUNTRY_STATES = "ON_LOAD_JOB_COUNTRY_STATES";
export const SET_JOB_COUNTRY_STATES_LIST = "SET_JOB_COUNTRY_STATES_LIST";
export const ON_LOAD_CONTRACTORS_LIST = "ON_LOAD_CONTRACTORS_LIST";
export const SET_CONTRACTORS_LIST = "SET_CONTRACTORS_LIST";
export const ON_LOAD_JOB_TYPES_OF_MATERIAL = "ON_LOAD_JOB_TYPES_OF_MATERIAL";
export const SET_JOB_TYPES_OF_MATERIAL = "SET_JOB_TYPES_OF_MATERIAL";
export const SET_COMPLETE_JOB_DETAILS = "SET_COMPLETE_JOB_DETAILS";
export const ON_SELECT_JOB = "ON_SELECT_JOB";
export const ON_CREATE_NEW_JOB = "ON_CREATE_NEW_JOB";
export const ON_DELETE_JOB_CLICK = "ON_DELETE_JOB_CLICK";
export const ON_ARCHIVE_JOB_CLICK = "ON_ARCHIVE_JOB_CLICK";
export const ON_LOAD_ACCOUNTS = "ON_LOAD_ACCOUNTS";
export const SET_ACCOUNTS_LIST = "SET_ACCOUNTS_LIST";
export const ON_LOAD_INSTALLER_LIST_BY_CONTRACTOR="ON_LOAD_INSTALLER_LIST_BY_CONTRACTOR";
export const SET_INSTALLER_LIST_FOR_CONTRACTOR = "SET_INSTALLER_LIST_FOR_CONTRACTOR";
export const ON_HOLD_STATUS_CHANGE_JOB='ON_HOLD_STATUS_CHANGE_JOB';
export const ON_DELETE_UPLOAD = "ON_DELETE_UPLOAD";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ArchiveIcon from "@material-ui/icons/Archive";
import { green } from "@material-ui/core/colors";
import { withRouter } from "react-router-dom";
import AuthenticationHandler from "./../shared/authentication-handler";
import {getLoggedInUserInfo} from './../shared/get-logged-user-detail';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  green: {
    color: "#fff",
    backgroundColor: green[500],
    width: "40px",
  },
});

const DashbordHomeLayout = (props) => {
  const classes = useStyles();

  const { loggedUserInfo } = props;

  useEffect(() => {
    if (loggedUserInfo && loggedUserInfo.role !== "admin") {
      props.history.push("/login");
    }
  }, []);

  const handleClickJobs = () => {
    props.history.push("/jobs");
  };
  const handleClickCustomers = () => {
    props.history.push("/customer");
  };
  const handleClickContractors = () => {
    props.history.push("/contractor");
  };

  const handleClickInstallers = () => {
    props.history.push("/installer");
  };

  const handleClickManageArchives = () => {
    props.history.push("/manage-archives");
  };
  if (!loggedUserInfo) {
    return null;
  }

  return (
    <div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12" style={{ width: "95%", marginTop: "10px" }}>
            {" "}
          </div>
          <div
            class="col-lg-12"
            style={{ width: "100%", paddingRight: "25px", paddingLeft: "25px" }}
          >
            <div class="col-lg-12" style={{ marginLeft: "-15px" }}>
              <div
                class="col-lg-12 text-center"
                id="wto-id"
                style={{ fontSize: "18px" }}
              >
                <br />
                <h4>Welcome to Osuna Flooring Dashboard</h4> <br /> <br />
              </div>
            </div>
            <div class="col-lg-12 pages-body-2">
              <div class="row" style={{ paddingTop: "10px" }}>
                <div class="col-lg-2 text-center"></div>
                <div class="col-lg-8 text-center">
                  <div class="row">
                    <div class="col-lg-4 text-center">
                      <Card className={classes.root} onClick={handleClickJobs}>
                        <CardActionArea>
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="h2"
                              color="#161616"
                            >
                              Manage Jobs
                            </Typography>
                            <BusinessCenterIcon
                              fontSize="large"
                              style={{ color: "#467FAA" }}
                            />
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </div>

                    {loggedUserInfo.role === "admin" && (
                      <div class="col-lg-4 text-center">
                        <Card
                          className={classes.root}
                          onClick={handleClickCustomers}
                        >
                          <CardActionArea>
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="h2"
                              >
                                Manage Customers
                              </Typography>
                              <PeopleOutlineIcon
                                fontSize="large"
                                style={{ color: "#467FAA" }}
                              />
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </div>
                    )}

                    {loggedUserInfo.role === "admin" && (
                      <div class="col-lg-4 text-center">
                        <Card
                          className={classes.root}
                          onClick={handleClickContractors}
                        >
                          <CardActionArea>
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="h2"
                              >
                                Manage Contractors
                              </Typography>
                              <PeopleAltIcon
                                fontSize="large"
                                style={{ color: "#467FAA" }}
                              />
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </div>
                    )}
                  </div>
                  <div id="br-hide">
                    <br />
                    <br />
                    <br />
                  </div>
                  <div class="row">
                    {loggedUserInfo.role === "admin" && (
                      <div class="col-lg-4 text-center">
                        <Card
                          className={classes.root}
                          onClick={handleClickInstallers}
                        >
                          <CardActionArea>
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="h2"
                              >
                                Manage Installers
                              </Typography>
                              <DateRangeIcon
                                fontSize="large"
                                style={{ color: "#467FAA" }}
                              />
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </div>
                    )}

                    {loggedUserInfo.role === "admin" && (
                      <div class="col-lg-4 text-center">
                        <Card className={classes.root}>
                          <CardActionArea>
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="h2"
                              >
                                Manage Reports
                              </Typography>
                              <AssessmentIcon
                                fontSize="large"
                                style={{ color: "#467FAA" }}
                              />
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </div>
                    )}

                    {loggedUserInfo.role === "admin" && (
                      <div class="col-lg-4 text-center">
                        <Card
                          className={classes.root}
                          onClick={handleClickManageArchives}
                        >
                          <CardActionArea>
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="h2"
                              >
                                Manage Archives
                              </Typography>
                              <ArchiveIcon
                                fontSize="large"
                                style={{ color: "#467FAA" }}
                              />
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </div>
                    )}
                  </div>
                </div>
                <div class="col-lg-2 text-center"></div>
              </div>
            </div>
          </div>
          <div class="col-lg-12"> </div>
        </div>
      </div>
    </div>
  );
};

/** Get State from Reducers & map to Dashboard */
const mapStateToProps = (state) => {
  return {
    loggedUserInfo: getLoggedInUserInfo(state),
  };
};

export default connect(mapStateToProps)(
  withRouter(AuthenticationHandler(DashbordHomeLayout))
);

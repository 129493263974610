import { useState } from "react";

const ArchiveCellRenderer = (props) => {
    const { canUnArchive, data } = props;
    const [isCompleted, isIsCompleted] = useState(data && data.status && data.status === 'Completed');

    const handleUnArchive = () => {
        const { api, context: { onUnArchiveClickHandler }, data } = props;
        onUnArchiveClickHandler(data);
    }

    return (<div>
        {canUnArchive && !isCompleted && <span onClick={handleUnArchive} style={{ textDecoration: 'underline', cursor: 'pointer', color: '#e53321' }}>Re-Activate</span>}
    </div>);
}

export default ArchiveCellRenderer;
export const fetchPost = async (url, data) => {
    try {
        const config = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': '*',
                'Access-Control-Allow-Headers': '*'
            },
            body: JSON.stringify(data)
        }
        const response = await fetch(url, config);
        if (response.ok) {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
               return response.json();
            }
            return response.text();
        } else {
            return { error: "Error occured while processing request" };
        }
    } catch (error) {
        return { error: "Error occured while processing request" };
    }
}



export const fetchGet = async (url, data) => {
    try {
        const config = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': '*',
                'Access-Control-Allow-Headers': '*'
            }
        }
        const urlQueryParams = getUrlQueryParams(url, data);
        const response = await fetch(urlQueryParams, config);
        if (response.ok) {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.json();
             }
             return response.text();
        } else {
            return { error: "Error occured while processing request" };
        }
    } catch (error) {
        return { error: "Error occured while processing request" };
    }
}

function getUrlQueryParams(url, data) {
    if (!data) {
        return url;
    }
    var queryStringInfo = "";
    for (var key in data) {
        if (queryStringInfo != "") {
            queryStringInfo += "&";
        }
        queryStringInfo += key + "=" + encodeURIComponent(data[key]);
    }
    return url + "?" + queryStringInfo;
}
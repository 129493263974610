export const typeOfMaterialList = [
    {
      materialTypeDisplayName: "Carpet",
      materialTypeId: "carpet",
      options: [
        { key: "futureForm", value: "Future From" },
        { key: "trafficMaster", value: "Traffic Master" },
        { key: "quietWalk", value: "Quiet Walk" },
      ],
    },
    {
      materialTypeDisplayName: "Sheet Vinyl",
      materialTypeId: "sheetVinyl",
      options: [
        { key: "roto", value: "Roto" },
        { key: "fullSpreadorPerimeter", value: "Full Spread or Perimeter?" },
        { key: "sheetVinylInlaid", value: "Sheet Vinyl -Inlaid" },
      ],
    },
    {
      materialTypeDisplayName: "Vinyl Composition Tile",
      materialTypeId: "vinylCompositionTile",
      options: [
        { key: "VCT", value: "VCT" },
        { key: "luxuryVinylTile", value: "Luxury Vinyl Tile" },
        { key: "Plank", value: "Plank" },
        { key: "Straight Lay", value: "Straight Lay" },
        { key: "Diagonal", value: "Diagonal" },
        { key: "Special", value: "Special" },
      ],
    },
    {
      materialTypeDisplayName: "Laminate",
      materialTypeId: "laminate",
      options: [
        { key: "plankDimensions", value: "Plank Dimension" },
        { key: "tileStoneDimensions", value: "Tile / StoneDimensions" },
      ],
    },
    {
      materialTypeDisplayName: "Hard wood",
      materialTypeId: "hardwood",
      options: [
        { key: "species", value: "Species" },
        { key: "plankDimensions", value: "Plank Dimensions" },
        { key: "engSolid", value: "Eng / Solid" },
        {
          key: "prefinishedFinishonFloor",
          value: "prefinished / Finish on Floor",
        },
        { key: "floating", value: "Floating" },
        { key: "gluedown", value: "Gluedown" },
        { key: "nailIn", value: "Nail-In" },
        { key: "gluelessGlue", value: "Glueless / Glue" },
        { key: "attachedBacking", value: "AttachedBacking" },
        { key: "sqFtperCtn", value: "Sq. Ft. per Ctn" },
        { key: "sqFtperCtn", value: "Plank Direction" },        
        { key: "straightLay", value: "Straight Lay" },
        { key: "diagonal", value: "Diagonal" },
        { key: "special", value: "Special" },
      ],
    },
    {
      materialTypeDisplayName: "Tile",
      materialTypeId: "tile",
      options: [
        { key: "ceramic", value: "Ceramic" },
        { key: "porcelain", value: "PlaPorcelain" },
        { key: "stone", value: "Stone" },
        { key: "tileDimensions", value: "Tile Dimensions" },
        { key: "spacing", value: "Spacing" },       
        { key: "groutColor", value: "Grout Color" },
        { key: "nailIn", value: "Nail-In" },
        { key: "straightLay", value: "Straight Lay" },
        { key: "diagonal", value: "Diagonal" },
        {
          key: "diagSpecial",
          value: "special (inserts, walls, counters, etc)",
        },
      ],
    },
  ];
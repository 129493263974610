import React from "react";
import { Redirect } from "react-router";

const AuthenticationHandler = WrappedComponent => {
  class AuthenticationHandlerHOC extends React.Component {
    render() {
         
        const loggedUserInfoJSon = localStorage.getItem("loggedinUserInfo");
        const loggedUserInfo=JSON.parse(loggedUserInfoJSon);
        if (loggedUserInfo && loggedUserInfo.isAuthenticated) {
        
          return <WrappedComponent {...this.props} />;
        } else {
          return <Redirect to={{ pathname: "/login" }} />;
        }
    
    }
  }
  return AuthenticationHandlerHOC;
};


export default AuthenticationHandler;

import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../user-form/styles';
import { AgGridReact } from 'ag-grid-react';
import JobIdLinkCellRenderer from "../grid-cell-renderers/job-id-link-cell-renderer";
import ArchiveCellRenderer from '../grid-cell-renderers/archive-cell-renderer';
import { withRouter } from "react-router-dom";
import ConfirmationDialog from "../shared/confirmation-dialog";
import { onLoadArchiveJobs, onActivateJob } from './ducks/actions';
import { onSelectJob } from "../dashboard/ducks/actions";

/** Manage Archive Jobs */
const ManageArchiveJobs = (props) => {

    /** State & Event Handler from Properties */
    const { jobs, errorMessage, loadArchiveJobsAction, activateJobAction, onJobSelectAction } = props;
    const [dialogConfirmationObj, setDialogConfirmationObj] = useState({ 'open': false });

    useEffect(() => {
        loadArchiveJobsAction();
    }, []);

    /** Custom Cell Renderer for Action Column */
    const frameworkComponents = {
        actionCellRenderer: ArchiveCellRenderer,
        jobIdLinkCellRenderer: JobIdLinkCellRenderer,
    }

    /** Is Hold column formatter */
    const isHoldFormatter = (params) => {
        return `${params.value === true ? 'Yes' : ''}`;
    }

    /** Grid Configuration */
    const gridOptions = {
        columnDefs: [
            { headerName: "Job ID", field: "jobId", filter: "agTextColumnFilter", cellRenderer: "jobIdLinkCellRenderer" },
            { headerName: "Status", field: "status", filter: "agTextColumnFilter" },
            { headerName: "Is Hold?", field: "hold", filter: "agTextColumnFilter", valueFormatter: isHoldFormatter },
            { headerName: "Contractor", field: "contractorName", filter: "agTextColumnFilter" },
            { headerName: "Installer", field: "installerName", filter: 'agTextColumnFilter' },
            { headerName: "Customer", field: "customerName", filter: "agTextColumnFilter" },
            { headerName: "Action", width: 200, field: "action", filter: false, cellRenderer: 'actionCellRenderer', cellRendererParams: { canUnArchive: true } }
        ],
        defaultColDef: {
            editable: true,
            sortable: true,
            floatingFilter: true,
            resizable: true,
            flex: 1
        },
        sortingOrder: ['desc', 'asc', null],
        pageSize: 10,
        pagination: true,
        animateRows: true,
        domLayout: 'autoHeight'
    };

    const processUnArchive = (confirm, params) => {
        setDialogConfirmationObj({ 'open': false });
        if (confirm) {
            activateJobAction(params.jobId);
        }
    }

    const onUnArchiveClickHandler = (data) => {
        var dialogProp = {
            'open': true,
            'title': 'Re-activate Job Record',
            'content': 'Are you sure you want to re-activate this job?',
            'params': { jobId: data.jobId },
            'handleClose': processUnArchive
        };

        setDialogConfirmationObj(dialogProp);
    }

    const onJobSelectClickHandler = (data) => {
        onJobSelectAction(data);
        props.history.push("/job-detailed-view");
    };

    /** Set Grid Context for Custom Handlers */
    const gridContext = {
        onUnArchiveClickHandler: onUnArchiveClickHandler,
        onJobSelectClickHandler: onJobSelectClickHandler
    };

    /** Trigger when grid ready */
    const onGridReady = (params) => {
        params.api.sizeColumnsToFit();
        params.api.resetRowHeights();
    }

    /** Renderer */
    return (
        <div className="manage-archive-grid">
            <div className="ag-theme-alpine">
                <AgGridReact
                    domLayout={gridOptions.domLayout}
                    pagination={gridOptions.pagination}
                    paginationPageSize={gridOptions.pageSize}
                    animateRows={gridOptions.animateRows}
                    defaultColDef={gridOptions.defaultColDef}
                    columnDefs={gridOptions.columnDefs}
                    sortingOrder={gridOptions.sortingOrder}
                    frameworkComponents={frameworkComponents}
                    context={gridContext}
                    rowData={jobs}
                    onGridReady={onGridReady}>
                </AgGridReact>
            </div>
            <ConfirmationDialog
                id="confirmation-dialog"
                keepMounted
                open={dialogConfirmationObj.open}
                onClose={dialogConfirmationObj.handleClose}
                title={dialogConfirmationObj.title}
                content={dialogConfirmationObj.content}
                params={dialogConfirmationObj.params}>
            </ConfirmationDialog>
        </div>
    );
}

/** Get State from Reducers & map to Archive Jobs */
const mapStateToProps = state => {
    return {
        jobs: state.manageArchiveReducer.jobs || [],
        errorMessage: state.manageArchiveReducer.errorMessage || ''
    }
}

/** Attach actions to Archive Jobs */
const mapDispatchToProps = dispatch => {
    return {
        loadArchiveJobsAction: () => dispatch(onLoadArchiveJobs()),
        activateJobAction: (payload) => dispatch(onActivateJob(payload)),
        onJobSelectAction: (payload) => dispatch(onSelectJob(payload))
    }
}

/** Manage Archive Jobs */
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withRouter(ManageArchiveJobs)));
import {
  ON_LOAD_JOBS, ON_SELECT_JOB, ON_CREATE_NEW_JOB,
  ON_LOAD_JOB_COUNTRY_STATES, ON_LOAD_JOB_TYPES_OF_MATERIAL, ON_SAVE_JOB_INFO, ON_LOAD_CONTRACTORS_LIST,
  ON_DELETE_JOB_CLICK, ON_ARCHIVE_JOB_CLICK, ON_LOAD_ACCOUNTS, ON_LOAD_INSTALLER_LIST_BY_CONTRACTOR, ON_HOLD_STATUS_CHANGE_JOB, ON_DELETE_UPLOAD
} from './types';

export const onLoadJobs = (payload) => ({
    type: ON_LOAD_JOBS, payload
  });

  export const onSelectJob = (payload) => ({
    type: ON_SELECT_JOB, payload
  });

export const onLoadJobsCountryStates = (payload) => ({
    type: ON_LOAD_JOB_COUNTRY_STATES, payload
});

export const onLoadJobsTypesOfMaterial = (payload) => ({
    type: ON_LOAD_JOB_TYPES_OF_MATERIAL, payload
});


export const onSaveJobInfo= (payload)=>({
  type: ON_SAVE_JOB_INFO, payload
});

export const onDeleteUpload= (payload)=>({
  type: ON_DELETE_UPLOAD, payload
});

export const onLoadContractors = (payload) => ({
  type: ON_LOAD_CONTRACTORS_LIST, payload
});

export const onLoadInstallerListByContractor = (payload) => ({
  type: ON_LOAD_INSTALLER_LIST_BY_CONTRACTOR, payload
});

export const onCreateNewJob=()=>({
  type: ON_CREATE_NEW_JOB
});

export const onDeleteJobClick = (payload) => ({
  type: ON_DELETE_JOB_CLICK, payload
});

export const onArchiveJobClick = (payload) => ({
  type: ON_ARCHIVE_JOB_CLICK, payload
}); 

export const onLoadAccounts = (payload) => ({
  type: ON_LOAD_ACCOUNTS, payload
}); 

export const onHoldStatusChangeJob=(payload)=>({
  type: ON_HOLD_STATUS_CHANGE_JOB, payload
}); 
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import { AgGridReact } from 'ag-grid-react';
import { styles } from './styles';
import ViewEditDeleteRenderer from "../grid-cell-renderers/view-edit-delete-renderer";
import {
    onListCustomers,
    onNewCustomerClick,
    onViewCustomerClick,
    onEditCustomerClick,
    onDeleteCustomerClick
} from './ducks/actions';
import AddIcon from '@material-ui/icons/Add';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import GetAppIcon from '@material-ui/icons/GetApp';
import AuthenticationHandler from './../shared/authentication-handler';
import {getLoggedInUserInfo} from './../shared/get-logged-user-detail';

/** Customer Dashboard */
const CustomerDashboard = (props) => {

    /** State & Event Handler from Properties */
    const { loggedUserInfo,
        customers,
        listCustomersAction,
        newCustomerClickAction,
        viewCustomerClickAction,
        editCustomerClickAction,
        deleteCustomerClickAction } = props;
    const [gridApi, setGridApi] = useState(null);

    /** Inital Load Action */
    useEffect(() => {
        if (loggedUserInfo.role === "admin") {
            listCustomersAction();
        }        
    }, []);

    /** Address column formatter */
    const addressFormatter = (params) => {
        if (params && params.data) {
            const address = params.data.address;
            if (address) {
                return `${address.addressLine1}, ${address.city}, ${address.zipCode}`;
            } else  {
                return '';
            }
        }
    }

    /** Grid Configuration */
    const gridOptions = {
        columnDefs: [
            { headerName: "Company Name", field: "companyName", filter: "agTextColumnFilter" },
            { headerName: "First Name", field: "firstName", filter: "agTextColumnFilter" },
            { headerName: "Last Name", field: "lastName", filter: "agTextColumnFilter" },
            { headerName: "Email", field: "email", filter: 'agTextColumnFilter' },
            { headerName: "Phone", field: "phone", filter: "agTextColumnFilter" },
            { headerName: "Address", field: "address", filter: 'agTextColumnFilter', valueGetter: addressFormatter },
            { headerName: "Action", width: 200, field: "action", filter: false, cellRenderer: 'actionCellRenderer' }
        ],
        defaultColDef: {
            editable: true,
            sortable: true,
            floatingFilter: true,
            resizable: true,
            flex: 1
        },
        sortingOrder: ['desc', 'asc', null],
        pageSize: 10,
        pagination: true,
        enableFilter: true,
        animateRows: true,
        domLayout: 'autoHeight'
    };

    /** Custom Cell Renderer for Action Column */
    const frameworkComponents = {
        actionCellRenderer: ViewEditDeleteRenderer
    }

    /** New Button Click Handler */
    const onNewButtonClickHandler = () => {
        newCustomerClickAction();
        props.history.push("/customer-form");
    }

    /** Clear Filters Click Handler */
    const clearFiltersClickHandler = () => {
        if (gridApi) {
            gridApi.setFilterModel(null);
        }
    }

    /** Export Data */
    const processCells = (cell) => {
        if (cell.column.colId === "address") {
            return addressFormatter(cell);
        } else {
            return cell.value;
        }
    };

    const exportDataClickHandler = () => {
        if (gridApi) {
            gridApi.exportDataAsCsv({
                fileName: 'Customers',
                columnKeys: ['companyName', 'firstName', 'lastName', 'email', 'phone', 'address'],
                processCellCallback: processCells
            });
        }
    }    

    /** View Button Click Handler */
    const onViewButtonClickHandler = (data) => {
        viewCustomerClickAction(data._id);
        props.history.push("/customer-form");
    }

    /** Edit Button Click Handler */
    const onEditButtonClickHandler = (data) => {
        editCustomerClickAction(data._id);
        props.history.push("/customer-form");
    }

    /** Delete Button Click Handler */
    const onDeleteButtonClickHandler = (data) => {
        let confirm = window.confirm(`Are you sure you want to delete this customer?`);
        if (confirm) {
            deleteCustomerClickAction(data._id);
            listCustomersAction();
        }
    }

    /** Set Grid Context for Custom Handlers */
    const context = { onViewClick: onViewButtonClickHandler, onEditClick: onEditButtonClickHandler, onDeleteClick: onDeleteButtonClickHandler };

    /** Trigger when grid ready */
    const onGridReady = (params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
        params.api.resetRowHeights();
    }

    /** Renderer */
    return (
        <div className="customer-dashboard">
            <div className="row action-row">
                <div className="col">
                    <button className="blackbtn logon-btn-space float-right" onClick={exportDataClickHandler}>Export Data<GetAppIcon /></button>
                    <button className="blackbtn logon-btn-space float-right" onClick={clearFiltersClickHandler}>Clear Filters<ClearAllIcon /></button>
                    <button className="blackbtn logon-btn-space float-right" onClick={onNewButtonClickHandler}><AddIcon />Add Customer</button>
                </div>
            </div>
            <div className="ag-theme-alpine">
                <AgGridReact
                    domLayout={gridOptions.domLayout}
                    pagination={gridOptions.pagination}
                    paginationPageSize={gridOptions.pageSize}
                    enableFilter={gridOptions.enableFilter}
                    animateRows={gridOptions.animateRows}
                    defaultColDef={gridOptions.defaultColDef}
                    columnDefs={gridOptions.columnDefs}
                    sortingOrder={gridOptions.sortingOrder}
                    frameworkComponents={frameworkComponents}
                    context={context}
                    rowData={customers}
                    onGridReady={onGridReady}>
                </AgGridReact>
            </div>
        </div>
    )
}

/** Get State from Reducers & map to Customer Dashboard */
const mapStateToProps = state => {
    return {
        loggedUserInfo: getLoggedInUserInfo(state),
        customers: state.customerReducer.customers || []
    }
}

/** Attach actions to Customer Dashboard */
const mapDispatchToProps = dispatch => {
    return {
        listCustomersAction: () => dispatch(onListCustomers()),
        newCustomerClickAction: () => dispatch(onNewCustomerClick()),
        viewCustomerClickAction: (payload) => dispatch(onViewCustomerClick(payload)),
        editCustomerClickAction: (payload) => dispatch(onEditCustomerClick(payload)),
        deleteCustomerClickAction: (payload) => dispatch(onDeleteCustomerClick(payload))
    }
}

/** Customer Dashboard */
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthenticationHandler(CustomerDashboard))));
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { useForm, Controller } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import { fetchGet } from './../rest-api-client';
import configData from "./../config.json";

const AccountSetupDialog = (props) => {
    const { onClose, open, title, role, accountId } = props;
    const [userIdAvailable, setUserIdAvailable] = useState(true);
    const { control, register, errors, handleSubmit, getValues, setValue } = useForm({
        mode: "onChange",
        defaultValues: {
            userId: "",
            password: "",
            confirmPassword: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: ""
        }
    });

    useEffect(() => {
        if (accountId) {
            setTimeout(() => setValue("_id", accountId));
        }
    }, [accountId, setValue]);

    const handleCancel = () => {
        onClose({ proceed: false });
    };

    const handleOk = (data) => {
        setUserIdAvailable(true);
        fetchGet(`${configData.SERVER_URL}/user/checkuserid/${data.userId}`)
            .then((result) => {
                if (!result.error) {
                    if (!result.isAvailable) {
                        onClose({
                            proceed: true,
                            _id: data._id,
                            userId: data.userId,
                            password: data.password,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            email: data.email,
                            phone: data.phone
                        });
                    } else {
                        setUserIdAvailable(false);
                    }
                }
            });
    };



    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            fullWidth
            maxWidth="md"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title" className="account-setup-title">{title}</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(handleOk)}>
                    <input type="hidden" ref={register} name="_id" />
                    <div class="row">
                        <div class="col">
                            <Controller as={<TextField autoFocus margin="dense" label="User ID" type="string" fullWidth />} control={control}
                                rules={{
                                    required: true,
                                    minLength: 6,
                                    maxLength: 20
                                }}
                                name="userId" />
                            {errors.userId && errors.userId.type === "required" && <span class="validation-error">*User ID is required</span>}
                            {errors.userId && errors.userId.type === "minLength" && <span class="validation-error">*User ID length should be minimum 6 characters</span>}
                            {errors.userId && errors.userId.type === "maxLength" && <span class="validation-error">*User ID length should be maximum 20 characters</span>}
                            {userIdAvailable === false && <span class="validation-error">*User ID not available</span>}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <Controller as={<TextField margin="dense" label="Password" type="password" fullWidth />} control={control}
                                rules={{
                                    required: true,
                                    minLength: 8,
                                    validate: () => { return !getValues("password") || !getValues("confirmPassword") || getValues("password") === getValues("confirmPassword"); }
                                }}
                                name="password" />
                            {errors.password && errors.password.type === "required" && <span class="validation-error">*Password cannot be empty</span>}
                            {errors.password && errors.password.type === "minLength" && <span class="validation-error">*Password length should be minimum 8 characters</span>}
                            {errors.password && errors.password.type === "validate" && <span class="validation-error">*Password / Confirm Password not matching</span>}
                        </div>
                        <div class="col">
                            <Controller as={<TextField margin="dense" label="Confirm Password" type="password" fullWidth />} control={control}
                                rules={{
                                    required: true,
                                    minLength: 8,
                                    validate: () => { return !getValues("password") || !getValues("confirmPassword") || getValues("password") === getValues("confirmPassword"); }
                                }}
                                name="confirmPassword" />
                            {errors.confirmPassword && errors.confirmPassword.type === "required" && <span class="validation-error">*Confirm password cannot be empty</span>}
                            {errors.confirmPassword && errors.confirmPassword.type === "minLength" && <span class="validation-error">*Confirm Password length should be minimum 8 characters</span>}
                            {errors.confirmPassword && errors.confirmPassword.type === "validate" && <span class="validation-error">*Password / Confirm Password not matching</span>}
                        </div>
                    </div>
                    {(role === "admin" || role === "wm") &&
                        <div class="row">
                            <div class="col d-inline">
                                <Controller as={<TextField margin="dense" label="First Name" type="string" fullWidth />} control={control} rules={{ required: true }} name="firstName" />
                                {errors.firstName && errors.firstName.type === "required" && <span class="validation-error">*First Name is required</span>}
                            </div>
                            <div class="col d-inline">
                                <Controller as={<TextField margin="dense" label="Last Name" type="string" fullWidth />} control={control} rules={{ required: true }} name="lastName" />
                                {errors.lastName && errors.lastName.type === "required" && <span class="validation-error">*Last Name is required</span>}
                            </div>
                        </div>
                    }
                    {(role === "admin" || role === "wm") &&
                        <div class="row">
                            <div class="col d-inline">
                                <Controller as={<TextField margin="dense" label="Email" type="email" fullWidth />} control={control}
                                    rules={{
                                        required: true,
                                        validate: () => { return !getValues("email") || new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i).test(getValues("email")) }
                                    }}
                                    name="email" />
                                {errors.email && errors.email.type === "required" && <span class="validation-error">*Email is required</span>}
                                {errors.email && errors.email.type === "validate" && <span class="validation-error">*Please enter valid email address</span>}
                            </div>
                            <div class="col d-inline">
                                <Controller as={<TextField margin="dense" label="Phone" type="tel" fullWidth />} control={control} rules={{ required: true }} name="phone" />
                                {errors.phone && errors.phone.type === "required" && <span class="validation-error">*Phone Number is required</span>}
                            </div>
                        </div>
                    }
                    <div class="row action-row">
                        <div class="col">
                            <Button variant="contained" color="primary" type="reset" onClick={handleCancel}>Cancel</Button>
                            <Button variant="contained" color="primary" type="submit">Setup</Button>
                        </div>
                    </div>
                </form>
            </DialogContent>
            {/* <DialogActions>
                <Button onClick={handleCancel} variant="contained" color="primary">Cancel</Button>
                <Button onClick={handleOk} variant="contained" color="primary" autoFocus>Confirm</Button>
            </DialogActions> */}
        </Dialog>
    );
}

export default AccountSetupDialog;
import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';

const ConfirmationDialog = (props) => {
    const { onClose, open, title, content, params } = props;

    const handleCancel = () => {
        onClose(false, params);
    };

    const handleOk = () => {
        onClose(true, params);
    };

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} variant="contained" color="primary">Cancel</Button>
                <Button onClick={handleOk} variant="contained" color="primary" autoFocus>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationDialog;
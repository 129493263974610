import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import Link from '@material-ui/core/Link';
import { Button } from "@material-ui/core";

const FooterPage = (props) => {
  const { classes: { footerpaper } } = props;
  return (
 
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12" id="footer-mb" className={footerpaper}>
          <div class="col-lg-12">
            <Link
              class="footerlinks"
              component="button"
              variant="body2"
              onClick={() => {
                console.info("I'm a button.");
              }}
            >
              About
            </Link>
            |
            <Link
              class="footerlinks"
              component="button"
              variant="body2"
              onClick={() => {
                console.info("I'm a button.");
              }}
            >
              Contact
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Button
              class="sociallinks-t"
              component="button"
              variant="text"
              onClick={() => {
                console.info("I'm a button.");
              }}
            ></Button>
            <Button
              class="sociallinks-i"
              component="button"
              variant="text"
              onClick={() => {
                console.info("I'm a button.");
              }}
            ></Button>
          </div>
          <div class="col-lg-12 copyrightcl">
            Privacy Policy · Terms of Service · All trademarks © their
            respective owners © 2021 Osuna Flooring, LLC.
          </div>
        </div>
      </div>
    </div>
  );

}

export default withStyles(styles)(FooterPage);
import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { styles}  from './styles';

function EnquireResponse(props) {

    const onClickHomePage =() =>{
        alert("go to home page screen");
        props.history.push("/");
    }

    return (
        <div>
          <h4>Thank you for being awesome!
              We have recieve your message  and would like to  thank you  for writing to us. If your inquire is urgent, please use telephone number list  below to  talk to one of our staff members. Otherwise, we will replay by email as soon as possible.
          </h4>
          <br />

            Looking for another info<input type="submit" value='click here' onClick={onClickHomePage}/><br/>
        </div>
    );
  }
  export default withStyles(styles)(EnquireResponse);
import {
  SET_JOBS_LIST,  ON_SAVE_JOB_FAILURE, SET_JOB_TYPES_OF_MATERIAL, ON_CREATE_NEW_JOB,
  ON_SELECT_JOB, SET_JOB_COUNTRY_STATES_LIST, SET_COMPLETE_JOB_DETAILS, SET_CONTRACTORS_LIST,
  SET_ACCOUNTS_LIST,SET_INSTALLER_LIST_FOR_CONTRACTOR
} from "./types";

const initialState = {
  jobMaterialPlacementInfo: {
    rooms: [{ roomName:'', areaDimension:'',areaLength:'',roomNotes:'' }],
  },
  accounts: []
};

const jobsDashboardReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_JOBS_LIST: {
      return {
        ...state,
        jobsList: payload,
      };
    }
    case SET_JOB_COUNTRY_STATES_LIST: {
      return {
        ...state,
        countryList: payload,
      };
    }
    case SET_JOB_TYPES_OF_MATERIAL: {
      return {
        ...state,
        typesOfMaterialList: payload,
      };
    } 
    case ON_SELECT_JOB: 
    case SET_COMPLETE_JOB_DETAILS: {
      return {
        ...state,
        jobOrderDetail: payload,
      };
    }

    case ON_SAVE_JOB_FAILURE: {
      return {
        ...state,
        jobFailureInfo: payload,
      };
    }
    case ON_CREATE_NEW_JOB:{
      return {
        ...state,
        jobOrderDetail: {},
      };
    }
    case SET_CONTRACTORS_LIST: {
      return {
        ...state,
        contractorsList: payload,
      };
    }
    case SET_ACCOUNTS_LIST: {
      return {
        ...state,
        accounts: payload,
      };
    }   
    case SET_INSTALLER_LIST_FOR_CONTRACTOR:{
      return {
        ...state,
        installerListForContractor: payload,
      };
    } 
    default: {
      return state;
    }
  }
};

export default jobsDashboardReducer;

import React, { useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import { styles}  from './styles';
import { useForm } from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Hidden } from "@material-ui/core";
import { TextareaAutosize } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));



const  EnquireForm=(props)=> {

    const { register, handleSubmit, errors} = useForm({
      mode: "onChange"
    });

    const [showSuccess, setShowSuccess] = useState(false);

  

    const onSubmit = data => {
     alert("register details:"+JSON.stringify(data));
     alert("register details:"+data.name + data.company + data.phone + data.email + data.question);
     // service to back end 
     setShowSuccess(true);
    
    };

    const classes = useStyles();



return (
    <div style = {{overflowY:"hidden"}}>   
    <div style = {{overflowX:"hidden"}}>
      <div class="row">
        {showSuccess && 
        <Alert severity="success" style={{
          width: '100%', textAlign: 'center'
        }}    ><AlertTitle style={{
          textAlign: 'center' 
        }} hidden >Success</AlertTitle><strong>Thank you for being awesome!</strong><br></br>
        <strong>We have recieve your message  and would like to  thank you  for writing to us. If your inquire is urgent, please use telephone number list  below to  talk to one of our staff members. Otherwise, we will replay by email as soon as possible.</strong>
        </Alert>
}
      </div>
      <div class="row" style = {{overflowX:"hidden"}}>
      <div class="col-lg-12" style={{ width: '95%', marginTop: '20px',overflowX:'hidden' }}>
      <h4 class="lable-1">Inquire Now</h4>
        <h4 class="lable-2"> Thank you for choosing Osuna Flooring. Please complete the fields below and submit. We'll respond as soon as possible.</h4>
        </div> 
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}  style={{width:'100%',overflowX:'hidden' }}>
       
          <div class="col-lg-12" style={{ width: '100%', marginTop: '10px',overflowX:'hidden',height:'410px' }}>
            <div class="col-lg-12" style={{ marginTop:'5px' }}>
              <TextField id="outlined-basic" name="name" inputRef={register({ required: true })} label="Name" variant="outlined" style = {{width: '500px',height:'20px',marginBottom:'55px'}}/>
            {/* {errors.name && errors.name.type === "required" && <span class="valid-error">Name is required</span>} */}
            </div>
            <div class="col-lg-12"><TextField id="outlined-basic" name="company" inputRef={register({ required: true })} label="Company" variant="outlined" style = {{width: '500px',height:'20px',marginBottom:'55px'}} /></div>
            <div class="col-lg-12"><TextField id="outlined-basic" name="phone" inputRef={register({ required: true })} label="Phone" variant="outlined" style = {{width: '500px',height:'20px',marginBottom:'55px'}} /></div>
            <div class="col-lg-12"><TextField id="outlined-basic" name="email" inputRef={register({ required: true })} label="Email" variant="outlined" style = {{width: '500px',height:'20px',marginBottom:'55px'}} /></div>
            <div class="col-lg-12"><TextField id="outlined-basic" name="question" multiline rows={3}  rowsMax={3} inputRef={register({ required: true })} label="Question" variant="outlined" style = {{width: '500px',height:'20px',marginBottom:'55px'}} /></div>           
           </div>
         
           
          <div class="col-lg-12" style={{ width: '100%'}}>
            <Link class="blackbtn logon-btn-space" component="button" variant="body2" >Submit</Link>
            </div>
          
       </form>
      </div>    
    </div>
  
    </div>
  
  );
}
export default withStyles(styles)(EnquireForm);
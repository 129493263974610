import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../user-form/styles';
import { AgGridReact } from 'ag-grid-react';

import ArchiveCellRenderer from '../grid-cell-renderers/archive-cell-renderer';
import { withRouter } from "react-router-dom";
import ConfirmationDialog from "../shared/confirmation-dialog";
import { onLoadArchiveCustomers, onActivateCustomer } from './ducks/actions';


/** Manage Archive Customers */
const ManageArchiveCustomers = (props) => {

    /** State & Event Handler from Properties */
    const { customers, errorMessage, loadArchiveCustomersAction, activateCustomerAction } = props;
    const [dialogConfirmationObj, setDialogConfirmationObj] =  useState({ 'open': false });

    useEffect(() => {
        loadArchiveCustomersAction();
    }, []);

    /** Custom Cell Renderer for Action Column */
    const frameworkComponents = {
        actionCellRenderer: ArchiveCellRenderer
    }

    /** Grid Configuration */
    const gridOptions = {
        columnDefs: [
            { headerName: "Company Name", field: "companyName", filter: "agTextColumnFilter" },
            { headerName: "First Name", field: "firstName", filter: "agTextColumnFilter" },
            { headerName: "Last Name", field: "lastName", filter: "agTextColumnFilter" },
            { headerName: "Email", field: "email", filter: 'agTextColumnFilter' },
            { headerName: "Phone", field: "phone", filter: "agTextColumnFilter" },
            { headerName: "Action", width: 200, field: "action", filter: false, cellRenderer: 'actionCellRenderer', cellRendererParams: { canUnArchive: true } }
        ],
        defaultColDef: {
            editable: true,
            sortable: true,
            floatingFilter: true,
            resizable: true,
            flex: 1
        },
        sortingOrder: ['desc', 'asc', null],
        pageSize: 10,
        pagination: true,
        animateRows: true,
        domLayout: 'autoHeight'
    };

    const processUnArchive = (confirm, params) => {
        setDialogConfirmationObj({ 'open': false });
        if (confirm) {
           activateCustomerAction(params.id);
        }
    }

    const onUnArchiveClickHandler = (data) => {
        var dialogProp = {
            'open': true,
            'title': 'Re-activate Customer Record',
            'content': 'Are you sure you want to re-activate this customer?',
            'params': {id: data._id},
            'handleClose': processUnArchive
        };

        setDialogConfirmationObj(dialogProp);
    }

    /** Set Grid Context for Custom Handlers */
    const gridContext = {
        onUnArchiveClickHandler: onUnArchiveClickHandler,
    };

    /** Trigger when grid ready */
    const onGridReady = (params) => {
        params.api.sizeColumnsToFit();
        params.api.resetRowHeights();
    }

    /** Renderer */
    return (
        <div className="manage-archive-grid">
            <div className="ag-theme-alpine">
                <AgGridReact
                    domLayout={gridOptions.domLayout}
                    pagination={gridOptions.pagination}
                    paginationPageSize={gridOptions.pageSize}
                    animateRows={gridOptions.animateRows}
                    defaultColDef={gridOptions.defaultColDef}
                    columnDefs={gridOptions.columnDefs}
                    sortingOrder={gridOptions.sortingOrder}
                    frameworkComponents={frameworkComponents}
                    context={gridContext}
                    rowData={customers}
                    onGridReady={onGridReady}>
                </AgGridReact>
            </div>
            <ConfirmationDialog
                id="confirmation-dialog"
                keepMounted
                open={dialogConfirmationObj.open}
                onClose={dialogConfirmationObj.handleClose}
                title={dialogConfirmationObj.title}
                content={dialogConfirmationObj.content}
                params={dialogConfirmationObj.params}>
            </ConfirmationDialog>
        </div>
    );
}

/** Get State from Reducers & map to Archive Customers */
const mapStateToProps = state => {
    return {
        customers: state.manageArchiveReducer.customers || [],
        errorMessage: state.manageArchiveReducer.errorMessage || ''
    }
}

/** Attach actions to Archive Customers */
const mapDispatchToProps = dispatch => {
    return {
        loadArchiveCustomersAction: () => dispatch(onLoadArchiveCustomers()),
        activateCustomerAction: (payload) => dispatch(onActivateCustomer(payload)),
    }
}

/** Manage Archive Customers */
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withRouter(ManageArchiveCustomers)));
import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { styles}  from './styles';
import { Alert, AlertTitle } from '@material-ui/lab';

function Logout(props) {

    const onClickLoginPage =() =>{
        console.log("go to login screen");
        props.history.push("/login");
    }

    return (
      <div>
        <div class="container">
          <div class="row">
        <div class="col-lg-12">         
        
            <Alert severity="success" style={{
        width:'100%',textAlign:'center' }}    ><AlertTitle style={{
          textAlign:'center' }} >Success</AlertTitle><strong>You have been successfully logged out</strong>
            </Alert>
                
        </div> </div>      
        </div>
          <br /><br />
            <input type="submit" class="blackbtn" value='Sign in' onClick={onClickLoginPage}/><br/>
        </div>
    );
  }
  export default withStyles(styles)(Logout);
import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import classNames from "classnames";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import PersonIcon from "@material-ui/icons/Person";
import Input from "@material-ui/core/Input";
import FilledInput from "@material-ui/core/FilledInput";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import { Controller, useForm } from "react-hook-form";
import { onSubmitLogin } from "./ducks/actions";
import { connect } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";
import {getLoggedInUserInfo} from './../shared/get-logged-user-detail';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
}));

const LoginPage = (props) => {
  const classes = useStyles();
  const [showPassword, togglePassword] = React.useState();

  const {
    loggedUserInfo,
    onSubmitLoginAction,
    loginFailureMessage,
    classes: { button, divbackground, signinblockWidth },
  } = props;

  const handleClickShowPassword = () => {
    togglePassword(!showPassword);
  };

  const { register, handleSubmit, control, errors } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (loggedUserInfo.isAuthenticated) {
      if (loggedUserInfo.role.toLowerCase() === "admin") {
        props.history.push("/dashboard");
      } else {
        //redirect here
         props.history.push("/jobs");
      }
    }
  }, [loggedUserInfo]);

  const onSubmit = (data) => {
    //call API service to validate credentials
    onSubmitLoginAction(data);
  };

  const onForgetUserPass = () => {
    console.log("go to forgot username/password screen");
    props.history.push("/forgot");
  };

    return (


        <div class="container login-container">
            <div class="row">
                <div class="col-md-12 login-form-1">
                    <h3><span class="s-title1">Osuna</span><span class="s-title2">Sign in</span></h3>
                    <br></br>
                    <div>
                        {
                            loginFailureMessage && <Alert severity="error" style={{
                                width: '100%', textAlign: 'center'
                            }}    ><span>{loginFailureMessage}</span>
                            </Alert>
                        }
                        <FormControl className={classNames(classes.margin)} variant="outlined">
                            <div class="form-group">
                                <InputLabel htmlFor="outlined-adornment-username">User Name</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-username"
                                    type='text'
                                    name="userId"
                                    inputRef={register({ required: true })}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton tabIndex={-1}
                                                edge="end"
                                            >
                                                <PersonIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={70}
                                ></OutlinedInput><br />
                                {errors.userId && errors.userId.type === "required" && <span class="valid-error">Username is required</span>}
                            </div>
                        </FormControl>
                        <br></br>
                        <FormControl className={classNames(classes.margin)} variant="outlined">
                            <div class="form-group">
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    name="password"
                                    inputRef={register({ required: true })}
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={70}
                                /><br />
                                {errors.password && errors.password.type === "required" && <span class="valid-error">Password is required</span>}
                            </div>
                        </FormControl>
                        <div class="form-group">
                            <Button class="blackbtn logon-btn-space" component="button" variant="contained" onClick={handleSubmit(onSubmit)} >Login</Button>
                        </div>
                        <div class="form-group">
                            <div class="col-lg-12" class="remember-padding-left">                                
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel value="rememberaccount" control={<Checkbox color="primary" />}  label="Remember account" labelPlacement="end" className={classes.textField} />
                                    </FormGroup>
                                </div>
                            <div class="col-lg-12">  <Link class="" component="a" variant="body2" onClick={onForgetUserPass} style={{marginTop:'5px'}}>Forgot Your Password?</Link> </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>







    );
};

//connect
const mapStateToProps = (state) => {
  return {
    loggedUserInfo: getLoggedInUserInfo(state),
    loginFailureMessage: state.userFormReducer.loginFailureMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitLoginAction: (payload) => dispatch(onSubmitLogin(payload)),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(LoginPage)
);

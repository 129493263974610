import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { createAdminAction, deleteAdminAction, createWMAction, deleteWMAction, setupAccountAction, removeAccountAction } from './ducks/actions';
import { AgGridReact } from 'ag-grid-react';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SecurityIcon from '@material-ui/icons/Security';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import BuildIcon from '@material-ui/icons/Build';
import AccountManagementCellRenderer from './../grid-cell-renderers/account-management-cell-renderer';
import AddIcon from '@material-ui/icons/Add';
import AccountSetupDialog from './account-setup-dialog';
import { withRouter } from "react-router-dom";
import ConfirmationDialog from "./../shared/confirmation-dialog";
import WorkIcon from '@material-ui/icons/Work';
import {getLoggedInUserInfo} from './../shared/get-logged-user-detail';

/** User Management Form */
const UserManagementForm = (props) => {

    /** State & Event Handler from Properties */
    const { loggedUserInfo, accounts, errorMessage, createAdminAction, deleteAdminAction, createWMAction, deleteWMAction, setupAccountAction, removeAccountAction } = props;
    const [userType, setUserType] = React.useState('contractor');
    const adminAccounts = accounts.filter(x => x.role === "admin");
    const contractorAccounts = accounts.filter(x => x.role === "contractor");
    const installerAccounts = accounts.filter(x => x.role === "installer");
    const wmAccounts = accounts.filter(x => x.role === "wm");
    const [dialogObj, setDialogObj] = useState({ 'open': false });
    const [dialogConfirmationObj, setDialogConfirmationObj] = useState({ 'open': false });

    const { control, register, errors, handleSubmit, setValue, getValues } = useForm({
        mode: "onChange",
        defaultValues: {
            userId: "",
            password: "",
            confirmPassword: ""
        }
    });

    const handleChange = (event) => {
        setUserType(event.target.value);
    };

    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    /** Custom Cell Renderer for Action Column */
    const frameworkComponents = {
        actionCellRenderer: AccountManagementCellRenderer
    }

    /** Admin Grid Configuration */
    const gridOptionsAdmin = {
        columnDefs: [
            { headerName: "First Name", field: "firstName", filter: "agTextColumnFilter" },
            { headerName: "Last Name", field: "lastName", filter: "agTextColumnFilter" },
            { headerName: "Email", field: "email", filter: 'agTextColumnFilter' },
            { headerName: "Phone", field: "phone", filter: "agTextColumnFilter" },
            { headerName: "User ID", field: "userId", filter: "agTextColumnFilter" },
            { headerName: "Action", width: 200, field: "action", filter: false, cellRenderer: 'actionCellRenderer', cellRendererParams: { currentUserId: loggedUserInfo._id } }
        ],
        defaultColDef: {
            editable: true,
            sortable: true,
            resizable: true,
            flex: 1
        },
        sortingOrder: ['desc', 'asc', null],
        pageSize: 10,
        pagination: true,
        animateRows: true,
        domLayout: 'autoHeight'
    };

    const processAdminAccountSetup = (data) => {
        setDialogObj({ 'open': false });
        if (data && data.proceed) {
            var account = {
                userId: data.userId,
                password: data.password,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phone: data.phone
            };
            createAdminAction(account);
        }
    }

    const onAdminSetupClickHandler = (data) => {
        var dialogProp = {
            'open': true,
            'title': 'Setup Admin Account',
            'id': data._id,
            'role': 'admin',
            'handleClose': processAdminAccountSetup
        };

        setDialogObj(dialogProp);
    }

    const deleteAdminAccountHandler = (confirm, params) => {
        setDialogConfirmationObj({ 'open': false });
        if (confirm) {
            deleteAdminAction(params.id);
        }
    }

    const onAdminDeleteClickHandler = (data) => {
        var dialogProp = {
            'open': true,
            'title': 'Delete Admin Account',
            'content': 'Are you sure you want to remove this account?',
            'params': { id: data._id },
            'handleClose': deleteAdminAccountHandler
        };

        setDialogConfirmationObj(dialogProp);
    }

    /** Set Grid Context for Custom Handlers */
    const adminContext = {
        onSetupAccountClickHandler: onAdminSetupClickHandler,
        onDeleteAccountClickHandler: onAdminDeleteClickHandler
    };

    /** Contractor Grid Configuration */
    const gridOptionsContractor = {
        columnDefs: [
            { headerName: "First Name", field: "firstName", filter: "agTextColumnFilter" },
            { headerName: "Last Name", field: "lastName", filter: "agTextColumnFilter" },
            { headerName: "Email", field: "email", filter: 'agTextColumnFilter' },
            { headerName: "Phone", field: "phone", filter: "agTextColumnFilter" },
            { headerName: "Company Name", field: "companyName", filter: "agTextColumnFilter" },
            { headerName: "User ID", field: "userId", filter: "agTextColumnFilter" },
            { headerName: "Action", width: 200, field: "action", filter: false, cellRenderer: 'actionCellRenderer', cellRendererParams: { currentUserId: loggedUserInfo._id } }
        ],
        defaultColDef: {
            editable: true,
            sortable: true,
            resizable: true,
            flex: 1
        },
        sortingOrder: ['desc', 'asc', null],
        pageSize: 10,
        pagination: true,
        animateRows: true,
        domLayout: 'autoHeight'
    };

    const processContractorAccountSetup = (data) => {
        setDialogObj({ 'open': false });
        if (data && data.proceed) {
            var account = {
                _id: data._id,
                userId: data.userId,
                password: data.password
            };
            setupAccountAction(account);
        }
    }

    const onContractorSetupClickHandler = (data) => {
        var dialogProp = {
            'open': true,
            'title': 'Setup Contractor Account',
            'id': data._id,
            'role': 'contractor',
            'handleClose': processContractorAccountSetup
        };

        setDialogObj(dialogProp);
    }

    const deleteContractorAccountHandler = (confirm, params) => {
        setDialogConfirmationObj({ 'open': false });
        if (confirm) {
            removeAccountAction(params.id);
        }
    }

    const onContractorDeleteClickHandler = (data) => {
        var dialogProp = {
            'open': true,
            'title': 'Delete Contractor Account',
            'content': 'Are you sure you want to remove this account?',
            'params': { id: data._id },
            'handleClose': deleteContractorAccountHandler
        };

        setDialogConfirmationObj(dialogProp);
    }

    /** Set Grid Context for Custom Handlers */
    const contractorContext = {
        onSetupAccountClickHandler: onContractorSetupClickHandler,
        onDeleteAccountClickHandler: onContractorDeleteClickHandler
    };

    /** Installer Grid Configuration */
    const gridOptionsInstaller = {
        columnDefs: [
            { headerName: "First Name", field: "firstName", filter: "agTextColumnFilter" },
            { headerName: "Last Name", field: "lastName", filter: "agTextColumnFilter" },
            { headerName: "Email", field: "email", filter: 'agTextColumnFilter' },
            { headerName: "Phone", field: "phone", filter: "agTextColumnFilter" },
            { headerName: "Contractor Name", field: "contractorName", filter: "agTextColumnFilter" },
            { headerName: "User ID", field: "userId", filter: "agTextColumnFilter" },
            { headerName: "Action", width: 200, field: "action", filter: false, cellRenderer: 'actionCellRenderer', cellRendererParams: { currentUserId: loggedUserInfo._id } }
        ],
        defaultColDef: {
            editable: true,
            sortable: true,
            resizable: true,
            flex: 1
        },
        sortingOrder: ['desc', 'asc', null],
        pageSize: 10,
        pagination: true,
        animateRows: true,
        domLayout: 'autoHeight'
    };

    const processInstallerAccountSetup = (data) => {
        setDialogObj({ 'open': false });
        if (data && data.proceed) {
            var account = {
                _id: data._id,
                userId: data.userId,
                password: data.password
            };
            setupAccountAction(account);
        }
    }

    const onInstallerSetupClickHandler = (data) => {
        var dialogProp = {
            'open': true,
            'title': 'Setup Installer Account',
            'id': data._id,
            'role': 'installer',
            'handleClose': processInstallerAccountSetup
        };

        setDialogObj(dialogProp);
    }

    const deleteInstallerAccountHandler = (confirm, params) => {
        setDialogConfirmationObj({ 'open': false });
        if (confirm) {
            removeAccountAction(params.id);
        }
    }

    const onInstallerDeleteClickHandler = (data) => {
        var dialogProp = {
            'open': true,
            'title': 'Delete Installer Account',
            'content': 'Are you sure you want to remove this account?',
            'params': { id: data._id },
            'handleClose': deleteInstallerAccountHandler
        };

        setDialogConfirmationObj(dialogProp);
    }

    /** Set Grid Context for Custom Handlers */
    const installerContext = {
        onSetupAccountClickHandler: onInstallerSetupClickHandler,
        onDeleteAccountClickHandler: onInstallerDeleteClickHandler
    };

    /** Warehouse Manager Grid Configuration */
    const gridOptionsWM = {
        columnDefs: [
            { headerName: "First Name", field: "firstName", filter: "agTextColumnFilter" },
            { headerName: "Last Name", field: "lastName", filter: "agTextColumnFilter" },
            { headerName: "Email", field: "email", filter: 'agTextColumnFilter' },
            { headerName: "Phone", field: "phone", filter: "agTextColumnFilter" },
            { headerName: "User ID", field: "userId", filter: "agTextColumnFilter" },
            { headerName: "Action", width: 200, field: "action", filter: false, cellRenderer: 'actionCellRenderer', cellRendererParams: { currentUserId: loggedUserInfo._id } }
        ],
        defaultColDef: {
            editable: true,
            sortable: true,
            resizable: true,
            flex: 1
        },
        sortingOrder: ['desc', 'asc', null],
        pageSize: 10,
        pagination: true,
        animateRows: true,
        domLayout: 'autoHeight'
    };

    const processWMAccountSetup = (data) => {
        setDialogObj({ 'open': false });
        if (data && data.proceed) {
            var account = {
                userId: data.userId,
                password: data.password,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phone: data.phone
            };
            createWMAction(account);
        }
    }

    const onWMSetupClickHandler = (data) => {
        var dialogProp = {
            'open': true,
            'title': 'Setup Warehouse Manager Account',
            'id': data._id,
            'role': 'wm',
            'handleClose': processWMAccountSetup
        };

        setDialogObj(dialogProp);
    }

    const deleteWMAccountHandler = (confirm, params) => {
        setDialogConfirmationObj({ 'open': false });
        if (confirm) {
            deleteWMAction(params.id);
        }
    }

    const onWMDeleteClickHandler = (data) => {
        var dialogProp = {
            'open': true,
            'title': 'Delete Warehouse Manager Account',
            'content': 'Are you sure you want to remove this account?',
            'params': { id: data._id },
            'handleClose': deleteWMAccountHandler
        };

        setDialogConfirmationObj(dialogProp);
    }

    /** Set Grid Context for Custom Handlers */
    const wmContext = {
        onSetupAccountClickHandler: onWMSetupClickHandler,
        onDeleteAccountClickHandler: onWMDeleteClickHandler
    };

    /** Trigger when grid ready */
    const onGridReady = (params) => {
        params.api.sizeColumnsToFit();
        params.api.resetRowHeights();
    }

    /** Renderer */
    return (
        <div className="user-management-form">
            <Paper square>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label="icon label tabs example">
                    <Tab icon={<SecurityIcon />} label="Admin Accounts" />
                    <Tab icon={<AccountBoxIcon />} label="Contractor Accounts" />
                    <Tab icon={<BuildIcon />} label="Installer Accounts" />
                    <Tab icon={<WorkIcon />} label="Warehouse Manager Accounts" />
                </Tabs>
            </Paper>
            {tabValue === 0 && <div role="tabpanel">
                <div className="row action-row">
                    <div className="col">
                        <button className="blackbtn logon-btn-space float-right" onClick={onAdminSetupClickHandler}><AddIcon />Create New Admin</button>
                    </div>
                </div>
                <div className="ag-theme-alpine">
                    <AgGridReact
                        domLayout={gridOptionsAdmin.domLayout}
                        pagination={gridOptionsAdmin.pagination}
                        paginationPageSize={gridOptionsAdmin.pageSize}
                        animateRows={gridOptionsAdmin.animateRows}
                        defaultColDef={gridOptionsAdmin.defaultColDef}
                        columnDefs={gridOptionsAdmin.columnDefs}
                        sortingOrder={gridOptionsAdmin.sortingOrder}
                        frameworkComponents={frameworkComponents}
                        context={adminContext}
                        rowData={adminAccounts}
                        onGridReady={onGridReady}>
                    </AgGridReact>
                </div>
            </div>}
            {tabValue === 1 && <div role="tabpanel">
                <div className="ag-theme-alpine">
                    <AgGridReact
                        domLayout={gridOptionsContractor.domLayout}
                        pagination={gridOptionsContractor.pagination}
                        paginationPageSize={gridOptionsContractor.pageSize}
                        animateRows={gridOptionsContractor.animateRows}
                        defaultColDef={gridOptionsContractor.defaultColDef}
                        columnDefs={gridOptionsContractor.columnDefs}
                        sortingOrder={gridOptionsContractor.sortingOrder}
                        frameworkComponents={frameworkComponents}
                        context={contractorContext}
                        rowData={contractorAccounts}
                        onGridReady={onGridReady}>
                    </AgGridReact>
                </div>
            </div>}
            {tabValue === 2 && <div role="tabpanel">
                <div className="ag-theme-alpine">
                    <AgGridReact
                        domLayout={gridOptionsInstaller.domLayout}
                        pagination={gridOptionsInstaller.pagination}
                        paginationPageSize={gridOptionsInstaller.pageSize}
                        animateRows={gridOptionsInstaller.animateRows}
                        defaultColDef={gridOptionsInstaller.defaultColDef}
                        columnDefs={gridOptionsInstaller.columnDefs}
                        sortingOrder={gridOptionsInstaller.sortingOrder}
                        frameworkComponents={frameworkComponents}
                        context={installerContext}
                        rowData={installerAccounts}
                        onGridReady={onGridReady}>
                    </AgGridReact>
                </div>
            </div>}
            {tabValue === 3 && <div role="tabpanel">
                <div className="row action-row">
                    <div className="col">
                        <button className="blackbtn logon-btn-space float-right" onClick={onWMSetupClickHandler}><AddIcon />Create New Warehouse Manager</button>
                    </div>
                </div>
                <div className="ag-theme-alpine">
                    <AgGridReact
                        domLayout={gridOptionsWM.domLayout}
                        pagination={gridOptionsWM.pagination}
                        paginationPageSize={gridOptionsWM.pageSize}
                        animateRows={gridOptionsWM.animateRows}
                        defaultColDef={gridOptionsWM.defaultColDef}
                        columnDefs={gridOptionsWM.columnDefs}
                        sortingOrder={gridOptionsWM.sortingOrder}
                        frameworkComponents={frameworkComponents}
                        context={wmContext}
                        rowData={wmAccounts}
                        onGridReady={onGridReady}>
                    </AgGridReact>
                </div>
            </div>}
            <AccountSetupDialog
                id="account-setup-dialog"
                keepMounted
                open={dialogObj.open}
                onClose={dialogObj.handleClose}
                title={dialogObj.title}
                role={dialogObj.role}
                accountId={dialogObj.id}>
            </AccountSetupDialog>
            <ConfirmationDialog
                id="confirmation-dialog"
                keepMounted
                open={dialogConfirmationObj.open}
                onClose={dialogConfirmationObj.handleClose}
                title={dialogConfirmationObj.title}
                content={dialogConfirmationObj.content}
                params={dialogConfirmationObj.params}>
            </ConfirmationDialog>
        </div>
    );
}

/** Get State from Reducers & map to Contractor Form */
const mapStateToProps = state => {
    return {
        loggedUserInfo: getLoggedInUserInfo(state),
        accounts: state.jobsDashboardReducer.accounts || [],
        errorMessage: state.contractorReducer.errorMessage || ''
    }
}

/** Attach actions to Contractor Form */
const mapDispatchToProps = dispatch => {
    return {
        createAdminAction: (payload) => dispatch(createAdminAction(payload)),
        deleteAdminAction: (payload) => dispatch(deleteAdminAction(payload)),
        createWMAction: (payload) => dispatch(createWMAction(payload)),
        deleteWMAction: (payload) => dispatch(deleteWMAction(payload)),        
        setupAccountAction: (payload) => dispatch(setupAccountAction(payload)),
        removeAccountAction: (payload) => dispatch(removeAccountAction(payload))
    }
}

/** User Management Form */
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withRouter(UserManagementForm)));
import {
    ON_LOAD_ARCHIVE_CONTRACTORS,
    ON_ACTIVATE_CONTRACTOR,
    ON_LOAD_ARCHIVE_INSTALLERS,
    ON_ACTIVATE_INSTALLER,
    ON_LOAD_ARCHIVE_CUSTOMERS,
    ON_ACTIVATE_CUSTOMER,
    ON_LOAD_ARCHIVE_JOBS,
    ON_ACTIVATE_JOB
  } from './types';
  
  export const onLoadArchiveContractors = () => ({
    type: ON_LOAD_ARCHIVE_CONTRACTORS
  });

  export const onActivateContractor = (payload) => ({
    type: ON_ACTIVATE_CONTRACTOR, payload
  });

  export const onLoadArchiveInstallers = () => ({
    type: ON_LOAD_ARCHIVE_INSTALLERS
  });

  export const onActivateInstaller = (payload) => ({
    type: ON_ACTIVATE_INSTALLER, payload
  });  

  export const onLoadArchiveCustomers = () => ({
    type: ON_LOAD_ARCHIVE_CUSTOMERS
  });

  export const onActivateCustomer = (payload) => ({
    type: ON_ACTIVATE_CUSTOMER, payload
  });  

  export const onLoadArchiveJobs = () => ({
    type: ON_LOAD_ARCHIVE_JOBS
  });

  export const onActivateJob = (payload) => ({
    type: ON_ACTIVATE_JOB, payload
  });  
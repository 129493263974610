import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { styles}  from './styles';
import { useForm } from "react-hook-form";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
      display: 'flex',
      flexWrap: 'wrap',
  },
  margin: {
      margin: theme.spacing(1),
  },
  withoutLabel: {
      marginTop: theme.spacing(3),
  },
  textField: {
      width: '25ch',
  },
}));

const  Resetpassword=(props)=> {
  const classes = useStyles();

    const { register, handleSubmit, errors} = useForm({
      mode: "onChange"
    });

    const onSubmit = data => {
     if (data.newpassword === data.confirmpassword) {
        alert('New password and confirm password match!');
        props.history.push('/change');
    } else {
        alert('New password and confirm password not match!');
        }
    };
    
return (
    <div>
        {/* <h3>Reset Password</h3><br />
    <form onSubmit={handleSubmit(onSubmit)}>
      <label>Enter New Password :
      <input type="password" name="newpassword" ref={register({ required: true } )} /></label><br/>

      <label>Confirm Password : 
      <input type="password" name="confirmpassword" ref={register({ required: true })} /></label><br/>

      <input type="submit" value='Reset the password'/><br/>
    </form> */}


    <div class="container login-container">
      <div class="row">
        <div class="col-md-12 login-form-1">
          <h3><span class="s-title2">Reset Password</span></h3>
          <br></br>
          <div>
            <FormControl className={classNames(classes.margin)} variant="outlined">
              <div class="form-group">
                <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type='password'
                  name="newpassword"
                  inputRef={register({ required: true })}
                  endAdornment={
                    <InputAdornment position="end">                     
                    </InputAdornment>
                  }
                  labelWidth={70}
                ></OutlinedInput><br />
                {errors.confirmpassword && errors.confirmpassword.type === "required" && <span>New Password is required</span>}
              </div>
            </FormControl>
            <FormControl className={classNames(classes.margin)} variant="outlined">
              <div class="form-group">
                <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type='password'
                  name="confirmpassword"
                  inputRef={register({ required: true })}
                  endAdornment={
                    <InputAdornment position="end">                     
                    </InputAdornment>
                  }
                  labelWidth={70}
                ></OutlinedInput><br />
                {errors.confirmpassword && errors.confirmpassword.type === "required" && <span>Confirm Password is required</span>}
              </div>
            </FormControl>
            <br></br>
            <Link class="blackbtn logon-btn-space" component="button" variant="body2" type="submit" onClick={handleSubmit(onSubmit)}  >Submit</Link>

          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
export default withStyles(styles)(Resetpassword);
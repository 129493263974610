const JobIdLinkCellRenderer = (props) => {

    const handleJobSelection = () => {
        const {api, context: { onJobSelectClickHandler }, data } = props;
        onJobSelectClickHandler(data);
    }  

    return (<div>        
          <span onClick={handleJobSelection} style={{textDecoration: 'underline', cursor:'pointer', color:'blue'}}>{props.value}</span>
    </div>);
}

export default JobIdLinkCellRenderer;
import React,{ useState} from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../shared/tab-panel";
import { withStyles } from '@material-ui/core/styles';
import ContractorDashboard from './../contractor-management/contractor-dashboard';
import { styles}  from './styles';
import banner from '../images/home-banner.png';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';


const HomePage = (props) => {   

  return (
    <div>
    <div>
      <Grid container spacing={0} >
        <Grid item xs={12}>
          <img src={banner} class="banner" alt="Osuna Flooring" />
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} class="home-title">
            Welcome to Osuna Flooring...
          </Grid>
          <Grid item xs={12} class="home-data">
            The flooring specialist is here. We have a great selection of products including natural stone, carpets, wooden, vinyl flooring, and also have exclusive wall products including stone veneer sheets. We are also the leading countertop fabrication and installation brand in the country and have been associated with top Interior designing studios & Builders.
            If you are looking for a complete new flooring technology, we are here to create several decorative flooring to suit your space. Whether it is commercial or residential, interior, or exterior. We provide stamping on fresh concrete, overlay on your existing concrete, dye and stains, Metallic epoxies, Acrylic spray finishes, and many more.
          </Grid>
        </Grid> 
      </Grid>
    </div>
    </div>
  );
};

export default withStyles(styles)(HomePage);

import { fork, all} from 'redux-saga/effects';
import  userFormSagas from './user-form/saga';
import jobsDashboardSaga from './dashboard/saga';
import contractorManagementSaga from './contractor-management/saga';
import installerManagementSaga from './installer-management/saga';
import customerManagementSaga from './customer-management/saga';
import manageArchiveWatchers from './manage-archives/saga';

const sagasList = [
    ...userFormSagas,
    ...jobsDashboardSaga,
    ...contractorManagementSaga,
    ...installerManagementSaga,
    ...customerManagementSaga,
    ...manageArchiveWatchers
    /* Add other sagas here*/
];

export default function* masterSaga() {
    yield all(sagasList.map(saga => fork(saga)));
}

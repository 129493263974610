import {
  ON_NEW_CONTRACTOR_CLICK,
  ON_VIEW_CONTRACTOR_CLICK,
  ON_EDIT_CONTRACTOR_CLICK,
  ON_DELETE_CONTRACTOR_CLICK,
  ON_LIST_CONTRACTORS,
  ON_CONTRACTOR_CREATE,
  ON_CONTRACTOR_SAVE
} from './types';

export const onNewContractorClick = (payload) => ({
  type: ON_NEW_CONTRACTOR_CLICK, payload
});

export const onViewContractorClick = (payload) => ({
  type: ON_VIEW_CONTRACTOR_CLICK, payload
});

export const onEditContractorClick = (payload) => ({
  type: ON_EDIT_CONTRACTOR_CLICK, payload
});

export const onDeleteContractorClick = (payload) => ({
  type: ON_DELETE_CONTRACTOR_CLICK, payload
});

export const onListContractors = () => ({
  type: ON_LIST_CONTRACTORS
});

export const onContractorCreate = (payload) => ({
  type: ON_CONTRACTOR_CREATE, payload
});

export const onContractorSave = (payload) => ({
  type: ON_CONTRACTOR_SAVE, payload
});
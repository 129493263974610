import {
  SET_ARCHIVE_JOBS,
  SET_ARCHIVE_CONTRACTORS,
  SET_ARCHIVE_INSTALLERS,
  SET_ARCHIVE_CUSTOMERS
} from "./types";

const initialState = {
  jobs: [],
  contractors: [],
  installers: [],
  customers: [],
  errorMessage: ""
};

const manageArchiveReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_ARCHIVE_JOBS: {
      return {
        ...state,
        jobs: payload,
      };
    }
    case SET_ARCHIVE_CONTRACTORS: {
      return {
        ...state,
        contractors: payload,
      };
    }
    case SET_ARCHIVE_INSTALLERS: {
      return {
        ...state,
        installers: payload,
      };
    }
    case SET_ARCHIVE_CUSTOMERS: {
      return {
        ...state,
        customers: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default manageArchiveReducer;

import { ON_SUBMIT_LOGIN, SET_LOGGEDIN_USER_INFO, LOGGED_OUT_USER, CREATE_ADMIN_ACCOUNT, DELETE_ADMIN_ACCOUNT, CREATE_WM_ACCOUNT, DELETE_WM_ACCOUNT, UPDATE_ACCOUNT, REMOVE_ACCOUNT } from "./types";

export const onSubmitLogin = (payload) => ({
  type: ON_SUBMIT_LOGIN, payload
});

export const setLoggedInUserInfo = (payload) => ({
  type: SET_LOGGEDIN_USER_INFO, payload
});

export const onLoggedOutUser = () => ({
  type: LOGGED_OUT_USER
});

export const createAdminAction = (payload) => ({
  type: CREATE_ADMIN_ACCOUNT, payload
});

export const deleteAdminAction = (payload) => ({
  type: DELETE_ADMIN_ACCOUNT, payload
});

export const createWMAction = (payload) => ({
  type: CREATE_WM_ACCOUNT, payload
});

export const deleteWMAction = (payload) => ({
  type: DELETE_WM_ACCOUNT, payload
});

export const setupAccountAction = (payload) => ({
  type: UPDATE_ACCOUNT, payload
});

export const removeAccountAction = (payload) => ({
  type: REMOVE_ACCOUNT, payload
});
import React, { useState } from "react";
import InstallerDashboard from "./../installer-management/installer-dashboard";

const InstallerLayout = () => {

    return (

        <div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12" style={{ width: '95%', marginTop: '10px' }}> </div>
                    <div class="col-lg-12" style={{ width: '100%', paddingRight: '25px', paddingLeft: '25px' }}>
                        <div id="mb-brd" class="col-lg-12" style={{ marginLeft: '-15px' }}>
                            <div class="col-lg-3 pages-tabs">
                                Installer
                        </div>
                            <div class="col-lg-9 ">

                            </div>
                        </div>
                        <div class="col-lg-12 pages-body">
                            <InstallerDashboard />
                        </div>
                    </div>
                    <div class="col-lg-12"> </div>
                </div>
            </div>
        </div>
    )
}
export default InstallerLayout;
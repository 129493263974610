import {
    SET_INSTALLERS_LIST,
    SET_INSTALLER_VIEW,
    SET_INSTALLER_EDIT,
    SET_CONTRACTORS_DROPDOWN_VALUES,
    ON_NEW_INSTALLER_CLICK,
    ON_VIEW_INSTALLER_CLICK,
    ON_EDIT_INSTALLER_CLICK,
    ON_ERROR
  } from './types';
  
  const initialState = {
    errorMessage: '',
    currentAction: 'CREATE',
    currentInstaller: {
      _id: '',
      contractorId: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: 'CA',
        zipCode: ''
      }
    },
    contractorDropdownValues: []
  }
  
  const installerReducer = (state = initialState, action) => {
    const { payload } = action;
    switch (action.type) {
      case SET_INSTALLERS_LIST: {
        return {
          ...state,
          installers: payload,
          errorMessage: ''
        };
      }
      case ON_NEW_INSTALLER_CLICK: {
        return {
          ...state,
          currentInstaller: {
            _id: '',
            contractortId: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            address: {
              addressLine1: '',
              addressLine2: '',
              city: '',
              state: 'CA',
              zipCode: ''
            }
          },
          currentAction: 'CREATE',
          errorMessage: ''
        };
      }
      case ON_VIEW_INSTALLER_CLICK: {
        return {
          ...state,
          currentInstaller: {
            _id: '',
            contractortId: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            address: {
              addressLine1: '',
              addressLine2: '',
              city: '',
              state: '',
              zipCode: ''
            }
          },
          currentAction: 'VIEW',
          errorMessage: ''        
        };
      }
      case SET_INSTALLER_VIEW: {
        return {
          ...state,
          currentInstaller: payload,
          currentAction: 'VIEW',
          errorMessage: ''
        };
      }
      case ON_EDIT_INSTALLER_CLICK: {
        return {
          ...state,
          currentInstaller: {
            _id: '',
            contractortId: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            address: {
              addressLine1: '',
              addressLine2: '',
              city: '',
              state: '',
              zipCode: ''
            }
          },
          currentAction: 'EDIT',
          errorMessage: ''        
        };
      }    
      case SET_INSTALLER_EDIT: {
        return {
          ...state,
          currentInstaller: payload,
          currentAction: 'EDIT',
          errorMessage: ''
        };
      }
      case SET_CONTRACTORS_DROPDOWN_VALUES: {
        return {
          ...state,
          contractorDropdownValues: payload,
        };        
      }
      case ON_ERROR: {
        return {
          ...state,
          errorMessage: payload
        }
      }
      default: {
        return state;
      }
    }
  };
  
  export default installerReducer;
  
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    ON_LOAD_ARCHIVE_JOBS,
    SET_ARCHIVE_JOBS,
    ON_ACTIVATE_JOB,    
    ON_LOAD_ARCHIVE_CONTRACTORS,
    SET_ARCHIVE_CONTRACTORS,
    ON_ACTIVATE_CONTRACTOR,
    ON_LOAD_ARCHIVE_INSTALLERS,
    SET_ARCHIVE_INSTALLERS,
    ON_ACTIVATE_INSTALLER,
    ON_LOAD_ARCHIVE_CUSTOMERS,
    SET_ARCHIVE_CUSTOMERS,
    ON_ACTIVATE_CUSTOMER    
} from './ducks/types';
import { fetchGet, fetchPost } from './../rest-api-client';
import configData from "./../config.json";

configData.SERVER_URL = process.env.REACT_APP_API_ENDPOINT;

function* onLoadArchiveJobs() {
    try {
        let jobs = yield call(fetchGet, `${configData.SERVER_URL}/job/getarchives`);
        yield put({ type: SET_ARCHIVE_JOBS, payload: jobs });

    } catch (e) {
        console.log('error on loading archived jobs');
    }
}

function* onActivateJob(action) {
    try {
        const { payload } = action;
        yield call(fetchPost, `${configData.SERVER_URL}/job/activate/${payload}`);
        yield put({ type: ON_LOAD_ARCHIVE_JOBS, payload: null});
    } catch (e) {
        console.log('error on activating job');
    }
}

function* onLoadArchiveContractors() {
    try {
        let contractors = yield call(fetchGet, `${configData.SERVER_URL}/contractor/getarchives`);
        yield put({ type: SET_ARCHIVE_CONTRACTORS, payload: contractors });

    } catch (e) {
        console.log('error on loading archived contractors');
    }
}

function* onActivateContractor(action) {
    try {
        const { payload } = action;
        yield call(fetchPost, `${configData.SERVER_URL}/contractor/activate/${payload}`);
        yield put({ type: ON_LOAD_ARCHIVE_CONTRACTORS, payload: null});
    } catch (e) {
        console.log('error on activating contractor');
    }
}

function* onLoadArchiveInstallers() {
    try {
        let installers = yield call(fetchGet, `${configData.SERVER_URL}/installer/getarchives`);
        yield put({ type: SET_ARCHIVE_INSTALLERS, payload: installers });

    } catch (e) {
        console.log('error on loading archived installers');
    }
}

function* onActivateInstaller(action) {
    try {
        const { payload } = action;
        yield call(fetchPost, `${configData.SERVER_URL}/installer/activate/${payload}`);
        yield put({ type: ON_LOAD_ARCHIVE_INSTALLERS, payload: null});
    } catch (e) {
        console.log('error on activating installer');
    }
}

function* onLoadArchiveCustomers() {
    try {
        let customers = yield call(fetchGet, `${configData.SERVER_URL}/customer/getarchives`);
        yield put({ type: SET_ARCHIVE_CUSTOMERS, payload: customers });

    } catch (e) {
        console.log('error on loading archived customers');
    }
}

function* onActivateCustomer(action) {
    try {
        const { payload } = action;
        yield call(fetchPost, `${configData.SERVER_URL}/customer/activate/${payload}`);
        yield put({ type: ON_LOAD_ARCHIVE_CUSTOMERS, payload: null});
    } catch (e) {
        console.log('error on activating customer');
    }
}

function* manageArchiveWatchers() {
    yield takeEvery(ON_LOAD_ARCHIVE_JOBS, onLoadArchiveJobs);
    yield takeLatest(ON_ACTIVATE_JOB, onActivateJob);    
    yield takeEvery(ON_LOAD_ARCHIVE_CONTRACTORS, onLoadArchiveContractors);
    yield takeLatest(ON_ACTIVATE_CONTRACTOR, onActivateContractor);
    yield takeEvery(ON_LOAD_ARCHIVE_INSTALLERS, onLoadArchiveInstallers);
    yield takeLatest(ON_ACTIVATE_INSTALLER, onActivateInstaller);  
    yield takeEvery(ON_LOAD_ARCHIVE_CUSTOMERS, onLoadArchiveCustomers);
    yield takeLatest(ON_ACTIVATE_CUSTOMER, onActivateCustomer);         
}

export default [manageArchiveWatchers];
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { styles } from "./styles";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import { onSaveJobInfo } from "../dashboard/ducks/actions";
import { v4 as uuidv4 } from "uuid";

const DynamicRoom = ({
  roomHeading,
  room,
  onRoomDelete,
  allowDelete,
  onRoomDataChanged,
}) => {
  const onRoomDeleteHandler = () => {
    onRoomDelete(room.roomId);
  };

  return (
    <div class="row" style={{ width: "98%" }}>
      <div
        class="col-lg-12 mb-placement"
        style={{ width: "60%", display: "block", float: "left", marginLeft: "20px" }}
      >
        <div
          class="row"
          style={{
            width: "170px",
            backgroundColor: "#F9FBE5",
            color: "#000000",
            padding: "0px",
            fontSize: "15px",
            borderRadiusTopleft: "5px",
            borderRadiusTopright: "5px",
            marginBottom: "-5px",
            marginTop: "15px",
            border: "1px solid #D5D5D5",
            height: "33px!important",
          }}
        >
          <div class="col-lg-8" style={{ marginTop: "8px", paddingBottom: "6px" }}>
            {roomHeading}
          </div>
          {allowDelete && (
            <div class="col-lg-4 text-right" style={{ marginTop: "5px" }}>
              <IconButton
                style={{ marginTop: "10px" }}
                onClick={onRoomDeleteHandler}
              >
                <CloseIcon title="Delete Room" />
              </IconButton>
            </div>
          )}
        </div>
        <div class="row">
          <div class="col-lg-12 container-add">
            <div class="col-lg-2 d-inline">
              <TextField
                autoFocus
                margin="dense"
                defaultValue={room.roomName}
                onBlur={(event) =>
                  onRoomDataChanged(event.target.value, "roomName", room.roomId)
                }
                id="name"
                label="Area/ Room"
                type="string"
              />
            </div>
            <div class="col-lg-2 d-inline">
              <TextField

                margin="dense"
                id="name"
                defaultValue={room.areaDimension}
                onBlur={(event) =>
                  onRoomDataChanged(event.target.value, "areaDimension", room.roomId)
                }
                label="Area Dimensions"
                type="string"
              />
            </div>
            <div class="col-lg-2 d-inline">
              <TextField

                margin="dense"
                id="name"
                defaultValue={room.areaLength}
                onBlur={(event) =>
                  onRoomDataChanged(event.target.value, "areaLength", room.roomId)
                }
                label="Square Feet/Yards"
                type="string"
              />
            </div>
            <div class="col-lg-6 d-inline">
              <TextField
                id="outlined-basic"
                name="notes"
                multiline
                rows={2}
                defaultValue={room.roomNotes}
                onBlur={(event) =>
                  onRoomDataChanged(event.target.value, "roomNotes", room.roomId)
                }
                rowsMax={2}
                label="Notes"
                variant="outlined"
                style={{
                  height: "20px",
                  marginBottom: "5px",
                  marginTop: "5px",
                  width: "44%",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const MaterialPlacement = (props) => {
  const {
    isEditMode,
    onSummaryEditClick,
    jobMaterialPlacementInfo,
    jobOrderDetail,
    onStepperBackClick,
    onStepperNextClick,
    onSaveJobMaterialPlacementInfoAction,
  } = props;
  const classes = useStyles();

  const jobMaterialPlacementInfoSelector = (jobOrder) => {
    if (
      jobOrder &&
      jobOrder.materialPlacement &&
      jobOrder.materialPlacement.rooms &&
      jobOrder.materialPlacement.rooms.length > 0
    ) {
      return jobOrder.materialPlacement;
    }
    const defaultRooms = [
      {
        roomId: uuidv4(),
        roomName: "",
        areaDimension: "",
        areaLength: "",
        roomNotes: "",
      },
    ];
    const materialPlacement = {
      rooms: defaultRooms,
    };
    return materialPlacement;
  };

  useEffect(() => {
    const matInfo = jobMaterialPlacementInfoSelector(jobOrderDetail);
    setRooms(matInfo.rooms);
  }, [jobOrderDetail]);

  const [rooms, setRooms] = useState([]);

  const onRoomDelete = (roomId) => {
    const indexDelete = rooms.findIndex((room) => room.roomId === roomId);
    rooms.splice(indexDelete, 1);
    setRooms([...rooms]);
  };

  const onAddNewRoom = () => {
    const clonedRooms = [...rooms];
    clonedRooms.push({
      roomId: uuidv4(),
      roomName: "",
      areaDimension: "",
      areaLength: "",
      roomNotes: "",
    });
    setRooms(clonedRooms);
  };

  const onNextClick = () => {
    if (jobOrderDetail.jobId) {
      onSaveJobMaterialPlacementInfoAction({ jobId: jobOrderDetail.jobId, materialPlacement: { rooms: [...rooms] } });
      onStepperNextClick();
    }
  };

  const handleBack = () => {
    onStepperBackClick();
  };

  const onRoomDataChanged = (value, propName, roomId) => {
    const clonedRooms = [...rooms];
    const room = clonedRooms.find((room) => room.roomId === roomId);
    room[propName] = value;
    setRooms(clonedRooms);
  };

  const onClickFinish = () => {
    if (jobOrderDetail.jobId) {
      onSaveJobMaterialPlacementInfoAction({ jobId: jobOrderDetail.jobId, materialPlacement: { rooms: [...rooms] } });
      onSummaryEditClick(6, false);
    }
  };

  return (
    <div>
      <div class="col-lg-12 container-center text-left container-stepper">
        <div class="col-lg-12">
          <div class="row block-header">
            <div
              class="col-lg-10 text-left d-inline"
              style={{ fontWeight: "bold", paddingTop: "8px" }}
            >
              Placement
            </div>
            <div class="col-lg-2 text-right d-inline">
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                onClick={onAddNewRoom}
                startIcon={<AddCircleIcon />}
              >
                Add Room
              </Button>
            </div>
          </div>
          <div class="row typeofmetcss">
            {rooms &&
              rooms.length > 0 &&
              [...Array(rooms.length)].map((item, index) => {
                const roomId = rooms[index].roomId;
                return (
                  <DynamicRoom
                    key={roomId}
                    room={rooms[index]}
                    roomHeading={`Room ${index + 1}`}
                    onRoomDelete={onRoomDelete}
                    onRoomDataChanged={onRoomDataChanged}
                    allowDelete={rooms.length > 1}
                  />
                );
              })}
          </div>
        </div>
      </div>
      <div class="col-lg-12  text-left" style={{ marginTop: "10px" }}>
        <div style={{ textAlign: "right" }}>
          {/* <Button variant="contained" color="primary" >Reset</Button> */}
          <Button onClick={handleBack}>Back</Button>
          <Button variant="contained" color="primary" onClick={onNextClick}>
            {" "}
            Next
          </Button>
          {isEditMode && (
            <Button variant="contained" color="primary" onClick={onClickFinish}>
              {" "}
              Finish
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    jobOrderDetail: state.jobsDashboardReducer.jobOrderDetail || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveJobMaterialPlacementInfoAction: (payload) =>
      dispatch(onSaveJobInfo(payload)),
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MaterialPlacement)
);

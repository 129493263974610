export const getLoggedInUserInfo =(state)=>{
    if(state.userFormReducer.loggedUserInfo){
        return state.userFormReducer.loggedUserInfo;
    }
    else{
        const loggedUserInfoJSon = localStorage.getItem("loggedinUserInfo");
        if(loggedUserInfoJSon){
        return JSON.parse(loggedUserInfoJSon);
        }
        return {};
    }
}
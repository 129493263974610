export const ON_NEW_INSTALLER_CLICK = 'ON_NEW_INSTALLER_CLICK';
export const ON_VIEW_INSTALLER_CLICK = 'ON_VIEW_INSTALLER_CLICK';
export const ON_EDIT_INSTALLER_CLICK = 'ON_EDIT_INSTALLER_CLICK';
export const ON_DELETE_INSTALLER_CLICK = 'ON_DELETE_INSTALLER_CLICK';
export const ON_LIST_INSTALLERS = 'ON_LIST_INSTALLERS';
export const ON_INSTALLER_CREATE = 'ON_INSTALLER_CREATE';
export const ON_INSTALLER_SAVE = 'ON_INSTALLER_SAVE';

export const SET_INSTALLER_VIEW = 'SET_INSTALLER_VIEW';
export const SET_INSTALLER_EDIT = 'SET_INSTALLER_EDIT';
export const SET_INSTALLERS_LIST = 'SET_INSTALLERS_LIST';

export const ON_LOAD_CONTRACTORS_DROPDOWN_VALUES = 'ON_LOAD_CONTRACTORS_DROPDOWN_VALUES';
export const SET_CONTRACTORS_DROPDOWN_VALUES = 'SET_CONTRACTORS_DROPDOWN_VALUES';

export const ON_ERROR = 'ON_ERROR';
import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { ON_SUBMIT_LOGIN, SET_LOGGEDIN_USER_INFO, ON_LOGIN_FAILURE, CREATE_ADMIN_ACCOUNT, DELETE_ADMIN_ACCOUNT, CREATE_WM_ACCOUNT, DELETE_WM_ACCOUNT, UPDATE_ACCOUNT, REMOVE_ACCOUNT } from './ducks/types';
import { fetchPost } from './../rest-api-client';
import configData from "./../config.json";
import { ON_LOAD_ACCOUNTS } from "./../dashboard/ducks/types";

export const getCredentials = (state) => state.userFormReducer.credentials;

configData.SERVER_URL = process.env.REACT_APP_API_ENDPOINT;

function* onSubmitLogin(action) {
    try {
        const { payload } = action;
        //take payload from store
        console.log("configData.SERVER_URL ", configData.SERVER_URL );
        const cred = yield select(getCredentials);
        let user = yield call(fetchPost, `${configData.SERVER_URL}/user/signin`, payload);

        //save credentials in Session storage
        localStorage.setItem("loggedinUserInfo",JSON.stringify(user));

        if (user.isAuthenticated) {
            yield put({ type: SET_LOGGEDIN_USER_INFO, payload: user });            
        }
        else {
            const failureMessage = user.error || "invalid Credentials";
            yield put({ type: ON_LOGIN_FAILURE, payload: failureMessage });
        }



    } catch (e) {
        console.log('error in submit login')
    }
}

function* onSubmitLoginWatcher() {
    yield takeLatest(ON_SUBMIT_LOGIN, onSubmitLogin)
}

function* onAdminAccountCreate(action) {
    try {
        const { payload } = action;
        let result = yield call(fetchPost, `${configData.SERVER_URL}/user/createadmin`, payload);
        yield put({ type: ON_LOAD_ACCOUNTS, payload: null});
    } catch (e) {
        console.log('error in account setup')
    }
}

function* onAdminAccountCreateWatcher() {
    yield takeLatest(CREATE_ADMIN_ACCOUNT, onAdminAccountCreate)
}

function* onAdminAccountDelete(action) {
    try {
        const { payload } = action;
        let result = yield call(fetchPost, `${configData.SERVER_URL}/user/deleteadmin/${payload}`);
        yield put({ type: ON_LOAD_ACCOUNTS, payload: null});
    } catch (e) {
        console.log('error in account delete')
    }
}

function* onAdminAccountDeleteWatcher() {
    yield takeLatest(DELETE_ADMIN_ACCOUNT, onAdminAccountDelete)
}

function* onWMAccountCreate(action) {
    try {
        const { payload } = action;
        let result = yield call(fetchPost, `${configData.SERVER_URL}/user/createwm`, payload);
        yield put({ type: ON_LOAD_ACCOUNTS, payload: null});
    } catch (e) {
        console.log('error in account setup')
    }
}

function* onWMAccountCreateWatcher() {
    yield takeLatest(CREATE_WM_ACCOUNT, onWMAccountCreate)
}

function* onWMAccountDelete(action) {
    try {
        const { payload } = action;
        let result = yield call(fetchPost, `${configData.SERVER_URL}/user/deletewm/${payload}`);
        yield put({ type: ON_LOAD_ACCOUNTS, payload: null});
    } catch (e) {
        console.log('error in account delete')
    }
}

function* onWMAccountDeleteWatcher() {
    yield takeLatest(DELETE_WM_ACCOUNT, onWMAccountDelete)
}

function* onAccountSetup(action) {
    try {
        const { payload } = action;
        let result = yield call(fetchPost, `${configData.SERVER_URL}/user/updateaccount`, payload);
        yield put({ type: ON_LOAD_ACCOUNTS, payload: null});
    } catch (e) {
        console.log('error in account setup')
    }
}

function* onAccountSetupWatcher() {
    yield takeLatest(UPDATE_ACCOUNT, onAccountSetup)
}

function* onRemoveAccount(action) {
    try {
        const { payload } = action;
        let result = yield call(fetchPost, `${configData.SERVER_URL}/user/removeaccount/${payload}`);
        yield put({ type: ON_LOAD_ACCOUNTS, payload: null});
    } catch (e) {
        console.log('error in account removal')
    }
}

function* onRemoveAccountWatcher() {
    yield takeLatest(REMOVE_ACCOUNT, onRemoveAccount)
}

export default [onSubmitLoginWatcher, onAdminAccountCreateWatcher, onAdminAccountDeleteWatcher, onWMAccountCreateWatcher, onWMAccountDeleteWatcher, onAccountSetupWatcher, onRemoveAccountWatcher];
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { styles } from "./styles";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import InputLabel from "@material-ui/core/InputLabel";
import { onSaveJobInfo } from "../dashboard/ducks/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { typeOfMaterialList } from "./type-of-material-info";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const JobView = ({ loggedUserInfo, jobSummaryInfo, onSummaryEditClick }) => {
  const getTypeOfMaterialDisplayName = (typeId) => {
    const matType = typeOfMaterialList.find(
      (item) => item.materialTypeId === typeId
    );
    if (matType) {
      return matType.materialTypeDisplayName;
    }
    return typeId;
  };

  const getTypeOfMaterialOptionValue = (typeId, optionKey) => {
    const matType = typeOfMaterialList.find(
      (item) => item.materialTypeId === typeId
    );
    if (matType) {
      const option = matType.options.find((item) => item.key === optionKey);
      if (option) {
        return option.value;
      }
    }
    return optionKey;
  };
  const getAssignedContractorName = () => {
    if (jobSummaryInfo.contractorInstallerInfo && jobSummaryInfo.contractorInstallerInfo.contractorInfo) {
      return (
        jobSummaryInfo.contractorInstallerInfo.contractorInfo.firstName +
        " " +
        jobSummaryInfo.contractorInstallerInfo.contractorInfo.lastName
      );
    }
    return "";
  };

  const getAssignedInstallerNames = () => {
    const selctedInstallerNames =[];
    if (jobSummaryInfo.contractorInstallerInfo && jobSummaryInfo.contractorInstallerInfo.selectedInstallerList) {      
      jobSummaryInfo.contractorInstallerInfo.selectedInstallerList.forEach(selectedInstaller=>{        
          selctedInstallerNames.push(`${selectedInstaller.firstName} ${selectedInstaller.lastName} (PH: ${selectedInstaller.phone})`);
        })      
    }
    return selctedInstallerNames;
  };

  const classes = useStyles();
  return (
    <div class="typeofmetcss print-job" style={{ paddingRight: "5px" }}>
      <div class="col-lg-12 block-color-1">
        <div class="row block-header-summary-2">
          <div
            class="col-lg-10 text-left d-inline"
            style={{ paddingTop: "5px" }}
          >
            {" "}
            Customer Info{" "}
          </div>
          {loggedUserInfo.role.toLowerCase() === "admin" && (
            <div
              class="col-lg-2 text-right d-inline"
              style={{ paddingRight: "0px" }}
            >
              <Button
                variant="contained"
                onClick={() => onSummaryEditClick(0)}
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<EditIcon />}
                style={{ margin: "0!important;" }}
              >
                EDIT
              </Button>
            </div>
          )}
        </div>
        <div class="row text-left" style={{ paddingLeft: "20px" }}>
          <div class="col-lg-6" style={{ paddingTop: "8px" }}>
            <div class="d-block">
              <InputLabel class="summary-label" htmlFor="my-input">
                Company Name:
                {/* <span class="summary-label-span">
                  {jobSummaryInfo.customerInfo
                    ? jobSummaryInfo.customerInfo.companyname
                    : ""}
                </span> */}
              </InputLabel>
            </div>
            <div class="d-block">
              <InputLabel class="summary-label" htmlFor="my-input">
                First Name:
                <span class="summary-label-span">
                  {jobSummaryInfo.customerInfo
                    ? jobSummaryInfo.customerInfo.firstName
                    : ""}
                </span>
              </InputLabel>
            </div>
            <div class="d-block">
              <InputLabel class="summary-label" htmlFor="my-input">
                Last Name:
                <span class="summary-label-span">
                  {jobSummaryInfo.customerInfo
                    ? jobSummaryInfo.customerInfo.lastName
                    : ""}
                </span>
              </InputLabel>
            </div>
            <div class="d-block">
              <InputLabel class="summary-label" htmlFor="my-input">
                Email:
                <span class="summary-label-span">
                  {jobSummaryInfo.customerInfo
                    ? jobSummaryInfo.customerInfo.email
                    : ""}
                </span>
              </InputLabel>
            </div>
            <div class="d-block">
              <InputLabel class="summary-label" htmlFor="my-input">
                Phone:
                <span class="summary-label-span">
                  {jobSummaryInfo.customerInfo
                    ? jobSummaryInfo.customerInfo.phone
                    : ""}
                </span>
              </InputLabel>
            </div>
          </div>
          <div class="col-lg-6" style={{ paddingTop: "8px" }}>
            <div class="d-block">
              <InputLabel class="summary-label" htmlFor="my-input">
                Address Line 1:
                <span class="summary-label-span">
                  {jobSummaryInfo.customerInfo && jobSummaryInfo.customerInfo.address
                    ? jobSummaryInfo.customerInfo.address.addressLine1
                    : ""}
                </span>
              </InputLabel>
            </div>
            <div class="d-block">
              <InputLabel class="summary-label" htmlFor="my-input">
                Address Line 2:
                <span class="summary-label-span">
                  {jobSummaryInfo.customerInfo && jobSummaryInfo.customerInfo.address
                    ? jobSummaryInfo.customerInfo.address.addressLine2
                    : ""}
                </span>
              </InputLabel>
            </div>
            <div class="d-block">
              <InputLabel class="summary-label" htmlFor="my-input">
                City:
                <span class="summary-label-span">
                  {jobSummaryInfo.customerInfo && jobSummaryInfo.customerInfo.address
                    ? jobSummaryInfo.customerInfo.address.city
                    : ""}
                </span>
              </InputLabel>
            </div>
            <div class="d-block">
              <InputLabel class="summary-label" htmlFor="my-input">
                Zip Code:
                <span class="summary-label-span">
                  {jobSummaryInfo.customerInfo && jobSummaryInfo.customerInfo.address
                    ? jobSummaryInfo.customerInfo.address.zipCode
                    : ""}
                </span>
              </InputLabel>
            </div>
            <div class="d-block">
              <InputLabel class="summary-label" htmlFor="my-input">
                State:
                <span class="summary-label-span">
                  {jobSummaryInfo.customerInfo && jobSummaryInfo.customerInfo.address
                    ? jobSummaryInfo.customerInfo.address.state
                    : ""}
                </span>
              </InputLabel>
            </div>
          </div>
          <div class="col-lg-12" style={{ paddingTop: "8px" }}>
            <div class="d-block">
              <InputLabel class="summary-label" htmlFor="my-input">
                Description:
                <span class="summary-label-span">
                  {jobSummaryInfo
                    ? jobSummaryInfo.jobDescription
                    : ""}
                </span>
              </InputLabel>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
      </div>

      <div class="col-lg-12 block-color-2">
        <div class="row block-header-summary-2">
          <div
            class="col-lg-10 text-left d-inline"
            style={{ paddingTop: "5px" }}
          >
            {" "}
            Initial Schedule & Construction Type{" "}
          </div>
          {loggedUserInfo.role.toLowerCase() === "admin" && (
            <div
              class="col-lg-2 text-right d-inline"
              style={{ paddingRight: "0px" }}
            >
              <Button
                variant="contained"
                onClick={() => onSummaryEditClick(1)}
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<EditIcon />}
                style={{ margin: "0!important;" }}
              >
                EDIT
              </Button>
            </div>
          )}
        </div>
        <div class="row text-left" style={{ paddingLeft: "20px" }}>
          <div class="col-lg-6" style={{ paddingTop: "8px" }}>
            <div class="d-block">
              <InputLabel class="summary-label" htmlFor="my-input">
                <span class="summary-label-blk">Initial Schedule</span>
              </InputLabel>
            </div>
            <div class="d-block">
              <InputLabel class="summary-label" htmlFor="my-input">
                Measure date:
                <span class="summary-label-span">
                  {jobSummaryInfo.initialScheduleInfo
                    ? jobSummaryInfo.initialScheduleInfo.mdate
                    : ""}
                </span>
              </InputLabel>
            </div>
            <div class="d-block">
              <InputLabel class="summary-label" htmlFor="my-input">
                Tentative installation date:
                <span class="summary-label-span">
                  {jobSummaryInfo.initialScheduleInfo
                    ? jobSummaryInfo.initialScheduleInfo.tidate
                    : ""}
                </span>
              </InputLabel>
            </div>
            <div class="d-block">
              <InputLabel class="summary-label" htmlFor="my-input">
                Confirm installation date:
                <span class="summary-label-span">
                  {jobSummaryInfo.initialScheduleInfo
                    ? jobSummaryInfo.initialScheduleInfo.cidate
                    : ""}
                </span>
              </InputLabel>
            </div>
          </div>

          <div class="col-lg-6" style={{ paddingTop: "8px" }}>
            <div class="d-block">
              <InputLabel class="summary-label" htmlFor="my-input">
                <span class="summary-label-blk">Construction Type</span>
              </InputLabel>
            </div>
            <div class="d-block">
              <InputLabel class="summary-label" htmlFor="my-input">
                New:
                <span class="summary-label-span">
                  {jobSummaryInfo.initialScheduleInfo &&
                  jobSummaryInfo.initialScheduleInfo.new
                    ? "Yes"
                    : "No"}
                </span>
              </InputLabel>
            </div>
            <div class="d-block">
              <InputLabel class="summary-label" htmlFor="my-input">
                Replacement:
                <span class="summary-label-span">
                  {jobSummaryInfo.initialScheduleInfo &&
                  jobSummaryInfo.initialScheduleInfo.replacement
                    ? "Yes"
                    : "No"}
                </span>
              </InputLabel>
            </div>
            <div class="d-block">
              <InputLabel class="summary-label" htmlFor="my-input">
                Commercial:
                <span class="summary-label-span">
                  {jobSummaryInfo.initialScheduleInfo &&
                  jobSummaryInfo.initialScheduleInfo.commercial
                    ? "Yes"
                    : "No"}
                </span>
              </InputLabel>
            </div>
            <div class="d-block">
              <InputLabel class="summary-label" htmlFor="my-input">
                Property Management:
                <span class="summary-label-span">
                  {jobSummaryInfo.initialScheduleInfo &&
                  jobSummaryInfo.initialScheduleInfo.propertyManagement
                    ? "Yes"
                    : "No"}
                </span>
              </InputLabel>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
      </div>

      <div class="col-lg-12 block-color-1">
        <div class="row block-header-summary-2">
          <div
            class="col-lg-10 text-left d-inline"
            style={{ paddingTop: "5px" }}
          >
            {" "}
            Placement{" "}
          </div>
          {loggedUserInfo.role.toLowerCase() === "admin" && (
            <div
              class="col-lg-2 text-right d-inline"
              style={{ paddingRight: "0px" }}
            >
              <Button
                variant="contained"
                onClick={() => onSummaryEditClick(2)}
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<EditIcon />}
                style={{ margin: "0!important;" }}
              >
                EDIT
              </Button>
            </div>
          )}
        </div>
      </div>
      <div class="row text-left block-color-2" style={{ paddingLeft: "40px" }}>
        <div class="col-lg-6 d-inline" style={{ paddingTop: "8px" }}>
          {jobSummaryInfo &&
            jobSummaryInfo.materialPlacement &&
            jobSummaryInfo.materialPlacement.rooms.map((item, i) => (
              <div class="d-inline">
                {"Room " + (i + 1)}
                <div class="d-block">
                  <InputLabel class="summary-label" htmlFor="my-input" >
                    Area/ Room:
                    <span class="summary-label-span">{item.roomName}</span>
                  </InputLabel>
                </div>
                <div class="d-block">
                  <InputLabel class="summary-label" htmlFor="my-input">
                    Area Dimensions:
                    <span class="summary-label-span">{item.areaDimension}</span>
                  </InputLabel>
                </div>
                <div class="d-block">
                  <InputLabel class="summary-label" htmlFor="my-input">
                    Square Feet/Yards:
                    <span class="summary-label-span">{item.areaLength}</span>
                  </InputLabel>
                </div>
                <div class="d-block">
                  <InputLabel class="summary-label" htmlFor="my-input">
                    Notes:
                    <span class="summary-label-span">{item.roomNotes}</span>
                  </InputLabel>
                </div>
                <hr style={{ windth: "600px" }} />
              </div>
            ))}
        </div>
      </div>    
      <div class="col-lg-12 block-color-1">
        <div class="row block-header-summary-2">
          <div
            class="col-lg-10 text-left d-inline"
            style={{ paddingTop: "5px" }}
          >
            {" "}
            Type Of Material{" "}
          </div>
          {loggedUserInfo.role.toLowerCase() === "admin" && (
            <div
              class="col-lg-2 text-right d-inline"
              style={{ paddingRight: "0px" }}
            >
              <Button
                variant="contained"
                onClick={() => onSummaryEditClick(3)}
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<EditIcon />}
                style={{ margin: "0!important;" }}
              >
                EDIT
              </Button>
            </div>
          )}
        </div>
        <div class="row text-left" style={{ paddingLeft: "20px" }}>
          {jobSummaryInfo &&
            jobSummaryInfo.typeOfMaterial &&
            Object.keys(jobSummaryInfo.typeOfMaterial).map((key, i) => (
              <div class="row text-left" style={{ paddingLeft: "20px" }}>
                <div class="col-lg-12">
                  <br></br>
                  <span class="summary-label  d-inline ">
                    {getTypeOfMaterialDisplayName(key)}:
                  </span>
                  {Object.keys(jobSummaryInfo.typeOfMaterial[key])
                    .filter(
                      (x) =>
                        jobSummaryInfo &&
                        jobSummaryInfo.typeOfMaterial[key][x] == true
                    )
                    .map((nestedkey, j) => (
                      <span class="summary-label-span">
                        {j != 0 && <span>,&nbsp;&nbsp;&nbsp;</span>}{" "}
                        {getTypeOfMaterialOptionValue(key, nestedkey)}{" "}
                      </span>
                    ))}
                </div>
                <br></br>
              </div>
            ))}
          <br></br>
          <br></br>
        </div>
        <br></br>
        <br></br>
      </div>
      <div class="print-page-break" />
      <div class="col-lg-12 block-color-2">
        <div class="row block-header-summary-2">
          <div
            class="col-lg-10 text-left d-inline"
            style={{ paddingTop: "5px" }}
          >
            {" "}
            Product Info{" "}
          </div>
          {loggedUserInfo.role.toLowerCase() === "admin" && (
            <div
              class="col-lg-2 text-right d-inline"
              style={{ paddingRight: "0px" }}
            >
              <Button
                variant="contained"
                onClick={() => onSummaryEditClick(4)}
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<EditIcon />}
                style={{ margin: "0!important;" }}
              >
                EDIT
              </Button>
            </div>
          )}
        </div>
        <div class="row text-left" style={{ paddingLeft: "20px" }}>
          {
            jobSummaryInfo && jobSummaryInfo.productInfo && (
              // && Object.keys(jobSummaryInfo.productInfo).map((key,i) =>(
              //     <span>{jobSummaryInfo.productInfo[key] && <span>{key} : {jobSummaryInfo.productInfo[key]},&nbsp;&nbsp;&nbsp;</span>}</span>

              <div class="row text-left" style={{ paddingLeft: "20px" }}>
                <div class="col-lg-6" style={{ paddingTop: "8px" }}>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Manufacture:
                      <span class="summary-label-span">
                        {jobSummaryInfo.productInfo.manufacture}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      style:
                      <span class="summary-label-span">
                        {jobSummaryInfo.productInfo.style}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Color:
                      <span class="summary-label-span">
                        {jobSummaryInfo.productInfo.color}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Amount Ordered:
                      <span class="summary-label-span">
                        {jobSummaryInfo.productInfo.amtOrdered}
                      </span>
                    </InputLabel>
                  </div>
                </div>

                <div class="col-lg-6" style={{ paddingTop: "8px" }}>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      <span class="summary-label-blk">Transitions Needed</span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      End Cap:
                      <span class="summary-label-span">
                        {jobSummaryInfo.productInfo["endcap"]}
                      </span>{" "}
                      Length:
                      <span class="summary-label-span">
                        {jobSummaryInfo.productInfo["endcapLength"]}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      T-Molding:
                      <span class="summary-label-span">
                        {jobSummaryInfo.productInfo["tmolding"]}{" "}
                      </span>
                      Length:
                      <span class="summary-label-span">
                        {jobSummaryInfo.productInfo["tmoldingLength"]}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Reducer:
                      <span class="summary-label-span">
                        {jobSummaryInfo.productInfo["reducer"]}{" "}
                      </span>
                      Length:
                      <span class="summary-label-span">
                        {jobSummaryInfo.productInfo["reducerLength"]}
                      </span>
                    </InputLabel>
                  </div>
                </div>
                <div class="col-lg-6" style={{ paddingTop: "8px" }}>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      <span class="summary-label-blk">Stairs</span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Flush Mount:
                      <span class="summary-label-span">
                        {jobSummaryInfo.productInfo["flushMount"]}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Overlap:
                      <span class="summary-label-span">
                        {jobSummaryInfo.productInfo["overlap"]}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Tread-Wood/Lam:
                      <span class="summary-label-span">
                        {jobSummaryInfo.productInfo["treadWoodLam"]}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Tread-Rubber / Vinyl:
                      <span class="summary-label-span">
                        {jobSummaryInfo.productInfo["treadRubberVinyl"]}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Bullnose-Metal:
                      <span class="summary-label-span">
                        {jobSummaryInfo.productInfo["bullnoseMetal"]}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Bullnose-Rubber/Vinyl:
                      <span class="summary-label-span">
                        {jobSummaryInfo.productInfo["bullnoseRubberVinyl"]}
                      </span>
                    </InputLabel>
                  </div>
                </div>
                <div class="col-lg-6" style={{ paddingTop: "8px" }}>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      <span class="summary-label-blk">
                        Furniture Responsibility
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Osuna:
                      <span class="summary-label-span">
                        {jobSummaryInfo.productInfo.osuna ? "Yes" : "No"}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Customer:
                      <span class="summary-label-span">
                        {jobSummaryInfo.productInfo.customer ? "Yes" : "No"}
                      </span>
                    </InputLabel>
                  </div>
                </div>
                <div class="col-lg-6" style={{ paddingTop: "8px" }}>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      <span class="summary-label-blk">
                        Floor Preparation / Demo: Sub-Floor
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Concrete:
                      <span class="summary-label-span">
                        {jobSummaryInfo.productInfo.concrete ? "Yes" : "No"}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Wood:
                      <span class="summary-label-span">
                        {jobSummaryInfo.productInfo.wood ? "Yes" : "No"}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Elasticel:
                      <span class="summary-label-span">
                        {jobSummaryInfo.productInfo.elasticel ? "Yes" : "No"}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                    Existing Floor-Covering:
                    <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["existingFloorCoveringDemolition"]}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                    Scrape:
                    <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["scrape"]}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                    Grinding:
                    <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["grinding"]}
                    </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Floor Prep-Fill/Patch/Level:
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["floorPrepFillPatchLevel"]}
                      </span>
                    </InputLabel>
                  </div>

                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Amount of Demo (sq ft):
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["amountofDemo"]}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Wood Underlayment (sq ft):
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["woodUnderlaymentRequired"]}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                    Base:
                    <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["base"]}
                    </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      1/4 Round:
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["round"]}
                      </span>
                    </InputLabel>
                  </div>

                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Wood Species:
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["woodSpecies"]}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Paint/Stain Color:
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["paintStainColor"]}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Style/Dimensions:
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["styleDimensions"]}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Length:
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["woodSpecieslength"]}
                      </span>
                    </InputLabel>
                  </div>
                </div>
                <div class="col-lg-6" style={{ paddingTop: "8px" }}>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      <b>Top Set</b>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Vinyl or Rubber:
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["VinylRubber"]}
                      </span>
                      
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Color:
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["VinylRubberColor"]}
                      </span>
                      
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Size:
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["VinylRubberSize"]}
                      </span>
                      
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Length:
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["VinylRubberLength"]}
                      </span>
                      
                    </InputLabel>
                  </div>
                </div>

                <div class="col-lg-6" style={{ paddingTop: "8px" }}>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      <b>Appliances</b>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Fridge:
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["fridge"]}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Icemaker:
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["icemaker"]}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Dryer:
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["dryer"]}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Toilet(s):
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["toilets"]}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Other:
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["other"]}
                      </span>
                    </InputLabel>
                  </div>
                </div>
                <div class="col-lg-6" style={{ paddingTop: "8px" }}>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      <b>Utilities Available</b>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Electricity:
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo.electricity? "Yes" : "No"}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Running Water:
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo.runningwater? "Yes" : "No"}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Toilet:
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo.utilitytoilet? "Yes" : "No"}
                      </span>
                    </InputLabel>
                  </div>
                </div>
                <div class="col-lg-6" style={{ paddingTop: "8px" }}>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      <b>Other</b>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Other Comments / Instructions:
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["othercomments"]}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Customer Signature of Acceptance:
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["signature"]}
                      </span>
                    </InputLabel>
                  </div>
                  <div class="d-block">
                    <InputLabel class="summary-label" htmlFor="my-input">
                      Date:
                      <span class="summary-label-span">
                      {jobSummaryInfo.productInfo["pdate"]}
                      </span>
                    </InputLabel>
                  </div>
                </div>
              </div>
            )
            // ))
          }
        </div>
        <br></br>
        <br></br>
      </div>
      <div class="col-lg-12 block-color-1">
        <div class="row block-header-summary-2">
          <div
            class="col-lg-10 text-left d-inline"
            style={{ paddingTop: "5px" }}
          >
            {" "}
            Contractor & Installer{" "}
          </div>
          {loggedUserInfo.role.toLowerCase() === "admin" && (
            <div
              class="col-lg-2 text-right d-inline"
              style={{ paddingRight: "0px" }}
            >
              <Button
                variant="contained"
                onClick={() => onSummaryEditClick(5)}
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<EditIcon />}
                style={{ margin: "0!important;" }}
              >
                EDIT
              </Button>
            </div>
          )}
        </div>
      </div>
      <div class="col-lg-12 block-color-1" >
        <div class="col-lg-6" style={{ paddingTop: "8px" }}>
          <div class="d-block">
            <InputLabel class="summary-label" htmlFor="my-input">
              <span class="summary-label-blk">Contractor</span>
            </InputLabel>
          </div>
          <div class="d-block">
            <InputLabel class="summary-label" htmlFor="my-input">
              Constrator Name:
              <span class="summary-label-span">
                {getAssignedContractorName()}
              </span>
            </InputLabel>
          </div>
          <div class="d-block">
            <InputLabel class="summary-label" htmlFor="my-input">
              <span class="summary-label-blk">Installers:</span>
              {getAssignedInstallerNames().map(installerName=>
              <span style={{marginRight: 30}} class="summary-label-span">
                {installerName}
              </span>)}
            </InputLabel>
          </div>
          <div class="d-block">
            <InputLabel class="summary-label" htmlFor="my-input">
              Contractor Notes:
              <span class="summary-label-span">
                {jobSummaryInfo.contractorInstallerInfo
                  ? jobSummaryInfo.contractorInstallerInfo.contractorNotes
                  : ""}
              </span>
            </InputLabel>
          </div>
          <div class="d-block">
            <InputLabel class="summary-label" htmlFor="my-input">
              Installer Notes:
              <span class="summary-label-span">
                {jobSummaryInfo.contractorInstallerInfo
                  ? jobSummaryInfo.contractorInstallerInfo.installerNotes
                  : ""}
              </span>
            </InputLabel>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
    </div>
  );
};

export default JobView;

import { useState, useEffect } from "react";
import Stepper from "@material-ui/core/Stepper";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import CustomerInfo from "./customer-info";
import InitialSchedule from "./initial-schedule";
import TypeOfMaterial from "./type-of-material";
import MaterialPlacement from "./material-placement";
import { connect } from 'react-redux';
import JobSummary from './job-summary';
import ProductInfo from './product-info';
import ContratorInstaller from "./contrator-installer-assignment";
import AuthenticationHandler from './../shared/authentication-handler';
import {getLoggedInUserInfo} from './../shared/get-logged-user-detail';

function getSteps() {
  return ["Customer Info", "Initial Schedule & Construction Type", "Placement", "Type Of Material", "Product Info", "Contractor & Installer",  "Summary"];
}


function getStepContent(props) {
  const { step, isEditMode, onStepperNextClick, onStepperBackClick, onSummaryEditClick} =props;
  switch (step) {
    case 0:
      return (
        <div>
          <div class="col-lg-12 container-center mb-container-width" style={{ width: '80%' }} >
            <CustomerInfo isEditMode={isEditMode} onSummaryEditClick= { onSummaryEditClick} onStepperBackClick={onStepperBackClick}   onStepperNextClick={onStepperNextClick}/>
          </div>
        </div>
      )
    case 1:
      return (
        <div>
          <div class="col-lg-12 container-center mb-container-width" style={{ width: '80%'}} >
          <InitialSchedule isEditMode={isEditMode} onSummaryEditClick= { onSummaryEditClick} onStepperBackClick={onStepperBackClick}   onStepperNextClick={onStepperNextClick} />         
        </div>
        </div>
      )
    case 2:
      return (
        <div>
           <div class="col-lg-12 container-center mb-container-width" style={{ width: '80%' }} >
          <MaterialPlacement isEditMode={isEditMode} onSummaryEditClick= { onSummaryEditClick} onStepperBackClick={onStepperBackClick}   onStepperNextClick={onStepperNextClick} />
        </div>
        </div>
      )
      case 3:
      return (
        <div>
           <div class="col-lg-12 container-center mb-container-width" style={{ width: '80%' }} >
          <TypeOfMaterial isEditMode={isEditMode} onSummaryEditClick= { onSummaryEditClick} onStepperBackClick={onStepperBackClick}   onStepperNextClick={onStepperNextClick}/>
        </div>
        </div>
      )
    case 4:
      return (
        <div>
          <div class="col-lg-12 container-center mb-container-width" style={{ width: '80%' }} >
          <ProductInfo isEditMode={isEditMode} onSummaryEditClick= { onSummaryEditClick} onStepperBackClick={onStepperBackClick}   onStepperNextClick={onStepperNextClick} />
        </div>
        </div>
      )
    case 5:
        return (
          <div>
            <div class="col-lg-12 container-center mb-container-width" style={{ width: '80%'}} >
            <ContratorInstaller isEditMode={isEditMode} onSummaryEditClick= { onSummaryEditClick} onStepperBackClick={onStepperBackClick}   onStepperNextClick={onStepperNextClick} />
          </div>
          </div>
        )  
    default:
      return (
        <div>
          <div class="col-lg-12 container-center mb-container-width" style={{ width: '80%' }} >
          <JobSummary isEditMode={isEditMode} onSummaryEditClick= { onSummaryEditClick} onStepperBackClick={onStepperBackClick}   onStepperNextClick={onStepperNextClick} />
          </div>
        </div>
      )
  }
}
 function JobStepper(props) {
   const requestedStepperValue = props.location.stepperValue||0;
  const [activeStep, setActiveStep] = useState(requestedStepperValue);
  const [editMode, setEditMode] = useState(props.location.stepperValue|| props.location.stepperValue===0? true: false);
  const [displayJobSuccessfulSaveMsg, setDisplayJobSuccessfulSaveMsg]=useState(false);
  const { jobOrderDetail }= props;
  const steps = getSteps();


  const onStepperNextClick=()=>{    
    setActiveStep(activeStep + 1);
    setDisplayJobSuccessfulSaveMsg(true);
  }

  const onStepperBackClick=()=>{
    setActiveStep(activeStep - 1);
  }
  const onSummaryEditClick=(stepperValue, isEditMode=true)=>{
    setActiveStep(stepperValue);
    setEditMode(isEditMode);
  }
  
  const handleReset = () => {
    setActiveStep(0);
  };
  const handleSubmit = () => {
    props.history.push("./jobs");
  };
  return (
    <div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12" style={{ width: '95%', marginTop: '10px' }}> </div>
          <div id="mb-brd-pad" class="col-lg-12" style={{ width: '100%', paddingRight: '25px', paddingLeft: '25px' }}>
            <div id="mb-brd" class="col-lg-12" style={{ marginLeft: '-15px' }}>
              <div class="col-lg-2 pages-tabs">Jobs <span style={{color:'#B5B5B5'}}>&#10095;</span> Job Order</div>
              <div class="col-lg-10 "></div>
            </div>
            <div class="col-lg-12 pages-body">
              <div class="row">
                <div class="col-lg-12" style={{ marginBottom: '10px' }} >
                  {/* Content Area */}
                  
                  <div id="container-center-id" class="col-lg-12 container-center" style={{ width: '80%', marginTop: '10px', height:'60px' }}>
                  
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map(label => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                   
                  </div>
                  <div class="col-lg-12 container-center" style={{ width: '100%', marginTop: '10px', height:'20px' }}>
                  {displayJobSuccessfulSaveMsg && jobOrderDetail.jobId && <span class="msg-success">Your Job successfully saved with Job Id : {jobOrderDetail.jobId}</span>}
                  </div>
                 
                  <div class="col-lg-12 container-center" style={{ width: '100%', marginTop: '', height:'60px' }}>
                    {activeStep === steps.length ? (
                      <div>
                        <div  class="col-lg-12 container-center text-left container-stepper" style={{ width: '100%', padding: "10px" }} >
                        <div style={{textAlign:'center'}}>


                        <Button disabled={activeStep === 0} onClick={onStepperBackClick}>Back</Button>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>Done</Button>
                        </div>
                        </div>
                      </div>
                    ) : (
                        <div>
                          {getStepContent({step:activeStep, isEditMode:editMode, onStepperNextClick, onStepperBackClick, onSummaryEditClick})}
                         
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div></div></div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    jobOrderDetail: state.jobsDashboardReducer.jobOrderDetail || {},
    loggedUserInfo: getLoggedInUserInfo(state),
  };
};

export default withStyles(styles)(connect(mapStateToProps)(AuthenticationHandler(JobStepper)));

import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { styles}  from './styles';
import { useForm } from "react-hook-form";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
      display: 'flex',
      flexWrap: 'wrap',
  },
  margin: {
      margin: theme.spacing(1),
  },
  withoutLabel: {
      marginTop: theme.spacing(3),
  },
  textField: {
      width: '25ch',
  },
}));

const Forgotpassword=(props)=> {

  const classes = useStyles();

    const { register, handleSubmit, errors} = useForm({
        mode: "onChange"
      });

      const onSubmit = data => {
        console.log("register details:"+data.username);
        props.history.push("/reset");

       };
    return (
        <div>
          {/* <h4>If you have forgot User Name: Contact IT/Admin Team</h4>
          <br /><br />
        <h3>Forgot Password</h3><br />
        <form onSubmit={handleSubmit(onSubmit)}>
        <label>Enter User Name :
            <input type="text" name="username" ref={register({ required: true } )} /></label><br/>
            <input type="submit" value='Reset Password'/><br/>
        </form> */}

<div class="container login-container">
      <div class="row">
        <div class="col-md-12 login-form-1">
          <h3><span class="s-title2">Forgot Password</span></h3>
          <br></br>
          <div>
            <FormControl className={classNames(classes.margin)} variant="outlined" onSubmit={handleSubmit(onSubmit)}>
              <div class="form-group">
                <InputLabel htmlFor="outlined-adornment-password">User Name</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type='text'
                  name="username"
                  inputRef={register({ required: true })}
                  endAdornment={
                    <InputAdornment position="end">                     
                    </InputAdornment>
                  }
                  labelWidth={70}
                ></OutlinedInput><br />
                {errors.username && errors.username.type === "required" && <span>username is required</span>}
              </div>
            </FormControl>
            <br></br>
            <Link class="blackbtn logon-btn-space" component="button" variant="body2" type="submit" onClick={handleSubmit(onSubmit)} >Reset Password</Link>

          </div>
        </div>
      </div>
    </div>

        </div>
    );
  }
  export default withStyles(styles)(Forgotpassword);
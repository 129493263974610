import { combineReducers } from "redux";
import contractorReducer from "./contractor-management/ducks/reducer";
import installerReducer from "./installer-management/ducks/reducer";
import customerReducer from "./customer-management/ducks/reducer";
import userFormReducer from './user-form/ducks/reducer';
import jobsDashboardReducer from './dashboard/ducks/reducer';
import manageArchiveReducer from './manage-archives/ducks/reducer';

const masterReducer =  combineReducers({
  contractorReducer,
  installerReducer,
  customerReducer,
  userFormReducer,
  jobsDashboardReducer,
  manageArchiveReducer
  /* Add other reducers here */
});

export default masterReducer;
export const styles = {
    root: {
        flexGrow: 1,
    },
    toppaper: {
        padding: '2px 0px',
        textAlign: 'right',
        color: "red",
        backgroundColor:'#F1F1F1',
        borderRadius:'0px',
        height: '75px',
        paddingRight:'25px'   
    },
    toppapernav: {
        // padding: '20px',
        textAlign: 'right',
        color: "red",     
        backgroundColor:'#a01800',
        borderRadius:'0px',
        height:'35px',
        width:'100%',
        paddingRight:'25px'       
    },
   
    footerpaper: {
        padding: '13px',
        textAlign: 'center',
        color: "red",
        backgroundColor:'#2CA7E0',
        borderRadius:'0px',
        position: 'relative',
        bottom:'0',
        margin: '0 auto',
        width:'100%'
    },
    labelFont: {
        fontSize: '2em',
        fontWeight: 'bold'

    },
    button: {
        color: 'green'
    },

    divbackground: {
        backgroundColor: 'yellow'
    },
    headermain: {
        backgroundColor: 'yellow'
    },
    toppaperBtnGrp:
    {
        textAlign: 'right',
        marginTop:'20px'
    },
    textField: {
        width: '25ch',
      },
    signinblockWidth:{
        textAlign: 'center',
        width:'300px',
        height:'300px',
        marginTop:'100px',
        backgroundColor:'#F9F9F9',
        borderRadius:'20px',
        boderColor:'#4a4a4a',
        borderSize:'1px'    
   },
   activeclassname:{
    backgroundColor:'#F9F9F9',
   },

};
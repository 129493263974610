import { Hidden } from "@material-ui/core";

export const styles = {
    root: {
        flexGrow: 1,
    },
    body:
    {
        overflowY:"hidden"
    }
}
 
import Button from "@material-ui/core/Button";
const ActionCellRenderer = (props) => {
    const {contractorNotes, installerNotes} = props;
    
    const handleClickDelete = () => {
        const {api, context: { onDeleteClick }, data } = props;
        onDeleteClick({'data':data, 'contractorNotes':contractorNotes, 'installerNotes':installerNotes});
    }

    return (<div>       
        <Button  onClick={handleClickDelete}  variant="contained" color="primary" size="small" > Delete </Button>
    </div>);
}

export default ActionCellRenderer;
import { Button } from "@material-ui/core";

const UploadedFileactionCellRenderer = (props) => {
    const {beforeImages, afterImages} = props;
    
    const handleUploadedFileClickDelete = () => {
        const {api, context: { onDeleteUploadFileClick }, data } = props;
        onDeleteUploadFileClick({'data':data, 'beforeImages':beforeImages, 'afterImages':afterImages });
    }

    return (<div>       
        <Button  onClick={handleUploadedFileClickDelete}  variant="contained" color="primary" size="small" > Delete </Button>
    </div>);
}

export default UploadedFileactionCellRenderer;
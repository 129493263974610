import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    ON_VIEW_CUSTOMER_CLICK,
    ON_EDIT_CUSTOMER_CLICK,
    ON_DELETE_CUSTOMER_CLICK,
    ON_LIST_CUSTOMERS,
    ON_CUSTOMER_CREATE,
    ON_CUSTOMER_SAVE,
    SET_CUSTOMER_VIEW,
    SET_CUSTOMER_EDIT,
    SET_CUSTOMERS_LIST,
    ON_ERROR
} from './ducks/types';
import { fetchGet, fetchPost } from '../rest-api-client';
import configData from "./../config.json";

configData.SERVER_URL = process.env.REACT_APP_API_ENDPOINT;

/** View Customer Button Click */
function* onViewCustomerClick(action) {
    try {
        const { payload } = action;
        let customer = yield call(fetchGet, `${configData.SERVER_URL}/customer/get/${payload}`);

        if (!customer.error) {
            yield put({ type: SET_CUSTOMER_VIEW, payload: customer });
        } else {
            yield put({ type: ON_ERROR, payload: "Failed to load customer" });
        }
    } catch (e) {
    }
}

function* onViewCustomerClickWatcher() {
    yield takeLatest(ON_VIEW_CUSTOMER_CLICK, onViewCustomerClick)
}

/** Edit Customer Button Click */
function* onEditCustomerClick(action) {
    try {
        const { payload } = action;
        let customer = yield call(fetchGet, `${configData.SERVER_URL}/customer/get/${payload}`);
        if (!customer.error) {
            yield put({ type: SET_CUSTOMER_EDIT, payload: customer });
        } else {
            yield put({ type: ON_ERROR, payload: "Failed to load customer" });
        }
    } catch (e) {
    }
}

function* onEditCustomerClickWatcher() {
    yield takeLatest(ON_EDIT_CUSTOMER_CLICK, onEditCustomerClick)
}

/** Delete Customer Button Click */
function* onDeleteCustomerClick(action) {
    try {
        const { payload } = action;
        let result = yield call(fetchPost, `${configData.SERVER_URL}/customer/delete/${payload}`);
        if (result.error) {
            yield put({ type: ON_ERROR, payload: "Failed to delete customer" });
        }
    } catch (e) {
    }
}

function* onDeleteCustomerClickWatcher() {
    yield takeLatest(ON_DELETE_CUSTOMER_CLICK, onDeleteCustomerClick)
}

/** Load Customers */
function* onListCustomers() {
    try {
        let customers = yield call(fetchGet, `${configData.SERVER_URL}/customer/list`);
        yield put({ type: SET_CUSTOMERS_LIST, payload: customers && customers.length > 0 ? customers : [] });
    } catch (e) {
    }
}

function* onListCustomersWatcher() {
    yield takeEvery(ON_LIST_CUSTOMERS, onListCustomers)
}

/** Create Customer */
function* onCustomerCreate(action) {
    try {
        const { payload } = action;
        let customer = {
            companyName: payload.companyName,
            firstName: payload.firstName,
            lastName: payload.lastName,
            email: payload.email,
            phone: payload.phone,
            address: {
                addressLine1: payload.addressLine1,
                addressLine2: payload.addressLine2,
                city: payload.city,
                state: payload.state,
                zipCode: payload.zipCode
            }
        };
        let result = yield call(fetchPost, `${configData.SERVER_URL}/customer/create`, customer);

        if (result !== "success") {
            yield put({ type: ON_ERROR, payload: "Failed to create customer" });
        }
    } catch (e) {
    }
}

function* onCustomerCreateWatcher() {
    yield takeLatest(ON_CUSTOMER_CREATE, onCustomerCreate)
}

/** Save Customer */
function* onCustomerSave(action) {
    try {
        const { payload } = action;
        let customer = {
            _id: payload._id,
            companyName: payload.companyName,
            firstName: payload.firstName,
            lastName: payload.lastName,
            email: payload.email,
            phone: payload.phone,
            address: {
                addressLine1: payload.addressLine1,
                addressLine2: payload.addressLine2,
                city: payload.city,
                state: payload.state,
                zipCode: payload.zipCode
            }
        };
        let result = yield call(fetchPost, `${configData.SERVER_URL}/customer/update`, customer);

        if (result !== "success") {
            yield put({ type: ON_ERROR, payload: "Failed to create customer" });
        }
    } catch (e) {
    }
}

function* onCustomerSaveWatcher() {
    yield takeLatest(ON_CUSTOMER_SAVE, onCustomerSave)
}

/** Export Watchers */
const customerManagementWatchers = [
    onViewCustomerClickWatcher,
    onEditCustomerClickWatcher,
    onDeleteCustomerClickWatcher,
    onListCustomersWatcher,
    onCustomerCreateWatcher,
    onCustomerSaveWatcher
];

export default customerManagementWatchers;
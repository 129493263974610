import React, { useEffect, useState } from "react";
import UserManagementForm from "./../user-form/user-management";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import { connect } from "react-redux";
import { onLoadAccounts } from "./ducks/actions";
import AuthenticationHandler from './../shared/authentication-handler';
import {getLoggedInUserInfo} from './../shared/get-logged-user-detail';

const UserLayout = (props) => {

    const { loggedUserInfo, loadAccountsAction } = props;

    useEffect(() => {
        if (loggedUserInfo.role === "admin") {
            loadAccountsAction();
        } 
    }, []);

    return (
        <div class="container-fluid">
            <div class="row">
                <div class="col">
                    <UserManagementForm />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      loggedUserInfo: getLoggedInUserInfo(state),
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        loadAccountsAction: (payload) => dispatch(onLoadAccounts(payload))
    };
  };
  
  export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(AuthenticationHandler(UserLayout))
  );

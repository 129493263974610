import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import HeaderPage from './header-page';
import AppFooter from './footer-page';
import { Container } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const MasterLayout = (props) => {
  const { classes: { root, labelFont, footerpaper } } = props;
  return (
    <div className="App">
      {/* header page */}
      <HeaderPage />
      {/* body page */}
      <div style={{minHeight: '80vh'}}>
         {props.children}
      </div>
      {/* Footer page */}
      <AppFooter />
    </div>

  );

}

export default withStyles(styles)(MasterLayout);
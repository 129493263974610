import React, { useEffect, useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import ActionCellRenderer from "../grid-cell-renderers/action-cell-renderer";
import JobIdLinkCellRenderer from "../grid-cell-renderers/job-id-link-cell-renderer";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { onSelectJob, onCreateNewJob } from "./ducks/actions";
import Link from "@material-ui/core/Link";
import AddIcon from "@material-ui/icons/Add";
import ClearAllIcon from '@material-ui/icons/ClearAll';
import GetAppIcon from '@material-ui/icons/GetApp';
import {getLoggedInUserInfo} from './../shared/get-logged-user-detail';

const getCustomerName = (params) => {
  const customerInfo = params.data.customerInfo;
  if (customerInfo) {
    return `${customerInfo.lastName}, ${customerInfo.firstName}`;
  }
  return "";
};

const getCustomerPhone = (params) => {
  const customerInfo = params.data.customerInfo;
  if (customerInfo) {
    return customerInfo.phone;
  }
  return "";
};
const getInstallationAddress = (params) => {
  const customerInfo = params.data.customerInfo;
  if (customerInfo && customerInfo.address ) {
    return `${customerInfo.address.addressLine1}, ${customerInfo.address.city}, Zipcode: ${customerInfo.address.zipCode}`;
  }
  return "";
};

// const getJobDescription = (params) => {
//   const customerInfo = params.data.customerInfo;
//   if (customerInfo) {
//     return customerInfo.description;
//   }
//   return "";
// };

const getContractorNotes = (params) => {
  const contractorInstallerInfo = params.data.contractorInstallerInfo;
  if (contractorInstallerInfo) {
    return contractorInstallerInfo.contractorNotes;
  }
  return "";
};

const getConfirmInstallationDate = (params) => {
  const initialScheduleInfo = params.data.initialScheduleInfo;
  if (initialScheduleInfo) {
    let date = new Date(Date.parse(initialScheduleInfo.cidate));
    var options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };
    var formattedDate = date.toLocaleString('en-US', options);
    return `${formattedDate}`;
  }

  return "";
};

var dateFilterParams = {
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var cellDate = new Date(Date.parse(`${cellValue}`));

    let date1 = new Date(filterLocalDateAtMidnight.getFullYear(), filterLocalDateAtMidnight.getMonth(), filterLocalDateAtMidnight.getDate());
    let date2 = new Date(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate());

    if (date1.getTime() === date2.getTime()) {
      return 0;
    }
    if (date2 < date1) {
      return -1;
    }
    if (date2 > date1) {
      return 1;
    }
  },
  browserDatePicker: true,
  minValidYear: 2000,
};

const getInstallerNameAndPhone = (params) => {
  if (params.data && params.data.contractorInstallerInfo && params.data && params.data.contractorInstallerInfo.selectedInstallerList) {
    return params.data.contractorInstallerInfo.selectedInstallerList.reduce(
      (a, i) =>
        (a ? a + "," : "") +
        i.firstName +
        " " +
        i.lastName +
        " (PH: " +
        i.phone +
        ")\n",
      ""
    );
  }
  return "";
};

const getContractorName = (params) => {
  if (params.data && params.data.contractorInstallerInfo && params.data.contractorInstallerInfo.contractorInfo) {
    return params.data.contractorInstallerInfo.contractorInfo.firstName + " " + params.data.contractorInstallerInfo.contractorInfo.lastName;
  }
  return "";
};

const getJobHold = (params) => {
  const isHold = params.data.hold;
  return isHold ? "YES" : "NO";
};

const getColumnDefs = () => {
  const columnDefs = [
    {
      headerName: "Job Order ID",
      field: "jobId",
      filter: "agTextColumnFilter",
      width: 150,
      suppressMenu: true,
      cellRenderer: "jobIdLinkCellRenderer",
    },
    {
      headerName: "Customer",
      filter: "agTextColumnFilter",
      valueGetter: getCustomerName,
    },
    {
      headerName: "Customer Phone",
      filter: "agTextColumnFilter",
      valueGetter: getCustomerPhone,
    },
    {
      headerName: "Schedule",
      valueGetter: getConfirmInstallationDate,
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams
    },
    {
      headerName: "Installation Address",
      filter: "agTextColumnFilter",
      valueGetter: getInstallationAddress,
    },
    {
      headerName: "Contractor",
      field: "contractorId",
      filter: "agTextColumnFilter",
      suppressMenu: true,
      valueGetter: getContractorName,
    },
    {
      headerName: "Installer Name & Phone",
      filter: "agTextColumnFilter",
      valueGetter: getInstallerNameAndPhone,
      suppressMenu: true,
    },
    {
      headerName: "Job Description",
      //valueGetter: getJobDescription,
      field : "jobDescription",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Contractor Notes",
      valueGetter: getContractorNotes,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Job Hold?",
      valueGetter: getJobHold,
      suppressMenu: true,
      filter: true,
      width: 120,
      cellStyle: (params) => {
        if (params.data.hold) {
          return { color: "brown" };
        }
      },
    },
  ];
  return columnDefs;
};

const frameworkComponents = {
  actionCellRenderer: ActionCellRenderer,
  jobIdLinkCellRenderer: JobIdLinkCellRenderer,
};

const defaultColDef = {
  editable: true,
  sortable: true,
  floatingFilter: true,
  resizable: true,
};

const AgGridJobs = (props) => {
  const { loggedUserInfo, jobsList, onCreateNewJobAction } = props;
  const [gridApi, setGridApi] = useState(null);

  const onJobSelectClickHandler = (data) => {
    props.OnJobSelectAction(data);
    props.history.push("/job-detailed-view");
  };

  const onDeleteClickHandler = (data) => {
    alert("I am from ag-grid for Deleting..." + data);
  };

  const context = {
    onJobSelectClickHandler: onJobSelectClickHandler,
    onDeleteClick: onDeleteClickHandler,
  };

  const sortingOrder = ["desc", "asc", null];

  function onGridReady(params) {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  }

  const clearFiltersClickHandler = () => {
    if (gridApi) {
      gridApi.setFilterModel(null);
    }
  }

  const handleClickJobsform = () => {
    onCreateNewJobAction();
    props.history.push("./jobs-form");
  };  

  /** Export Data */
  const exportDataClickHandler = () => {
      if (gridApi) {
          gridApi.exportDataAsCsv({
              fileName: 'Jobs'
          });
      }
  }

  return (
    <div>
      <div class="row">
        {loggedUserInfo.role.toLowerCase() === "admin" && (
          <div class="col action-row" >
            <span >
              <Link class="blackbtn logon-btn-space float-right" component="button" variant="body2" onClick={exportDataClickHandler}>Export Data<GetAppIcon /></Link>
              <Link class="blackbtn logon-btn-space float-right" component="button" variant="body2" onClick={clearFiltersClickHandler}>Clear Filters<ClearAllIcon /></Link>
              <Link class="blackbtn logon-btn-space float-right" component="button" variant="body2" onClick={handleClickJobsform}><AddIcon /> Add Job Order</Link>
            </span>
          </div>
        )}
      </div>
      <div
        className="ag-theme-alpine"
        style={{ height: 600, position: "relative" }}
      >
        <AgGridReact
          pagination={true}
          paginationPageSize={10}
          enableFilter={true}
          defaultColDef={defaultColDef}
          frameworkComponents={frameworkComponents}
          sortingOrder={sortingOrder}
          columnDefs={getColumnDefs()}
          rowData={jobsList}
          animateRows={true}
          context={context}
          onGridReady={onGridReady}
        ></AgGridReact>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    jobsList: state.jobsDashboardReducer.jobsList || [],
    loggedUserInfo: getLoggedInUserInfo(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    OnJobSelectAction: (payload) => dispatch(onSelectJob(payload)),
    onCreateNewJobAction: () => dispatch(onCreateNewJob())
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(AgGridJobs))
);

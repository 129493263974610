import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { onContractorCreate, onContractorSave } from './ducks/actions';
import { Alert } from '@material-ui/lab';
import { AgGridReact } from 'ag-grid-react';
import { MenuItem } from "@material-ui/core";
import { onLoadJobsCountryStates } from "../dashboard/ducks/actions";
import {getLoggedInUserInfo} from './../shared/get-logged-user-detail';

/** Contractor Form */
const ContractorForm = (props) => {

  /** State & Event Handler from Properties */
  const { loggedUserInfo, currentContractor, currentAction, errorMessage, countryStatesInfo, contractorCreateAction, contractorSaveAction, countryStatesAction } = props;
  const [installers, setInstallers] = useState(currentContractor.installers);
  const [jobs, setJobs] = useState(currentContractor.jobs);

  /** User form handler for submit action */
  const { control, register, errors, handleSubmit, getValues, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "CA",
      zipCode: ""
    }
  });

  /** Inital Load Action */
  useEffect(() => {
    if (loggedUserInfo.role !== "admin") {
      props.history.push('/login');
    }
  }, [props, loggedUserInfo]);

  /** Load States Info */
  useEffect(() => {
    countryStatesAction();
  }, []);

  const states  = countryStatesInfo;

  /** Update Current Contractor */
  useEffect(() => {
    if (currentContractor) {
      if (currentContractor._id) {
        setValue("_id", currentContractor._id);
      }
      setValue("firstName", currentContractor.firstName);
      setValue("lastName", currentContractor.lastName);
      setValue("email", currentContractor.email);
      setValue("phone", currentContractor.phone);
      setValue("companyName", currentContractor.companyName);
      setValue("addressLine1", currentContractor.address ? currentContractor.address.addressLine1 : '');
      setValue("addressLine2", currentContractor.address ? currentContractor.address.addressLine2 : '');
      setValue("city", currentContractor.address ? currentContractor.address.city : '');
      setValue("state", currentContractor.address ? currentContractor.address.state : '');
      setValue("zipCode", currentContractor.address ? currentContractor.address.zipCode : '');
      setInstallers(currentContractor.installers);
      setJobs(currentContractor.jobs);
    }
  }, [currentContractor, setValue]);



  /** Save Handler for Create / Update */
  const onSave = data => {
    if (currentAction === "CREATE") {
      contractorCreateAction(data);
      props.history.push("/contractor");
    } else if (currentAction === "EDIT") {
      contractorSaveAction(data);
      props.history.push("/contractor");
    }
  };

  /** Cancel Event Handler */
  const onCancel = () => {
    props.history.push("/contractor");
  }

  /** Installer Grid Configuration */
  const addressFormatter = (params) => {
    if (params && params.data) {
      const address = params.data.address;
      if (address) {
          return `${address.addressLine1}, ${address.city}, ${address.zipCode}`;
      } else  {
          return '';
      }
  }
}

  const installerGridOptions = {
    columnDefs: [
      { headerName: "First Name", field: "firstName", filter: "agTextColumnFilter" },
      { headerName: "Last Name", field: "lastName", filter: "agTextColumnFilter" },
      { headerName: "Email", field: "email", filter: 'agTextColumnFilter' },
      { headerName: "Phone", field: "phone", filter: "agTextColumnFilter" },
      { headerName: "Home Address", field: "address", filter: 'agTextColumnFilter', valueGetter: addressFormatter }
    ],
    defaultColDef: {
      sortable: true,
      resizable: true,
      flex: 1
    },
    sortingOrder: ['desc', 'asc', null],
    animateRows: true,
    domLayout: 'autoHeight'
  };

  const onInstallerGridReady = (params) => {
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  }

  /** Job Grid Configuration */
  const getInstallationDate = (params) => {
    const installationDate = params.data.installationDate;
    if (installationDate) {
      let date = new Date(Date.parse(installationDate));
      var options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      };
      var formattedDate = date.toLocaleString('en-US', options);
      return `${formattedDate}`;
    }
  
    return "";
  };

  const jobGridOptions = {
    columnDefs: [
      { headerName: "Job ID", field: "jobId" },
      { headerName: "Installation Date", field: "installationDate", valueGetter: getInstallationDate },
      { headerName: "Status", field: "status" }
    ],
    defaultColDef: {
      sortable: true,
      resizable: true,
      flex: 1
    },
    sortingOrder: ['desc', 'asc', null],
    pageSize: 10,
    pagination: true,
    animateRows: true,
    domLayout: 'autoHeight'
  };

  const onJobGridReady = (params) => {
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  }

  /** Renderer */
  return (
    <div className="contractor-form">
      {errorMessage &&
        <Alert severity="error" style={{ width: '100%', textAlign: 'center' }}>
          <strong>{errorMessage}</strong>
        </Alert>
      }
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12" style={{ width: '95%', marginTop: '20px' }}> </div>
          <div class="col-lg-12" style={{ width: '100%', paddingRight: '25px', paddingLeft: '25px' }}>
            <div class="col-lg-12" style={{ marginLeft: '-15px' }}>
              <div class="col-lg-4 pages-tabs">Contractors <span style={{ color: '#B5B5B5' }}>{'>'}</span> {currentAction === 'VIEW' && 'View Contractor'} {currentAction === 'CREATE' && 'Create Contractor'} {currentAction === 'EDIT' && 'Edit Contractor'}</div>
              <div class="col-lg-8"></div>
            </div>
            <div class="col-lg-12 pages-body">
              <div class="row">
                <div class="col-lg-12" style={{ marginBottom: '10px' }} >
                  <form onSubmit={handleSubmit(onSave)}>
                    <input type="hidden" ref={register} name="_id" />

                    <div class="row">
                      <div class="col d-inline">
                        <Controller as={<TextField margin="dense" label="First Name" type="string" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} rules={{ required: true }} name="firstName" />
                        {errors.firstName && errors.firstName.type === "required" && <span class="validation-error">*First Name is required</span>}
                      </div>
                      <div class="col d-inline">
                        <Controller as={<TextField margin="dense" label="Last Name" type="string" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} rules={{ required: true }} name="lastName" />
                        {errors.lastName && errors.lastName.type === "required" && <span class="validation-error">*Last Name is required</span>}
                      </div>
                      <div class="col d-inline">
                        <Controller as={<TextField margin="dense" label="Email" type="email" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control}
                          rules={{
                            required: true,
                            validate: () => { return !getValues("email") || new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i).test(getValues("email")) }
                          }}
                          name="email" />
                        {errors.email && errors.email.type === "required" && <span class="validation-error">*Email is required</span>}
                        {errors.email && errors.email.type === "validate" && <span class="validation-error">*Please enter valid email address</span>}
                      </div>
                      <div class="col d-inline">
                        <Controller as={<TextField margin="dense" label="Phone" type="tel" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} rules={{ required: true }} name="phone" />
                        {errors.phone && errors.phone.type === "required" && <span class="validation-error">*Phone Number is required</span>}
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <Controller as={<TextField margin="dense" label="Company Name" type="string" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} rules={{ required: true }} name="companyName" />
                        {errors.companyName && errors.companyName.type === "required" && <span class="validation-error">*Company Name is required</span>}
                      </div>
                    </div>

                    <div class="row">
                      <div class="col d-inline">
                        <Controller as={<TextField margin="dense" label="Address Line 1" type="string" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} rules={{ required: true }} name="addressLine1" />
                      </div>
                      <div class="col d-inline">
                        <Controller as={<TextField margin="dense" label="Address Line 2" type="string" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} name="addressLine2" />
                      </div>
                      <div class="col d-inline">
                        <Controller as={<TextField margin="dense" label="City" type="string" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} rules={{ required: true }} name="city" />
                      </div>
                      <div class="col d-inline">
                        <Controller as={
                          <TextField margin="dense" label='State' fullWidth select InputProps={{ readOnly: currentAction === 'VIEW' }} onChange={e => setValue('state', e.target.value, true)} >
                            {states.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {`${option.name} (${option.id})`}
                              </MenuItem>
                            ))}
                          </TextField>} control={control} rules={{ required: true }} name="state" />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-3 d-inline">
                        <Controller as={<TextField margin="dense" label="Zip Code" type="string" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} rules={{ required: true }} name="zipCode" />
                      </div>
                    </div>

                    {currentAction === 'VIEW' &&
                      <fieldset>
                        <legend>Installer Details</legend>
                        <div class="row">
                          <div class="col">
                            <div className="ag-theme-alpine ag-theme-alpine-secondary">
                              <AgGridReact
                                domLayout={installerGridOptions.domLayout}
                                animateRows={installerGridOptions.animateRows}
                                defaultColDef={installerGridOptions.defaultColDef}
                                columnDefs={installerGridOptions.columnDefs}
                                sortingOrder={installerGridOptions.sortingOrder}
                                rowData={installers}
                                onGridReady={onInstallerGridReady}>
                              </AgGridReact>
                            </div>
                          </div>
                        </div>
                      </fieldset>}

                    {currentAction === 'VIEW' &&
                      <fieldset>
                        <legend>Job Details</legend>
                        <div class="row">
                          <div class="col">
                            <div className="ag-theme-alpine ag-theme-alpine-secondary">
                              <AgGridReact
                                domLayout={jobGridOptions.domLayout}
                                pagination={jobGridOptions.pagination}
                                paginationPageSize={jobGridOptions.pageSize}
                                animateRows={jobGridOptions.animateRows}
                                defaultColDef={jobGridOptions.defaultColDef}
                                columnDefs={jobGridOptions.columnDefs}
                                sortingOrder={jobGridOptions.sortingOrder}
                                rowData={jobs}
                                onGridReady={onJobGridReady}>
                              </AgGridReact>
                            </div>
                          </div>
                        </div>
                      </fieldset>}

                    <div class="row action-row">
                      <div class="col">
                      <Button variant="contained" color="primary" onClick={onCancel}>{currentAction === 'VIEW' && 'Back'} {currentAction === 'CREATE' && 'Cancel'} {currentAction === 'EDIT' && 'Cancel'}</Button>
                        {currentAction === 'CREATE' && <Button variant="contained" color="primary" type="submit">Create</Button>}
                        {currentAction === 'EDIT' && <Button variant="contained" color="primary" type="submit">Save</Button>}
                       
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

/** Get State from Reducers & map to Contractor Form */
const mapStateToProps = state => {
  return {
    loggedUserInfo: getLoggedInUserInfo(state),
    currentContractor: state.contractorReducer.currentContractor || {},
    currentAction: state.contractorReducer.currentAction || 'VIEW',
    errorMessage: state.contractorReducer.errorMessage || '',
    countryStatesInfo: state.jobsDashboardReducer.countryList || []
  }
}

/** Attach actions to Contractor Form */
const mapDispatchToProps = dispatch => {
  return {
    contractorCreateAction: (payload) => dispatch(onContractorCreate(payload)),
    contractorSaveAction: (payload) => dispatch(onContractorSave(payload)),
    countryStatesAction: (payload) => dispatch(onLoadJobsCountryStates(payload))
  }
}

/** Contractor Form */
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ContractorForm));
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    ON_LOAD_JOBS, SET_JOBS_LIST, ON_SAVE_JOB_FAILURE,
    ON_LOAD_JOB_COUNTRY_STATES, SET_JOB_COUNTRY_STATES_LIST, SET_JOB_TYPES_OF_MATERIAL,
    SET_COMPLETE_JOB_DETAILS, ON_SAVE_JOB_INFO, ON_LOAD_CONTRACTORS_LIST, SET_CONTRACTORS_LIST,
    ON_DELETE_JOB_CLICK, ON_ARCHIVE_JOB_CLICK, ON_LOAD_ACCOUNTS, SET_ACCOUNTS_LIST,
    ON_LOAD_INSTALLER_LIST_BY_CONTRACTOR, SET_INSTALLER_LIST_FOR_CONTRACTOR,
    ON_SELECT_JOB,ON_HOLD_STATUS_CHANGE_JOB, ON_DELETE_UPLOAD
} from './ducks/types';
import { fetchGet, fetchPost } from './../rest-api-client';
import configData from "./../config.json";

export const getCredentials = (state) => state.userFormReducer.credentials;

function* onLoadJobs(action) {
    try {
        const { payload } = action;
        const inputParams ={userId:payload.userId, includeArchive: payload.includeArchive};
        let jobs = yield call(fetchGet, `${configData.SERVER_URL}/job/load`, inputParams);
        yield put({ type: SET_JOBS_LIST, payload: jobs });

    } catch (e) {
        console.log('error on loading the jobs');
    }
}

function* onLoadJobsCountryStates(action) {
    try {
        const { payload } = action;
        let jobs = yield call(fetchGet, `${configData.SERVER_URL}/states`);
        yield put({ type: SET_JOB_COUNTRY_STATES_LIST, payload: jobs });

    } catch (e) {
        console.log('error on loading the jobs country states');
    }
}

function* onLoadJobsTypesOfMaterial(action) {
    try {
        const { payload } = action;
        let jobs = yield call(fetchGet, `${configData.SERVER_URL}/typeOfMaterial`);
        yield put({ type: SET_JOB_TYPES_OF_MATERIAL, payload: jobs });

    } catch (e) {
        console.log('error on loading the jobs');
    }
}
function* onSaveJobInfo(action) {
    try {
        const { payload } = action;
        let jobInfo = yield call(fetchPost, `${configData.SERVER_URL}/job/upsert`, payload);
        if (jobInfo.jobId) {
            yield put({ type: ON_SELECT_JOB, payload: jobInfo });
        }
        else {
            const failureMessage = jobInfo.error || "Failed to insert/update job info";
            yield put({ type: ON_SAVE_JOB_FAILURE, payload: failureMessage });
        }
    } catch (e) {
        console.log('error in insert/update job info');
    }
}
function* onDeleteUpload(action) {
    try {
        const { payload } = action;
        let response = yield call(fetchPost, `${configData.SERVER_URL}/uploads`, payload);
        if (response) {
            yield put({ type: ON_SELECT_JOB, payload: payload });
        }
        else {
            const failureMessage = response.error || "Failed to insert/update job info";
            yield put({ type: ON_SAVE_JOB_FAILURE, payload: failureMessage });
        }
    } catch (e) {
        console.log('error in insert/update job info');
    }
}


function* onLoadContractors(action) {
    try {
        const { payload } = action;
        let contractors = yield call(fetchGet, `${configData.SERVER_URL}/contractor/select`);
        yield put({ type: SET_CONTRACTORS_LIST, payload: contractors });

    } catch (e) {
        console.log('error on loading the contractors');
    }
}


/** Delete Job Button Click */
function* onDeleteJobClick(action) {
    try {
        const { payload } = action;
        let result = yield call(fetchPost, `${configData.SERVER_URL}/job/delete/${payload}`);
        if (result.error) {
            yield put({ type: ON_SAVE_JOB_FAILURE, payload: "Failed to delete Job information" });
        }
    } catch (e) {
    }
}

/** Archive Job Button Click */
function* onArchiveJobClick(action) {
    try {
        const { payload } = action;
        let result = yield call(fetchPost, `${configData.SERVER_URL}/job/archive/${payload}`);
        if (result.error) {
            yield put({ type: ON_SAVE_JOB_FAILURE, payload: "Failed to archive Job information" });
        }
    } catch (e) {
    }
}

/** Load Accounts */
function* onLoadAccounts() {
    try {
        let accounts = yield call(fetchGet, `${configData.SERVER_URL}/user/list`);
        yield put({ type: SET_ACCOUNTS_LIST, payload: accounts });

    } catch (e) {
        console.log('error on loading the accounts');
    }
}
function* onLoadInstallerListByContractorId(action){
    try {
        
        const contractorId= action.payload;
        let installers = yield call(fetchGet, `${configData.SERVER_URL}/installer/select/${contractorId}`);
        yield put({ type: SET_INSTALLER_LIST_FOR_CONTRACTOR, payload: installers });

    } catch (e) {
        console.log('error on loading the installers');
    }
}

function* onSelectJob(action) {
    const { payload } = action;
    try {
        
        let jobInfo = yield call(fetchGet, `${configData.SERVER_URL}/job/get/${payload.jobId}`);
        if (jobInfo.jobId) {
            yield put({ type: SET_COMPLETE_JOB_DETAILS, payload: jobInfo });
        }
    } catch (e) {
        console.log('error loading job id:', payload.jobId);
    }
}

function* onHoldStatusChangeJob(action){
    const { payload } = action;
    try {
        
       yield call(fetchPost, `${configData.SERVER_URL}/job/hold`, payload);       
       yield put({ type: ON_SELECT_JOB, payload: {jobId: payload.jobId} });
      
    } catch (e) {
        console.log('error loading job id:', payload.jobId);
    }
}

function* onLoadJobsWatcher() {
    yield takeEvery(ON_LOAD_JOBS, onLoadJobs);
    yield takeEvery(ON_SAVE_JOB_INFO, onSaveJobInfo);  
    yield takeEvery(ON_LOAD_JOB_COUNTRY_STATES, onLoadJobsCountryStates);
    yield takeEvery(ON_LOAD_CONTRACTORS_LIST, onLoadContractors);
    yield takeLatest(ON_DELETE_JOB_CLICK, onDeleteJobClick);
    yield takeLatest(ON_ARCHIVE_JOB_CLICK, onArchiveJobClick);
    yield takeLatest(ON_LOAD_ACCOUNTS, onLoadAccounts);
    yield takeLatest(ON_LOAD_INSTALLER_LIST_BY_CONTRACTOR, onLoadInstallerListByContractorId);    
    yield takeLatest(ON_SELECT_JOB, onSelectJob);
    yield takeLatest(ON_HOLD_STATUS_CHANGE_JOB, onHoldStatusChangeJob);
    yield takeEvery(ON_DELETE_UPLOAD, onDeleteUpload);  
}

export default [onLoadJobsWatcher];
const FileNameLinkCellRenderer = (props) => {

    const handleFileSelection = () => {
        const {api, context: { onFileNameSelectClickHandler }, data } = props;
        onFileNameSelectClickHandler(data);
    }  

    return (<div>        
          <span onClick={handleFileSelection} style={{textDecoration: 'underline', cursor:'pointer', color:'blue'}}>{props.value}</span>
    </div>);
}

export default FileNameLinkCellRenderer;
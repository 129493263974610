import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    ON_VIEW_INSTALLER_CLICK,
    ON_EDIT_INSTALLER_CLICK,
    ON_DELETE_INSTALLER_CLICK,
    ON_LIST_INSTALLERS,
    ON_INSTALLER_CREATE,
    ON_INSTALLER_SAVE,
    SET_INSTALLER_VIEW,
    SET_INSTALLER_EDIT,
    SET_INSTALLERS_LIST,
    ON_LOAD_CONTRACTORS_DROPDOWN_VALUES,
    SET_CONTRACTORS_DROPDOWN_VALUES,
    ON_ERROR
} from './ducks/types';
import { fetchGet, fetchPost } from '../rest-api-client';
import configData from "./../config.json";

configData.SERVER_URL = process.env.REACT_APP_API_ENDPOINT;

/** Load contractors dropdown values */
function* onLoadContractorsDropdownValues(action) {
    try {
        let result = yield call(fetchGet, `${configData.SERVER_URL}/contractor/select`);

        if (!result.error) {
            yield put({ type: SET_CONTRACTORS_DROPDOWN_VALUES, payload: result });
        } else {
            yield put({ type: ON_ERROR, payload: "Failed to load contractors" });
        }
    } catch (e) {
    }
}

function* onLoadContractorsDropdownValuesWatcher() {
    yield takeLatest(ON_LOAD_CONTRACTORS_DROPDOWN_VALUES, onLoadContractorsDropdownValues)
}

/** View Installer Button Click */
function* onViewInstallerClick(action) {
    try {
        const { payload } = action;
        let installer = yield call(fetchGet, `${configData.SERVER_URL}/installer/get/${payload}`);

        if (!installer.error) {
            yield put({ type: SET_INSTALLER_VIEW, payload: installer });
        } else {
            yield put({ type: ON_ERROR, payload: "Failed to load installer" });
        }
    } catch (e) {
    }
}

function* onViewInstallerClickWatcher() {
    yield takeLatest(ON_VIEW_INSTALLER_CLICK, onViewInstallerClick)
}

/** Edit Installer Button Click */
function* onEditInstallerClick(action) {
    try {
        const { payload } = action;
        let installer = yield call(fetchGet, `${configData.SERVER_URL}/installer/get/${payload}`);
        if (!installer.error) {
            yield put({ type: SET_INSTALLER_EDIT, payload: installer });
        } else {
            yield put({ type: ON_ERROR, payload: "Failed to load installer" });
        }
    } catch (e) {
    }
}

function* onEditInstallerClickWatcher() {
    yield takeLatest(ON_EDIT_INSTALLER_CLICK, onEditInstallerClick)
}

/** Delete Installer Button Click */
function* onDeleteInstallerClick(action) {
    try {
        const { payload } = action;
        let result = yield call(fetchPost, `${configData.SERVER_URL}/installer/delete/${payload}`);
        if (result.error) {
            yield put({ type: ON_ERROR, payload: "Failed to delete installer" });
        }
    } catch (e) {
    }
}

function* onDeleteInstallerClickWatcher() {
    yield takeLatest(ON_DELETE_INSTALLER_CLICK, onDeleteInstallerClick)
}

/** Load Installers */
function* onListInstallers() {
    try {
        let installers = yield call(fetchGet, `${configData.SERVER_URL}/installer/list`);
        yield put({ type: SET_INSTALLERS_LIST, payload: installers && installers.length > 0 ? installers : [] });
    } catch (e) {
    }
}

function* onListInstallersWatcher() {
    yield takeEvery(ON_LIST_INSTALLERS, onListInstallers)
}

/** Create Installer */
function* onInstallerCreate(action) {
    try {
        const { payload } = action;
        let installer = {
            contractorId: payload.contractorId,
            firstName: payload.firstName,
            lastName: payload.lastName,
            email: payload.email,
            phone: payload.phone,
            addressLine1: payload.addressLine1,
            addressLine2: payload.addressLine2,
            city: payload.city,
            state: payload.state,
            zipCode: payload.zipCode
        };
        let result = yield call(fetchPost, `${configData.SERVER_URL}/installer/create`, installer);
        if (result !== "success") {
            yield put({ type: ON_ERROR, payload: "Failed to create installer" });
        }
    } catch (e) {
    }
}

function* onInstallerCreateWatcher() {
    yield takeLatest(ON_INSTALLER_CREATE, onInstallerCreate)
}

/** Save Installer */
function* onInstallerSave(action) {
    try {
        const { payload } = action;
        let installer = {
            _id: payload._id,
            firstName: payload.firstName,
            lastName: payload.lastName,
            email: payload.email,
            phone: payload.phone,
            addressLine1: payload.addressLine1,
            addressLine2: payload.addressLine2,
            city: payload.city,
            state: payload.state,
            zipCode: payload.zipCode
        };
        let result = yield call(fetchPost, `${configData.SERVER_URL}/installer/update`, installer);

        if (result !== "success") {
            yield put({ type: ON_ERROR, payload: "Failed to create installer" });
        }
    } catch (e) {
    }
}

function* onInstallerSaveWatcher() {
    yield takeLatest(ON_INSTALLER_SAVE, onInstallerSave)
}

/** Export Watchers */
const installerManagementWatchers = [
    onLoadContractorsDropdownValuesWatcher,
    onViewInstallerClickWatcher,
    onEditInstallerClickWatcher,
    onDeleteInstallerClickWatcher,
    onListInstallersWatcher,
    onInstallerCreateWatcher,
    onInstallerSaveWatcher
];

export default installerManagementWatchers;
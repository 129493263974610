import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { onCustomerCreate, onCustomerSave } from './ducks/actions';
import { Alert } from '@material-ui/lab';
import { MenuItem } from "@material-ui/core";
import { onLoadJobsCountryStates } from "../dashboard/ducks/actions";
import AuthenticationHandler from './../shared/authentication-handler';
import {getLoggedInUserInfo} from './../shared/get-logged-user-detail';

/** Customer Form */
const CustomerForm = (props) => {

  /** State & Event Handler from Properties */
  const { loggedUserInfo, currentCustomer, currentAction, errorMessage, countryStatesInfo, customerCreateAction, customerSaveAction, countryStatesAction } = props;

  /** User form handler for submit action */
  const { control, register, errors, handleSubmit, getValues, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "CA",
      zipCode: ""
    }
  });

 
  /** Load States Info */
  useEffect(() => {
    countryStatesAction();
  }, []);

  const states = countryStatesInfo;

  /** Update Current Customer */
  useEffect(() => {
    if (currentCustomer) {
      if (currentCustomer._id) {
        setValue("_id", currentCustomer._id);
      }
      setValue("companyName", currentCustomer.companyName);
      setValue("firstName", currentCustomer.firstName);
      setValue("lastName", currentCustomer.lastName);
      setValue("email", currentCustomer.email);
      setValue("phone", currentCustomer.phone);
      setValue("addressLine1", currentCustomer.address ? currentCustomer.address.addressLine1 : '');
      setValue("addressLine2", currentCustomer.address ? currentCustomer.address.addressLine2 : '');
      setValue("city", currentCustomer.address ? currentCustomer.address.city : '');
      setValue("state", currentCustomer.address ? currentCustomer.address.state : '');
      setValue("zipCode", currentCustomer.address ? currentCustomer.address.zipCode : '');
    }
  }, [currentCustomer, setValue]);



  /** Save Handler for Create / Update */
  const onSave = data => {
    if (currentAction === "CREATE") {
      customerCreateAction(data);
      props.history.push("/customer");
    } else if (currentAction === "EDIT") {
      customerSaveAction(data);
      props.history.push("/customer");
    }
  };

  /** Cancel Event Handler */
  const onCancel = () => {
    props.history.push("/customer");
  }

  /** Renderer */
  return (
    <div className="customer-form">
      {errorMessage &&
        <Alert severity="error" style={{ width: '100%', textAlign: 'center' }}>
          <strong>{errorMessage}</strong>
        </Alert>
      }
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12" style={{ width: '95%', marginTop: '20px' }}> </div>
          <div class="col-lg-12" style={{ width: '100%', paddingRight: '25px', paddingLeft: '25px' }}>
            <div class="col-lg-12" style={{ marginLeft: '-15px' }}>
              <div class="col-lg-4 pages-tabs">Customers <span style={{ color: '#B5B5B5' }}>{'>'}</span> {currentAction === 'VIEW' && 'View Customer'} {currentAction === 'CREATE' && 'Create Customer'} {currentAction === 'EDIT' && 'Edit Customer'}</div>
              <div class="col-lg-8"></div>
            </div>
            <div class="col-lg-12 pages-body">
              <div class="row">
                <div class="col-lg-12" style={{ marginBottom: '10px' }} >
                  <form onSubmit={handleSubmit(onSave)}>
                    <input type="hidden" ref={register} name="_id" />
                    <div class="row">
                      <div class="col-lg-12">
                        <Controller as={<TextField margin="dense" label="Company Name" type="string" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} name="companyName" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3 d-inline">
                        <Controller as={<TextField margin="dense" label="First Name" type="string" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} rules={{ required: true }} name="firstName" />
                        {errors.firstName && errors.firstName.type === "required" && <span class="validation-error">*First Name is required</span>}
                      </div>
                      <div class="col-lg-3 d-inline">
                        <Controller as={<TextField margin="dense" label="Last Name" type="string" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} rules={{ required: true }} name="lastName" />
                        {errors.lastName && errors.lastName.type === "required" && <span class="validation-error">*Last Name is required</span>}
                      </div>
                      <div class="col-lg-3 d-inline">
                        <Controller as={<TextField margin="dense" label="Email" type="email" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control}
                          rules={{
                            required: true,
                            validate: () => { return !getValues("email") || new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i).test(getValues("email")) }
                          }}
                          name="email" />
                        {errors.email && errors.email.type === "required" && <span class="validation-error">*Email is required</span>}
                        {errors.email && errors.email.type === "validate" && <span class="validation-error">*Please enter valid email address</span>}
                      </div>
                      <div class="col-lg-3 d-inline">
                        <Controller as={<TextField margin="dense" label="Phone" type="tel" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} rules={{ required: true }} name="phone" />
                        {errors.phone && errors.phone.type === "required" && <span class="validation-error">*Phone Number is required</span>}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3 d-inline">
                        <Controller as={<TextField margin="dense" label="Address Line 1" type="string" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} rules={{ required: true }} name="addressLine1" />
                        {errors.addressLine1 && errors.addressLine1.type === "required" && <span class="validation-error">*Address Line 1 is required</span>}
                      </div>
                      <div class="col-lg-3 d-inline">
                        <Controller as={<TextField margin="dense" label="Address Line 2 (Optional)" type="string" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} name="addressLine2" />
                      </div>
                      <div class="col-lg-3 d-inline">
                        <Controller as={<TextField margin="dense" label="City" type="string" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} rules={{ required: true }} name="city" />
                        {errors.city && errors.city.type === "required" && <span class="validation-error">*City is required</span>}
                      </div>
                      <div class="col-lg-3 d-inline">
                        <Controller as={
                          <TextField margin="dense" label='State' fullWidth select InputProps={{ readOnly: currentAction === 'VIEW' }} onChange={e => setValue('state', e.target.value, true)} >
                            {states.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {`${option.name} (${option.id})`}
                              </MenuItem>
                            ))}
                          </TextField>} control={control} rules={{ required: true }} name="state" />
                        {errors.state && errors.state.type === "required" && <span class="validation-error">*State is required</span>}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3 d-inline">
                        <Controller as={<TextField margin="dense" label="Zip Code" type="string" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} rules={{ required: true }} name="zipCode" />
                        {errors.zipCode && errors.zipCode.type === "required" && <span class="validation-error">*Zip Code is required</span>}
                      </div>
                    </div>
                    <div class="row action-row">
                      <div class="col-lg-12">
                      <Button variant="contained" color="primary" onClick={onCancel}>{currentAction === 'VIEW' && 'Back'} {currentAction === 'CREATE' && 'Cancel'} {currentAction === 'EDIT' && 'Cancel'}</Button>
                        {currentAction === 'CREATE' && <Button variant="contained" color="primary" type="submit" style={{ marginRight: "5px!important" }}>Create</Button>}&nbsp;
                        {currentAction === 'EDIT' && <Button variant="contained" color="primary" type="submit" style={{ marginRight: "5px!important" }}>Save</Button>}&nbsp;
                       
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

/** Get State from Reducers & map to Customer Form */
const mapStateToProps = state => {
  return {
    loggedUserInfo: getLoggedInUserInfo(state),
    currentCustomer: state.customerReducer.currentCustomer || {},
    currentAction: state.customerReducer.currentAction || 'VIEW',
    errorMessage: state.customerReducer.errorMessage || '',
    countryStatesInfo: state.jobsDashboardReducer.countryList || []
  }
}

/** Attach actions to Customer Form */
const mapDispatchToProps = dispatch => {
  return {
    customerCreateAction: (payload) => dispatch(onCustomerCreate(payload)),
    customerSaveAction: (payload) => dispatch(onCustomerSave(payload)),
    countryStatesAction: (payload) => dispatch(onLoadJobsCountryStates(payload))
  }
}

/** Customer Form */
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AuthenticationHandler(CustomerForm)));
import {
  SET_CONTRACTORS_LIST,
  SET_CONTRACTOR_VIEW,
  SET_CONTRACTOR_EDIT,
  ON_NEW_CONTRACTOR_CLICK,
  ON_VIEW_CONTRACTOR_CLICK,
  ON_EDIT_CONTRACTOR_CLICK,
  ON_ERROR
} from './types';

const initialState = {
  errorMessage: '',
  currentAction: 'CREATE',
  currentContractor: {
    _id: '',
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: 'CA',
      zipCode: ''
    }
  }
}

const contractorReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_CONTRACTORS_LIST: {
      return {
        ...state,
        contractors: payload,
        errorMessage: ''
      };
    }
    case ON_NEW_CONTRACTOR_CLICK: {
      return {
        ...state,
        currentContractor: {
          _id: '',
          companyName: '',
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          address: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: 'CA',
            zipCode: ''
          }
        },
        currentAction: 'CREATE',
        errorMessage: ''
      };
    }
    case ON_VIEW_CONTRACTOR_CLICK: {
      return {
        ...state,
        currentContractor: {
          _id: '',
          companyName: '',
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          address: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zipCode: ''
          }
        },
        currentAction: 'VIEW',
        errorMessage: ''        
      };
    }
    case SET_CONTRACTOR_VIEW: {
      return {
        ...state,
        currentContractor: payload,
        currentAction: 'VIEW',
        errorMessage: ''
      };
    }
    case ON_EDIT_CONTRACTOR_CLICK: {
      return {
        ...state,
        currentContractor: {
          _id: '',
          companyName: '',
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          address: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zipCode: ''
          }
        },
        currentAction: 'EDIT',
        errorMessage: ''        
      };
    }    
    case SET_CONTRACTOR_EDIT: {
      return {
        ...state,
        currentContractor: payload,
        currentAction: 'EDIT',
        errorMessage: ''
      };
    }
    case ON_ERROR: {
      return {
        ...state,
        errorMessage: payload
      }
    }
    default: {
      return state;
    }
  }
};

export default contractorReducer;

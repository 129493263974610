import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { onLoadContractorsAction, onInstallerCreate, onInstallerSave } from './ducks/actions';
import { Alert } from '@material-ui/lab';
import { MenuItem } from "@material-ui/core";
import { AgGridReact } from 'ag-grid-react';
import { onLoadJobsCountryStates } from "../dashboard/ducks/actions";
import {getLoggedInUserInfo} from './../shared/get-logged-user-detail';

/** Installer Form */
const InstallerForm = (props) => {

  /** State & Event Handler from Properties */
  const { loggedUserInfo, currentInstaller, currentAction, contractorsList, errorMessage, countryStatesInfo, loadContractorsAction, installerCreateAction, installerSaveAction, countryStatesAction } = props;
  const [contractors, setContractors] = useState(contractorsList);
  const [jobs, setJobs] = useState(currentInstaller.jobs);

  /** User form handler for submit action */
  const { control, register, errors, handleSubmit, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      contractorId: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "CA",
      zipCode: ""
    }
  });

  /** Inital Load Action */
  useEffect(() => {
    if (loggedUserInfo.role !== "admin") {
      props.history.push('/login');
    }
  }, [props, loggedUserInfo]);

  /** Load States Info */
  useEffect(() => {
    countryStatesAction();
  }, []);

  const states = countryStatesInfo;

  /** Load Contractors */
  useEffect(() => {
    loadContractorsAction();
  }, [loadContractorsAction]);

  useEffect(() => {
    if (contractorsList && contractorsList.length > 0) {
      var activeContractors = contractorsList.filter(opt => opt.active).map(c => ({ id: c.id, name: c.name }));
      if (currentInstaller && currentInstaller._id && currentInstaller.contractorId) {
        var currentContractor = contractorsList.find(x => x.id == currentInstaller.contractorId);
        if (currentContractor && !currentContractor.active) {
          activeContractors.push({ id: currentContractor.id, name: `${currentContractor.name} (Deleted)` });
        }
      }
      setContractors(activeContractors);
    }
  }, [contractorsList]);

  /** Update Current Installer */
  useEffect(() => {
    if (currentInstaller) {
      if (currentInstaller._id) {
        setValue("_id", currentInstaller._id);
      }
      setValue("contractorId", currentInstaller.contractorId || '');
      setValue("firstName", currentInstaller.firstName);
      setValue("lastName", currentInstaller.lastName);
      setValue("email", currentInstaller.email);
      setValue("phone", currentInstaller.phone);
      setValue("addressLine1", currentInstaller.address ? currentInstaller.address.addressLine1 : '');
      setValue("addressLine2", currentInstaller.address ? currentInstaller.address.addressLine2 : '');
      setValue("city", currentInstaller.address ? currentInstaller.address.city : '');
      setValue("state", currentInstaller.address ? currentInstaller.address.state : '');
      setValue("zipCode", currentInstaller.address ? currentInstaller.address.zipCode : '');
      setJobs(currentInstaller.jobs);
    }
  }, [currentInstaller, setValue]);



  /** Save Handler for Create / Update */
  const onSave = data => {
    if (currentAction === "CREATE") {
      installerCreateAction(data);
      props.history.push("/installer");
    } else if (currentAction === "EDIT") {
      installerSaveAction(data);
      props.history.push("/installer");
    }
  };

  /** Cancel Event Handler */
  const onCancel = () => {
    props.history.push("/installer");
  }

  /** Job Grid Configuration */
  const getInstallationDate = (params) => {
    const installationDate = params.data.installationDate;
    if (installationDate) {
      let date = new Date(Date.parse(installationDate));
      var options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      };
      var formattedDate = date.toLocaleString('en-US', options);
      return `${formattedDate}`;
    }

    return "";
  };

  const jobGridOptions = {
    columnDefs: [
      { headerName: "Job ID", field: "jobId" },
      { headerName: "Installation Date", field: "installationDate", valueGetter: getInstallationDate },
      { headerName: "Status", field: "status" }
    ],
    defaultColDef: {
      sortable: true,
      resizable: true,
      flex: 1
    },
    sortingOrder: ['desc', 'asc', null],
    pageSize: 10,
    pagination: true,
    animateRows: true,
    domLayout: 'autoHeight'
  };

  const onJobGridReady = (params) => {
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  }

  /** Renderer */
  return (
    <div className="installer-form">
      {errorMessage &&
        <Alert severity="error" style={{ width: '100%', textAlign: 'center' }}>
          <strong>{errorMessage}</strong>
        </Alert>
      }
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12" style={{ width: '95%', marginTop: '20px' }}> </div>
          <div class="col-lg-12" style={{ width: '100%', paddingRight: '25px', paddingLeft: '25px' }}>
            <div class="col-lg-12" style={{ marginLeft: '-15px' }}>
              <div class="col-lg-4 pages-tabs">Installers <span style={{ color: '#B5B5B5' }}>{'>'}</span> {currentAction === 'VIEW' && 'View Installer'} {currentAction === 'CREATE' && 'Create Installer'} {currentAction === 'EDIT' && 'Edit Installer'}</div>
              <div class="col-lg-8"></div>
            </div>
            <div class="col-lg-12 pages-body">
              <div class="row">
                <div class="col-lg-12" style={{ marginBottom: '10px' }} >
                  <form onSubmit={handleSubmit(onSave)}>
                    <input type="hidden" ref={register} name="_id" />
                    <div class="row">
                      <div class="col">
                        <Controller as={
                          <TextField margin="dense" label='Contractor' fullWidth select InputProps={{ readOnly: currentAction === 'VIEW' || currentAction === 'EDIT' }} onChange={e => setValue('contractorId', e.target.value, true)} >
                            {contractors && contractors.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>} control={control} rules={{ required: true }} name="contractorId" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col d-inline">
                        <Controller as={<TextField margin="dense" label="First Name" type="string" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} rules={{ required: true }} name="firstName" />
                        {errors.firstName && <span class="validation-error">*First Name is required</span>}
                      </div>
                      <div class="col d-inline">
                        <Controller as={<TextField margin="dense" label="Last Name" type="string" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} rules={{ required: true }} name="lastName" />
                        {errors.lastName && <span class="validation-error">*Last Name is required</span>}
                      </div>
                      <div class="col d-inline">
                        <Controller as={<TextField margin="dense" label="Email" type="email" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} rules={{ required: true }} name="email" />
                        {errors.email && <span class="validation-error">*Email is required</span>}
                      </div>
                      <div class="col d-inline">
                        <Controller as={<TextField margin="dense" label="Phone" type="tel" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} rules={{ required: true }} name="phone" />
                        {errors.phone && <span class="validation-error">*Phone Number is required</span>}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col d-inline">
                        <Controller as={<TextField margin="dense" label="Address Line 1" type="string" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} rules={{ required: true }} name="addressLine1" />
                        {errors.addressLine1 && <span class="validation-error">*Address Line 1 is required</span>}
                      </div>
                      <div class="col d-inline">
                        <Controller as={<TextField margin="dense" label="Address Line 2 (Optional)" type="string" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} name="addressLine2" />
                      </div>
                      <div class="col d-inline">
                        <Controller as={<TextField margin="dense" label="City" type="string" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} rules={{ required: true }} name="city" />
                        {errors.city && <span class="validation-error">*City is required</span>}
                      </div>
                      <div class="col d-inline">
                        <Controller as={
                          <TextField margin="dense" label='State' fullWidth select InputProps={{ readOnly: currentAction === 'VIEW' }} onChange={e => setValue('state', e.target.value, true)} >
                            {states.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {`${option.name} (${option.id})`}
                              </MenuItem>
                            ))}
                          </TextField>} control={control} rules={{ required: true }} name="state" />
                        {errors.state && <span class="validation-error">*State is required</span>}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-3 d-inline">
                        <Controller as={<TextField margin="dense" label="Zip Code" type="string" fullWidth InputProps={{ readOnly: currentAction === 'VIEW' }} />} control={control} rules={{ required: true }} name="zipCode" />
                        {errors.zipCode && <span class="validation-error">*Zip Code is required</span>}
                      </div>
                    </div>
                    {currentAction === 'VIEW' &&
                      <fieldset>
                        <legend>Job Details</legend>
                        <div class="row">
                          <div class="col">
                            <div className="ag-theme-alpine ag-theme-alpine-secondary">
                              <AgGridReact
                                domLayout={jobGridOptions.domLayout}
                                pagination={jobGridOptions.pagination}
                                paginationPageSize={jobGridOptions.pageSize}
                                animateRows={jobGridOptions.animateRows}
                                defaultColDef={jobGridOptions.defaultColDef}
                                columnDefs={jobGridOptions.columnDefs}
                                sortingOrder={jobGridOptions.sortingOrder}
                                rowData={jobs}
                                onGridReady={onJobGridReady}>
                              </AgGridReact>
                            </div>
                          </div>
                        </div>
                      </fieldset>}
                    <div class="row action-row">
                      <div class="col">
                        <Button variant="contained" color="primary" onClick={onCancel}>{currentAction === 'VIEW' && 'Back'} {currentAction === 'CREATE' && 'Cancel'} {currentAction === 'EDIT' && 'Cancel'}</Button>
                        {currentAction === 'CREATE' && <Button variant="contained" color="primary" type="submit">Create</Button>}
                        {currentAction === 'EDIT' && <Button variant="contained" color="primary" type="submit">Save</Button>}

                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

/** Get State from Reducers & map to Installer Form */
const mapStateToProps = state => {
  return {
    loggedUserInfo: getLoggedInUserInfo(state),
    currentInstaller: state.installerReducer.currentInstaller || {},
    currentAction: state.installerReducer.currentAction || 'VIEW',
    contractorsList: state.installerReducer.contractorDropdownValues || [],
    errorMessage: state.installerReducer.errorMessage || '',
    countryStatesInfo: state.jobsDashboardReducer.countryList || []
  }
}

/** Attach actions to Installer Form */
const mapDispatchToProps = dispatch => {
  return {
    loadContractorsAction: () => dispatch(onLoadContractorsAction()),
    installerCreateAction: (payload) => dispatch(onInstallerCreate(payload)),
    installerSaveAction: (payload) => dispatch(onInstallerSave(payload)),
    countryStatesAction: (payload) => dispatch(onLoadJobsCountryStates(payload))
  }
}

/** Installer Form */
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InstallerForm));
import "./App.scss";
import HomePage from "./home/home-page";
import masterReducer from "./master-reducer";
import MasterLayout from "./shared/master-layout";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoginForm from "./user-form/login";
import Forgotpassword from "./user-form/forgot-password";
import Logout from "./user-form/logout";
import Resetpassword from "./user-form/reset-password";
import Passwordchange from "./user-form/change-password";
import EnquireForm from "./enquire-form/enquire";
import EnquireResponse from "./enquire-form/enquire-response";
import JobsLayout from "./dashboard/jobs-layout";
import { composeWithDevTools } from "redux-devtools-extension";

import createSagaMiddleware from "redux-saga";
import masterSaga from "./master-saga";
import CustomerLayout from "./dashboard/customer-layout";
import InstallerLayout from "./dashboard/installer-layout";
import ContractorLayout from "./dashboard/contractors-layout";
import JobStepper from "./job/job-stepper";
import ContractorForm from "./contractor-management/contractor-form";
import InstallerForm from "./installer-management/installer-form";
import DashbordHomeLayout from "./dashboard/dashboard-home";
import JobSummary from "./job/job-summary";
import CustomerForm from "./customer-management/customer-form"; 
import jobDetailedView from "./job/job-detailed-view";
import UserLayout from "./dashboard/user-layout";
import JobAdditionalDetail from "./job/job-additional-detail";
import ManageArchiveLayout from "./manage-archives/manage-archive-layout";



const sagaMiddleware = createSagaMiddleware();


const store = createStore(
  masterReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(masterSaga);

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <MasterLayout>
            <Switch>
            
              <Route exact path="/" component={HomePage} />              
              <Route path="/jobs-form" component={JobStepper} />
              <Route path="/jobs" component={JobsLayout} />
              <Route path="/login" component={LoginForm} />
              <Route path="/dashboard" component={DashbordHomeLayout} />
              <Route path="/forgot" component={Forgotpassword} />
              <Route path="/logout" component={Logout} />
              <Route path="/reset" component={Resetpassword} />
              <Route path="/change" component={Passwordchange} />
              <Route path="/enquire" component={EnquireForm} />
              <Route path="/enquireres" component={EnquireResponse} />
              <Route path="/customer" component={CustomerLayout} />
              <Route path="/customer-form" component={CustomerForm} />
              <Route path="/installer" component={InstallerLayout} />
              <Route path="/installer-form" component={InstallerForm} />
              <Route path="/contractor" component={ContractorLayout} />
              <Route path="/contractor-form" component={ContractorForm} />
              <Route path="/account-setup" component={UserLayout} />
              <Route path="/jobs-summary" component={JobSummary} />
              <Route path="/job-detailed-view" component={jobDetailedView} />
              <Route path="/job-additional-details" component={JobAdditionalDetail} />
              <Route path="/manage-archives" component={ManageArchiveLayout} />
            </Switch>
          </MasterLayout>
        </div>
      </Router>
    </Provider>
  );
}

export default App;

import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { styles}  from './styles';

function Changepassword(props) {

    const onClickLoginPage =() =>{
        console.log("go to login screen");
        props.history.push("/login");
    }

    return (
        <div>
          <h4>Great, Your password has been reset successfully!</h4>
          <br /><br />
            <input type="submit" value='Sign in' onClick={onClickLoginPage}/><br/>
        </div>
    );
  }
  export default withStyles(styles)(Changepassword);
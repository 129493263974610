import React, { useState } from "react";
import ContractorDashboard from "./../contractor-management/contractor-dashboard";

const ContractorLayout = (props) => {
    return (
        <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12" style={{width:'95%',marginTop:'10px' }}> </div>
                <div class="col-lg-12" style={{width:'100%',paddingRight:'25px',paddingLeft:'25px'}}>
                <div id="mb-brd" class="col-lg-12" style={{marginLeft:'-15px'}}>
                        <div class="col-lg-3 pages-tabs">
                        Contractor
                        </div>
                            <div class="col-lg-9 ">
                            </div>
                        </div>
                        <div class="col-lg-12 pages-body">
                            <ContractorDashboard />
                        </div>
                    </div>
                    <div class="col-lg-12"> </div>
                </div>
            </div>
        </div>
    )
}

export default ContractorLayout;
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { withStyles } from '@material-ui/core/styles';
import { styles}  from '../home/styles';

const TabPanel = (props) => {
    const { children, value, index, classes:{root}, ...other } = props;
  
    return (
      <div className={root}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  export default withStyles(styles)(TabPanel);